import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '../components/Dialog';
import { closeUnitProps } from '../reducers/DialogsReducer';
import { getUnitData, updateUnitData } from '../reducers/UnitsReducer';
import Dictionary from 'components/Dictionary';

import { getZoneNames } from '../reducers/ZonesReducer';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  title: {
    fontSize: '1.25em',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 12px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  addressFilter: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
}));

function UnitProps(props) {
  const classes = useStyles();
  const { ptsUnitID, dictionary } = props;
  const { Agencies } = dictionary;
  const [Zone, setZone] = useState(null);
  const [Unit, setUnit] = useState('');
  const [unitInfo, setUnitInfo] = useState(null);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [Agency, setAgency] = useState(null);
  const [zones, setZones] = useState([]);
  const [AgencyZones, setAgencyZones] = useState([]);

  useEffect(() => {
    getZoneNames()
      .then((zones) => setZones(zones))
      .catch((err) => console.log('Zones err: ', err));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ptsUnitID || zones.length === 0) return;
    getUnitData(ptsUnitID)
      .then((unit) => {
        const lat = unit.lat ? unit.lat : 'NULL';
        const lng = unit.lng ? unit.lng : 'NULL';
        setCoords({ lat, lng });
        setUnit(unit.Unit);
        setUnitInfo(unit);
        const agency = Agencies.find((a) => a.AgencyID === unit.AgencyID);
        setAgency(agency);
      })
      .catch((err) => console.log('error: ', err));
    // eslint-disable-next-line
  }, [zones]);

  useEffect(() => {
    if (!Agency) return;
    const AgencyZones = zones.filter((z) => z.AgencyID === Agency.AgencyID);
    const zone = AgencyZones.find((z) => z.ZoneCode === unitInfo.Zone);
    setAgencyZones(AgencyZones);
    setZone(zone ? zone : null);
    // eslint-disable-next-line
  }, [Agency]);

  const close = () => {
    props.closeUnitProps();
  };

  const save = () => {
    const zone = Zone ? Zone.ZoneCode : null;
    const lat = coords.lat !== 'NULL' ? '' + coords.lat : null;
    const lng = coords.lng !== 'NULL' ? '' + coords.lng : null;
    const data = { lat, lng, Zone: zone, AgencyID: Agency.AgencyID };
    updateUnitData(ptsUnitID, data)
      .then(() => close())
      .catch((err) => console.log('Err', err));
  };

  const changeLat = (ev) => {
    const val = ev.target.value;
    setCoords({ ...coords, lat: val });
  };

  const changeLng = (ev) => {
    const val = ev.target.value;
    setCoords({ ...coords, lng: val });
  };

  const renderForm = () => {
    return (
      <>
        <div className={classes.form}>
          <TextField
            className={classes.w100x200}
            label="Latitude"
            value={coords.lat}
            onChange={changeLat}
            variant="outlined"
            size="small"
          />

          <TextField
            className={classes.w100x200}
            label="Longitude"
            value={coords.lng}
            onChange={changeLng}
            variant="outlined"
            size="small"
          />
        </div>
        <div className={classes.form}>
          <Dictionary
            options="Agencies"
            className={classes.w100x200}
            onChange={(ev, val) => setAgency(val)}
            value={Agency}
            label="AgencyID"
            compact
          />
          <Autocomplete
            options={AgencyZones}
            getOptionLabel={(option) => option.ZoneCode}
            value={Zone}
            className={classes.w100x200}
            renderInput={(params) => (
              <TextField {...params} label="Zone" variant="outlined" size="small" />
            )}
            onChange={(event, newValue) => setZone(newValue)}
          />
        </div>
      </>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  return (
    <Dialog open={true} onClose={close} title={'Unit Props'} actions={renderActions()} toolbar>
      <h4 className={classes.title}>{Unit}</h4>
      <p style={{ color: 'red', marginBottom: 20 }}>This widget is for testing purposes only</p>
      {renderForm()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeUnitProps,
})(UnitProps);
