import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeUnitAddResource } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gridStyle } from 'utils/formStyles';
import XGrid3 from 'components/XGrid3';
import {
  getUnitsResources,
  getPtsPerson,
  searchSpecificVehicle,
  saveUnitPerson,
  saveUnitVehicle,
  removeUnitResource,
} from 'reducers/SearchReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonLookup from 'components/PersonLookup';
import VehicleLookup from 'components/VehicleLookup';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  formatSaveDate,
  getFormat24,
  getDateTimeFormat,
  getCurrentDate,
} from 'reducers/TimeReducer';
import { getFormStyle } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(200, 320),
    marginTop: 10,
  },
  date: getFormStyle(200, 300),
  action: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

const typeOptions = ['Person', 'Vehicle'];

const PersonColumns = [
  { field: 'PersonID', headerName: 'Person ID', width: 250 },
  { field: 'FullName', headerName: 'Full Name', width: 300 },
  { field: 'Age', headerName: 'Age', width: 250 },
];

const VehicleColumns = [
  { field: 'VehicleID', headerName: 'Vehicle ID', width: 250 },
  { field: 'VIN', headerName: 'VIN', width: 250 },
  { field: 'Make', headerName: 'Make', width: 250 },
  { field: 'Model', headerName: 'Model', width: 250 },
  { field: 'PrimaryColor', headerName: 'Color', width: 250 },
  { field: 'Year', headerName: 'Year', width: 250 },
];

function UnitResourceDialog(props) {
  const classes = useStyles();
  const { ptsUnitID } = props;
  const [type, setType] = useState('Person');
  const [personCols, setPersonCols] = useState(PersonColumns);
  const [vehicleCols, setVehicleCols] = useState(VehicleColumns);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [personData, setPersonData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [addView, setAddView] = useState(false);
  const [beginning, setBeginning] = useState(getCurrentDate());
  const [ending, setEnding] = useState(null);
  const [person, setPerson] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  const format24 = getFormat24();

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    const units = await getUnitsResources();
    const unit = units.find((u) => u.ptsUnitID === ptsUnitID);
    setCurrentUnit(unit);
    const { Resources, Vehicles } = unit;
    const persons = await getAllPersonsInfo(Resources);
    setPersonData(persons);
    const vehicles = await getAllVehiclesInfo(Vehicles);
    setVehicleData(vehicles);
  };

  const getAllPersonsInfo = async (Resources) => {
    const persons = Resources.map(async (resource) => {
      const { ptsPersonID } = resource;
      const [person] = await getPtsPerson(ptsPersonID);
      person.id = ptsPersonID;
      return person;
    });
    return Promise.all(persons);
  };

  const getAllVehiclesInfo = async (Vehicles) => {
    const vehicles = Vehicles.map(async (vehicle) => {
      const { ptsVehicleID } = vehicle;
      const [vehicleInfo] = await searchSpecificVehicle(ptsVehicleID);
      vehicleInfo.id = ptsVehicleID;
      return vehicleInfo;
    });
    return Promise.all(vehicles);
  };

  const close = () => {
    props.closeUnitAddResource();
  };

  // const save = () => {
  //   props.closeUnitAddResource();
  // };

  const renderDialogActions = () => {
    return (
      <>
        {/* <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button> */}
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderType = () => {
    const handleTypeChange = (ev, val) => {
      setType(val);
      setSelection(null);
    };
    return (
      <Autocomplete
        options={typeOptions}
        value={type}
        className={classes.item}
        size="small"
        onChange={handleTypeChange}
        renderInput={(params) => (
          <TextField {...params} label="Assignment Type" variant="outlined" />
        )}
      />
    );
  };

  const renderXgrid = () => {
    return (
      <>
        {type === 'Person' && (
          <XGrid3
            name="unitResources"
            columns={personCols}
            setColumns={setPersonCols}
            rows={personData}
            setSelection={setSelection}
          />
        )}
        {type === 'Vehicle' && (
          <XGrid3
            name="unitResources"
            columns={vehicleCols}
            setColumns={setVehicleCols}
            rows={vehicleData}
            setSelection={setSelection}
          />
        )}
      </>
    );
  };

  const deleteResource = async () => {
    if (!window.confirm('Are you sure you want to remove this resource?')) return;
    try {
      await removeUnitResource(selection, type, ptsUnitID);
      fetchResources();
      setSelection(null);
    } catch (err) {
      props.handleError(err, 'Error deleting person');
    }
  };

  const renderUnitActions = () => {
    return (
      <div className={classes.action}>
        <Fab size="small" color="secondary" onClick={() => setAddView(true)}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={deleteResource} disabled={!selection}>
          <DeleteIcon />
        </Fab>
      </div>
    );
  };

  const savePerson = async () => {
    const ComponentName = person.FullName;
    const ptsChildID = person.ptsPersonID;
    props.hideSpinner();
    try {
      await saveUnitPerson({ ptsUnitID, ComponentName, ptsChildID, beginning, ending });
      fetchResources();
      setAddView(false);
      setEnding(null);
    } catch (err) {
      props.handleError(err, 'Error saving resource');
    }
    props.hideSpinner();
  };

  const saveVehicle = async () => {
    const ComponentName = vehicle.Make;
    const ptsChildID = vehicle.ptsVehicleID;
    const selectedBeginningDate = beginning;
    const selectedEndingDate = ending;
    props.showSpinner();
    try {
      await saveUnitVehicle({
        ptsUnitID,
        ComponentName,
        ptsChildID,
        selectedBeginningDate,
        selectedEndingDate,
      });
      fetchResources();
      setAddView(false);
      setEnding(null);
    } catch (err) {
      props.handleError(err, 'Error saving resource');
    }
    props.hideSpinner();
  };

  const renderButtons = () => {
    const disableSave = (type === 'Person' && !person) || (type == 'Vehicle' && !vehicle);
    return (
      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSave}
          onClick={type === 'Person' ? savePerson : saveVehicle}>
          <SaveIcon />
          Save
        </Button>
        <Button color="primary" onClick={() => setAddView(false)}>
          <CloseIcon />
          Close
        </Button>
      </div>
    );
  };

  const renderResourceAddFields = () => {
    const handlePersonChange = (person) => {
      setPerson(person);
    };
    const handleVehicleChange = (vehicle) => {
      setVehicle(vehicle);
    };
    return (
      <>
        {type === 'Vehicle' && (
          <VehicleLookup
            className={classes.item}
            onChange={handleVehicleChange}
            label="Search Vehicle"
          />
        )}
        {type === 'Person' && (
          <PersonLookup className={classes.item} onPersonChange={handlePersonChange} compact />
        )}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              ampm={!format24}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format={getDateTimeFormat()}
              margin="normal"
              label="Date From"
              value={beginning}
              onChange={setBeginning}
              maxDate={ending}
              autoOk
              size="small"
              className={classes.item}
            />
            <KeyboardDateTimePicker
              ampm={!format24}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              margin="normal"
              label="Date To"
              value={ending}
              onChange={setEnding}
              minDate={beginning}
              autoOk
              size="small"
              className={classes.item}
              format={getDateTimeFormat()}
            />
          </MuiPickersUtilsProvider>
        </div>
        {renderButtons()}
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={`Unit Resources - ${currentUnit?.Unit}`}
      actions={renderDialogActions()}
      fullScreen>
      {renderType()}
      {!addView && type && renderUnitActions()}
      {addView && type && renderResourceAddFields()}
      {renderXgrid()}
    </Dialog>
  );
}

export default connect(null, { closeUnitAddResource, handleError, showSpinner, hideSpinner })(
  UnitResourceDialog
);
