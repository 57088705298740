import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import defaultMenuOptions from 'pages/Events/defaultMenuOptions';
// import EventTabMenu from 'pages/Events/EventTabMenu';
// import EventTabDetails from 'pages/Events/EventTabDetails';
import { setTab } from 'reducers/EventSortingReducer';
import { getEvent } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles(() => ({
  tabs: {
    marginTop: 8,
    marginBottom: 8,
  },
  tabDetails: {
    minHeight: 300,
  },
}));

function EditEventTabs(props) {
  const classes = useStyles();
  const { ptsEventID, events, dataUpdate } = props;
  const [event, setEvent] = useState(null);
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);
  const changeEventMenu = (tab) => props.setTab(ptsEventID, tab);
  const state = props.state[ptsEventID] || { open: false, tab: 'Units' };
  const { tab } = state;

  useEffect(() => {
    const event = events.find((ev) => ev.ptsEventID === ptsEventID);
    if (event) {
      setEvent(event);
    } else {
      getEventInfo();
    }
  }, [ptsEventID]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'event' || dataUpdate.data !== ptsEventID) return;
    getEventInfo();
  }, [dataUpdate]);

  const getEventInfo = async () => {
    try {
      const event = await getEvent(ptsEventID);
      setEvent(event);
    } catch (err) {
      props.handleError(err);
    }
  };

  const toggleMenuOptions = (key) => {
    const newMenuOptions = { ...menuOptions };
    newMenuOptions[key].sort = newMenuOptions[key].sort === 'ASC' ? 'DESC' : 'ASC';
    setMenuOptions(newMenuOptions);
  };

  if (!event) return 'no event data';
  return (
    <>
      <div className={classes.tabs}>
        {/* <EventTabMenu
        value={tab}
        setValue={changeEventMenu}
        event={event}
        menuOptions={menuOptions}
        toggleMenuOptions={toggleMenuOptions}
      /> */}
      </div>
      <div className={classes.tabDetails}>
        {/* <EventTabDetails
        event={event}
        menuOptions={menuOptions}
      /> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    state: state.eventSort.state,
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  setTab,
  handleError,
})(EditEventTabs);
