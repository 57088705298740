import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import UserReducer from './UserReducer';
import AppInfoReducer from './AppInfoReducer';
import EventsReducer from './EventsReducer';
import UnitsReducer from './UnitsReducer';
import ConfigReducer from './ConfigReducer';
import EventUnitsReducer from './EventUnitsReducer';
import UnitStatusReducer from './UnitStatusReducer';
import MapReducer from './MapReducer';
import ZonesReducer from './ZonesReducer';
import PagingReducer from './PagingReducer';
import DialogsReducer from './DialogsReducer';
import E911Reducer from './E911Reducer';
import { reducer as notifications } from 'react-notification-system-redux';
import UnitResources from './UnitResourcesReducer';
import EventSortingReducer from './EventSortingReducer';
import UnitSortingReducer from './UnitSortingReducer';
import BulletinReducer from './BulletinReducer';
import DataUpdateReducer from './DataUpdateReducer';
import DictionaryReducer from './DictionaryReducer';
import UnitsNeedAttentionReducer from './UnitsNeedAttentionReducer';
import PermissionsReducer from './PermissionsReducer';
import TimeReducer from './TimeReducer';
import KeyboardReducer from './KeyboardReducer';
import VFCReducer from '../reducers/VfcReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    notifications: notifications,
    ui: UiReducer,
    user: UserReducer,
    events: EventsReducer,
    eventSort: EventSortingReducer,
    units: UnitsReducer,
    unitSort: UnitSortingReducer,
    appInfo: AppInfoReducer,
    config: ConfigReducer,
    eventUnits: EventUnitsReducer,
    unitStatus: UnitStatusReducer,
    map: MapReducer,
    zones: ZonesReducer,
    paging: PagingReducer,
    dialogs: DialogsReducer,
    e911: E911Reducer,
    unitResources: UnitResources,
    unreadBulletins: BulletinReducer,
    dataUpdate: DataUpdateReducer,
    dictionary: DictionaryReducer,
    unitsNeedAttention: UnitsNeedAttentionReducer,
    permissions: PermissionsReducer,
    time: TimeReducer,
    keyboard: KeyboardReducer,

    vfc: VFCReducer,
  });

export default createRootReducer;
