import React from 'react';

import ArsonIcon from './ArsonIcon';

import BurglaryIcon from './BurglaryIcon';

import CarcrashIcon from './Car-crashIcon';

import CircumstanceIcon from './CircumstanceIcon';

import CrashInjuryIcon from './CrashInjuryIcon';

import DisturbanceDomesticIcon from './DisturbanceDomesticIcon';

import DisturbanceFightIcon from './DisturbanceFightIcon';

import DisturbanceWithWeaponIcon from './DisturbanceWithWeaponIcon';

import FirebuildingIcon from './FirebuildingIcon';

import FireIcon from './FireIcon';

import FireworksIcon from './FireworksIcon';

import FoundPropertyIcon from './FoundPropertyIcon';

import FraudForgeryIcon from './FraudForgeryIcon';

import HarassmentIcon from './HarassmentIcon';

import icons8airplanelanding100Icon from './icons8airplanelanding100Icon';

import icons8alarm100Icon from './icons8alarm100Icon';

import icons8ambulance100Icon from './icons8ambulance100Icon';

import icons8baby100Icon from './icons8baby100Icon';

import icons8bankcardmissing100Icon from './icons8bankcardmissing100Icon';

import icons8binoculars100Icon from './icons8binoculars100Icon';

import icons8bomb100Icon from './icons8bomb100Icon';

import icons8briefcase100Icon from './icons8briefcase100Icon';

import icons8bully100Icon from './icons8bully100Icon';

import icons8cannabis100Icon from './icons8cannabis100Icon';

import icons8caraccident100Icon from './icons8caraccident100Icon';

import icons8carservice100Icon from './icons8carservice100Icon';

import icons8cartheft100Icon from './icons8cartheft100Icon';

import icons8chase100Icon from './icons8chase100Icon';

import icons8checkfile100Icon from './icons8checkfile100Icon';

import icons8checklock100Icon from './icons8checklock100Icon';

import icons8children100Icon from './icons8children100Icon';

import icons8complaint100Icon from './icons8complaint100Icon';

import icons8crashedcar100Icon from './icons8crashedcar100Icon';

import icons8dangerouscurrent100Icon from './icons8dangerouscurrent100Icon';

import icons8deadmaninacoffin100Icon from './icons8deadmaninacoffin100Icon';

import icons8death100Icon from './icons8death100Icon';

import icons8detain100Icon from './icons8detain100Icon';

import icons8detective100Icon from './icons8detective100Icon';

import icons8doctorsbag100Icon from './icons8doctorsbag100Icon';

import icons8documentwriter100Icon from './icons8documentwriter100Icon';

import icons8dog100Icon from './icons8dog100Icon';

import icons8doorlock100Icon from './icons8doorlock100Icon';

import icons8doorsensorchecked100Icon from './icons8doorsensorchecked100Icon';

import icons8dying100Icon from './icons8dying100Icon';

import icons8elderlyperson100Icon from './icons8elderlyperson100Icon';

import icons8emergencycall100Icon from './icons8emergencycall100Icon';

import icons8enterhouse100Icon from './icons8enterhouse100Icon';

import icons8escape100Icon from './icons8escape100Icon';

import icons8evidence100Icon from './icons8evidence100Icon';

import icons8explosion100Icon from './icons8explosion100Icon';

import icons8fasttrack100Icon from './icons8fasttrack100Icon';

import icons8fight100Icon from './icons8fight100Icon';

import icons8findusermale100Icon from './icons8findusermale100Icon';

import icons8fingerprint100Icon from './icons8fingerprint100Icon';

import icons8fire100Icon from './icons8fire100Icon';

import icons8firestation100Icon from './icons8firestation100Icon';

import icons8fireworkexplosion100Icon from './icons8fireworkexplosion100Icon';

import icons8firinggun100Icon from './icons8firinggun100Icon';

import icons8fist100Icon from './icons8fist100Icon';

import icons8floodcar100Icon from './icons8floodcar100Icon';

import icons8floods100Icon from './icons8floods100Icon';

import icons8fraud100Icon from './icons8fraud100Icon';

import icons8gasstation100Icon from './icons8gasstation100Icon';

import icons8handcuffs100Icon from './icons8handcuffs100Icon';

import icons8hazardwarningflasher100Icon from './icons8hazardwarningflasher100Icon';

import icons8help100Icon from './icons8help100Icon';

import icons8homealarm100Icon from './icons8homealarm100Icon';

import icons8hospital100Icon from './icons8hospital100Icon';

import icons8identitytheft100Icon from './icons8identitytheft100Icon';

import icons8idnotverified100Icon from './icons8idnotverified100Icon';

import icons8judo100Icon from './icons8judo100Icon';

import icons8lawcourt100Icon from './icons8lawcourt100Icon';

import icons8leaningagainstwall100Icon from './icons8leaningagainstwall100Icon';

import icons8lockedinside100Icon from './icons8lockedinside100Icon';

import icons8lostandfound100Icon from './icons8lostandfound100Icon';

import icons8magnifyingglass100Icon from './icons8magnifyingglass100Icon';

import icons8mediumrisk100Icon from './icons8mediumrisk100Icon';

import icons8mentalhealth100BLACKANDWHITEIcon from './icons8mentalhealth100BLACKANDWHITEIcon';

import icons8moneybag100Icon from './icons8moneybag100Icon';

import icons8noentry100Icon from './icons8noentry100Icon';

import icons8noise100Icon from './icons8noise100Icon';

import icons8parking100Icon from './icons8parking100Icon';

import icons8peacesymbol100Icon from './icons8peacesymbol100Icon';

import icons8physicalabuse100Icon from './icons8physicalabuse100Icon';

import icons8pickingup100Icon from './icons8pickingup100Icon';

import icons8pickpocket100Icon from './icons8pickpocket100Icon';

import icons8pills100Icon from './icons8pills100Icon';

import icons8policecar100Icon from './icons8policecar100Icon';

import icons8policestation100Icon from './icons8policestation100Icon';

import icons8policydocument100Icon from './icons8policydocument100Icon';

import icons8prisonbuilding100Icon from './icons8prisonbuilding100Icon';

import icons8privacypolicy100Icon from './icons8privacypolicy100Icon';

import icons8robbery100Icon from './icons8robbery100Icon';

import icons8sexworker100Icon from './icons8sexworker100Icon';

import icons8signingadocument100Icon from './icons8signingadocument100Icon';

import icons8smarthomechecked100Icon from './icons8smarthomechecked100Icon';

import icons8splitup100Icon from './icons8splitup100Icon';

import icons8spraypaint100Icon from './icons8spraypaint100Icon';

import icons8spy100Icon from './icons8spy100Icon';

import icons8stopgesture100Icon from './icons8stopgesture100Icon';

import icons8suicidefullbody100Icon from './icons8suicidefullbody100Icon';

import icons8thief100Icon from './icons8thief100Icon';

import icons8tractioncontrol100Icon from './icons8tractioncontrol100Icon';

import icons8trafficjam100Icon from './icons8trafficjam100Icon';

import icons8train100Icon from './icons8train100Icon';

import icons8trashpile100Icon from './icons8trashpile100Icon';

import icons8view100Icon from './icons8view100Icon';

import icons8wanted100Icon from './icons8wanted100Icon';

import icons8warrefugee100Icon from './icons8warrefugee100Icon';

import icons8watertransportation100Icon from './icons8watertransportation100Icon';

import icons8whyquest100Icon from './icons8whyquest100Icon';

import JuvenileProblemAbuseNeglectIcon from './JuvenileProblemAbuseNeglectIcon';

import JuvenileProblemLoiteringIcon from './JuvenileProblemLoiteringIcon';

import LitteringDumpingIcon from './LitteringDumpingIcon';

import RobberyIcon from './RobberyIcon';

import RunawayjuvenileIcon from './RunawayjuvenileIcon';

import SexCrimeAssaultIcon from './SexCrimeAssaultIcon';

function EmptyIcon() {
  return <span>-</span>;
}

export default {
  0: EmptyIcon,

  1: ArsonIcon,

  2: BurglaryIcon,

  3: CarcrashIcon,

  4: CircumstanceIcon,

  5: CrashInjuryIcon,

  6: DisturbanceDomesticIcon,

  7: DisturbanceFightIcon,

  8: DisturbanceWithWeaponIcon,

  9: FirebuildingIcon,

  10: FireIcon,

  11: FireworksIcon,

  12: FoundPropertyIcon,

  13: FraudForgeryIcon,

  14: HarassmentIcon,

  15: icons8airplanelanding100Icon,

  16: icons8alarm100Icon,

  17: icons8ambulance100Icon,

  18: icons8baby100Icon,

  19: icons8bankcardmissing100Icon,

  20: icons8binoculars100Icon,

  21: icons8bomb100Icon,

  22: icons8briefcase100Icon,

  23: icons8bully100Icon,

  24: icons8cannabis100Icon,

  25: icons8caraccident100Icon,

  26: icons8carservice100Icon,

  27: icons8cartheft100Icon,

  28: icons8chase100Icon,

  29: icons8checkfile100Icon,

  30: icons8checklock100Icon,

  31: icons8children100Icon,

  32: icons8complaint100Icon,

  33: icons8crashedcar100Icon,

  34: icons8dangerouscurrent100Icon,

  35: icons8deadmaninacoffin100Icon,

  36: icons8death100Icon,

  37: icons8detain100Icon,

  38: icons8detective100Icon,

  39: icons8doctorsbag100Icon,

  40: icons8documentwriter100Icon,

  41: icons8dog100Icon,

  42: icons8doorlock100Icon,

  43: icons8doorsensorchecked100Icon,

  44: icons8dying100Icon,

  45: icons8elderlyperson100Icon,

  46: icons8emergencycall100Icon,

  47: icons8enterhouse100Icon,

  48: icons8escape100Icon,

  49: icons8evidence100Icon,

  50: icons8explosion100Icon,

  51: icons8fasttrack100Icon,

  52: icons8fight100Icon,

  53: icons8findusermale100Icon,

  54: icons8fingerprint100Icon,

  55: icons8fire100Icon,

  56: icons8firestation100Icon,

  57: icons8fireworkexplosion100Icon,

  58: icons8firinggun100Icon,

  59: icons8fist100Icon,

  60: icons8floodcar100Icon,

  61: icons8floods100Icon,

  62: icons8fraud100Icon,

  63: icons8gasstation100Icon,

  64: icons8handcuffs100Icon,

  65: icons8hazardwarningflasher100Icon,

  66: icons8help100Icon,

  67: icons8homealarm100Icon,

  68: icons8hospital100Icon,

  69: icons8identitytheft100Icon,

  70: icons8idnotverified100Icon,

  71: icons8judo100Icon,

  72: icons8lawcourt100Icon,

  73: icons8leaningagainstwall100Icon,

  74: icons8lockedinside100Icon,

  75: icons8lostandfound100Icon,

  76: icons8magnifyingglass100Icon,

  77: icons8mediumrisk100Icon,

  78: icons8mentalhealth100BLACKANDWHITEIcon,

  79: icons8moneybag100Icon,

  80: icons8noentry100Icon,

  81: icons8noise100Icon,

  82: icons8parking100Icon,

  83: icons8peacesymbol100Icon,

  84: icons8physicalabuse100Icon,

  85: icons8pickingup100Icon,

  86: icons8pickpocket100Icon,

  87: icons8pills100Icon,

  88: icons8policecar100Icon,

  89: icons8policestation100Icon,

  90: icons8policydocument100Icon,

  91: icons8prisonbuilding100Icon,

  92: icons8privacypolicy100Icon,

  93: icons8robbery100Icon,

  94: icons8sexworker100Icon,

  95: icons8signingadocument100Icon,

  96: icons8smarthomechecked100Icon,

  97: icons8splitup100Icon,

  98: icons8spraypaint100Icon,

  99: icons8spy100Icon,

  100: icons8stopgesture100Icon,

  101: icons8suicidefullbody100Icon,

  102: icons8thief100Icon,

  103: icons8tractioncontrol100Icon,

  104: icons8trafficjam100Icon,

  105: icons8train100Icon,

  106: icons8trashpile100Icon,

  107: icons8view100Icon,

  108: icons8wanted100Icon,

  109: icons8warrefugee100Icon,

  110: icons8watertransportation100Icon,

  111: icons8whyquest100Icon,

  112: JuvenileProblemAbuseNeglectIcon,

  113: JuvenileProblemLoiteringIcon,

  114: LitteringDumpingIcon,

  115: RobberyIcon,

  116: RunawayjuvenileIcon,

  117: SexCrimeAssaultIcon,
};
