import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8identitytheft100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="100"
      height="100">
      <path d="M42.02 9c-2.06 0-10.156 4.354-16.377 8h14.95a3.45 3.45 0 0 1 2.423 1.004 3.45 3.45 0 0 1 1.004 2.422V31c0 .348-.18.67-.477.852l-1.522.935-.209.127a1.001 1.001 0 0 1-1.011.022c-.033-.02-3.299-1.848-6.098-3.05-1.109-.474-2.12-.712-2.984-.712-.7 0-1.303.157-1.78.47-.52.344-.777.798-.873 1.106a.896.896 0 0 0-.046.25c0 2.014 1.643 3.102 3.232 4.154l.309.203c2.024 1.353 3.547 2.16 5.656 3.274.59.312 1.219.645 1.91 1.017.662.356 1.286.704 1.894 1.045l.221.123c3.647 2.043 6.53 3.659 11.414 3.659h.016c3.602-.002 8.782-2.897 10.35-3.825V24.053h-.002l-3.297 1.21c-1.708.565-3.543-.077-4.594-1.585-.001 0-8.379-10.97-9.211-12.078-1.8-2.4-3.472-2.6-4.898-2.6zm-40 10c-1 0-2 1-2 2v29c0 1 1 2 2 2h38c1 0 2-1 2-2v-7.018c-.251-.14-.5-.276-.756-.42-.666-.372-1.355-.76-2.086-1.154-.686-.369-1.312-.698-1.897-1.008-2.08-1.1-3.723-1.97-5.832-3.379l-.3-.199c-1.743-1.154-4.13-2.735-4.13-5.822 0-.91.563-2.197 1.819-3.025 1.165-.768 3.265-1.38 6.654.074 2.253.967 4.72 2.283 5.772 2.857l.756-.465V21c0-1-1-2-2-2h-38zm11 8c1.769 0 3.5 1.273 3.5 3.729v.826c0 1.776-1.67 4.425-3.5 4.425-1.832 0-3.64-2.65-3.64-4.425v-.826c0-2.473 1.87-3.729 3.64-3.729zm0 11c2.647 0 5.36.365 6.656.8a1.96 1.96 0 0 1 1.3 1.487L21.233 42H4.783l.281-1.715a1.955 1.955 0 0 1 1.301-1.484C7.66 38.366 10.372 38 13.02 38z" />
    </SvgIcon>
  );
}

export default icons8identitytheft100Icon;
