import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8checkfile100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="100"
      height="100">
      <path d="M7 2a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h8.518A9 9 0 0 1 23 14a8.97 8.97 0 0 1 2 .23V9.5a.997.997 0 0 0-.293-.707l-6.5-6.5A1 1 0 0 0 17.5 2H7zm10 1.904L23.096 10H18a1 1 0 0 1-1-1V3.904zM23 16a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm3 4a.999.999 0 0 1 .707 1.707l-4 4a.997.997 0 0 1-1.414 0l-2-2a.999.999 0 1 1 1.414-1.414L22 23.586l3.293-3.293A.996.996 0 0 1 26 20z" />
    </SvgIcon>
  );
}

export default icons8checkfile100Icon;
