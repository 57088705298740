import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect } from 'react-redux';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showPeopleCredentials } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { useDispatch } from 'react-redux';
import { getPeopleCredentials } from 'reducers/SearchReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);
// const showIcon = (params) => {
//   return <AttachmentIcon type={params.row.FileType} style={{ width: 24, height: 'auto' }} />;
// };
const defaultColumns = [
  { field: 'Type', headerName: 'Type', width: 150, hide: false },
  { field: 'Number', headerName: 'Number', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const Credential = (props) => {
  const classes = useStyles();
  const { personId, editPeopleCredentials } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    personId && getData();
  }, [editPeopleCredentials, isDeleted]);

  const getData = async () => {
    try {
      const data = await getPeopleCredentials(personId);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const addContact = () => {
    dispatch(showPeopleCredentials({ ptsPersonID: personId }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(showPeopleCredentials({ ...item }));
  };

  const deleteContact = async () => {
    const item = rows.find((row) => row.id === selection);
    try {
      props.showSpinner();
      const service = getService('record-person-credentials');
      const res = await service.remove(item.ptsCredentialID);
      dispatch(notify('Credential Deleted', 'warning'));
      setIsDeleted(!isDeleted);
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const viewDetails = () => {};

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Credential">
            <Fab size="small" color="secondary" onClick={addContact}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit Credential">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              {/* <Tooltip title="Veiw details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip> */}

              <Tooltip title="Delete Credential">
                <Fab size="small" color="secondary" onClick={deleteContact}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="PeopleCredentials"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleCredentials: state.dialogs.editPeopleCredentials,
  };
};
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(Credential);
