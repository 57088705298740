import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8children100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M14.094 0c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7ZM36.78 0c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Zm-7.31 3.781c-1.84 1.059-4.41 3.285-4.688 8.156a1.03 1.03 0 0 0 .282.75c.187.2.44.313.718.313h4c.473 0 .867-.34.969-.781-1.215-1.403-1.969-3.223-1.969-5.219 0-1.145.25-2.23.688-3.219Zm14.625 0c.437.989.687 2.074.687 3.219a7.927 7.927 0 0 1-1.937 5.219c.101.441.46.781.937.781h4c.274 0 .563-.113.75-.313a1 1 0 0 0 .25-.75c-.273-4.87-2.847-7.097-4.687-8.156ZM33.53 14.844c-2.726 0-5.18 1.676-6.125 4.156l-2.125 5.875c-.636-2.465-1.312-5.125-1.312-5.125-.719-2.86-3.34-4.844-6.375-4.844H9.53c-2.988 0-5.672 2.035-6.375 4.844 0 0-3.074 12.316-3.094 12.406a2.363 2.363 0 0 0 1.594 2.75c.04.016.086.024.125.032.176.035.36.062.532.062 1.109 0 2.066-.707 2.28-1.781.012-.059 2.47-9.563 2.47-9.563h.03V28h13v-4.344h.032l2.5 9.563c.195.98 1.016 1.636 2 1.75.129.02.25.031.375.031.14-.004.262-.035.406-.063.04-.007.086-.019.125-.03.64-.145 1.192-.512 1.532-1.126.007-.011.023-.02.03-.031.032-.035.063-.082.095-.125.023-.031.042-.055.062-.094.027-.05.043-.105.063-.156l3.656-9.656H31a.46.46 0 0 1 .469-.407c.273 0 .5.223.5.5 0 .06-.016.106-.032.157L27.5 35.656a.97.97 0 0 0 .094.907c.191.269.511.437.843.437h15.72c.327 0 .656-.164.843-.438.188-.273.21-.628.094-.937l-4.438-11.656a.59.59 0 0 1-.031-.188c0-.277.227-.5.5-.5.25 0 .46.2.5.438l3.656 9.656c.02.05.035.11.063.156.02.035.043.063.062.094.032.043.063.09.094.125.008.012.023.02.031.031A2.321 2.321 0 0 0 47.594 35c.254 0 .523-.047.781-.125a2.24 2.24 0 0 0 1.406-1.219c.27-.586.285-1.265.032-1.843-.004-.012-.028-.024-.032-.032L45.188 19c-.942-2.48-3.399-4.156-6.126-4.156ZM7.094 29v7h13v-7Zm0 8v10.5c0 1.375 1.125 2.5 2.5 2.5s2.5-1.125 2.5-2.5V37Zm8 0v10.5c0 1.375 1.125 2.5 2.5 2.5s2.5-1.125 2.5-2.5V37Zm16.687 1v10c0 1.102.899 2 2 2 1.102 0 2-.898 2-2V38Zm5 0v10c0 1.102.899 2 2 2 1.102 0 2-.898 2-2V38Z" />
    </SvgIcon>
  );
}

export default icons8children100Icon;
