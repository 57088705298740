export const locationEmptyData = {
  ptsAddressID: null,
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  UnitType: null,
  UnitIdentifier: null,
  City: null,
  State: null,
  PostalCode: null,
  County: null,
  IsPrimary: false,
  Notes: '',
};

export const locationPrimaryEmptyData = {
  ptsAddressID: null,
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  UnitType: null,
  UnitIdentifier: null,
  City: null,
  State: null,
  PostalCode: null,
  County: null,
  IsPrimary: true,
  Notes: '',
};

export const callerEmptyData = {
  CallerLocation: null,
  Unknown: false, // checkbox true/false
  Refused: false, // checkbox true/false
  FirstName: null,
  LastName: null,
  PhoneNo: null,
  // home address info
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  ptsCityID: null,
  State: null,
  PostalCode: null,
};

export const eventEmptyData = {
  CallType: null,
  CallSubType: null,
  CallMethod: null,
  RequestedAction: null,
  Description: null,
};

export const notesEmptyData = {
  Comment: '',
};
