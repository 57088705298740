import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeSettings } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getLocalSettings, saveLocalSettings } from 'reducers/ConfigReducer';
import { getFormStyle } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import ChangeUserPass from 'components/ChangeUserPass';

const useStyles = makeStyles((theme) => ({
  content: {
    width: 800,
    height: 500,
    maxWidth: '100%',
    display: 'flex',
    '& h4': {
      marginBottom: theme.spacing(3),
    },
  },
  settingsWrap: {
    flex: 1,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  w150x250: {
    ...getFormStyle(150, 250),
  },
  tabsWrap: {
    width: 200,
    flex: 0,
    marginLeft: theme.spacing(-3),
  },
}));

function Settings(props) {
  const classes = useStyles();
  const [changed, setChanged] = useState(false);
  const [workstationID, setWorkstationID] = useState('');
  const [tab, setTab] = useState('Workstation');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [zoom, setZoom] = useState(14);

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const getSettings = () => {
    const settings = getLocalSettings();
    const { workstationID, mapLat, mapLng, mapZoom } = settings;
    setWorkstationID(workstationID);
    setLat(String(mapLat));
    setLng(String(mapLng));
    setZoom(String(mapZoom));
    setWorkstationID(workstationID);
  };

  const close = () => {
    props.closeSettings();
  };

  const save = () => {
    const settings = {
      workstationID,
      mapLat: parseFloat(lat),
      mapLng: parseFloat(lng),
      mapZoom: parseFloat(zoom),
    };
    saveLocalSettings(settings);
    props.closeSettings();
  };

  const handleWorkstationIDChange = (ev) => {
    const val = ev.target.value;
    if (val.length > 10) return;
    const newSettings = { ...settings };
    newSettings.workstationID = val;
    setSettings(newSettings);
  };

  const isLatValid = () => {
    if (lat === '') return false;
    const val = parseFloat(lat);
    return val > -85.05112878 && val < 85.05112878;
  };

  const isLngValid = () => {
    if (lng === '') return false;
    const val = parseFloat(lng);
    return val > -180 && val < 180;
  };

  const isZoomValid = () => {
    if (zoom === '') return false;
    const val = parseFloat(zoom);
    return val >= 3 && val <= 21;
  };

  const settingsValid = () => {
    return isLatValid() && isLngValid() && isZoomValid();
  };

  const onLatChange = (ev) => {
    !changed && setChanged(true);
    setLat(ev.target.value);
  };

  const onLngChange = (ev) => {
    !changed && setChanged(true);
    setLng(ev.target.value);
  };

  const onZoomChange = (ev) => {
    !changed && setChanged(true);
    setZoom(ev.target.value);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!settingsValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handleTabChange = (ev, value) => {
    setTab(value);
  };

  const renderTabs = () => {
    return (
      <div className={classes.tabsWrap}>
        <Tabs orientation="vertical" variant="scrollable" value={tab} onChange={handleTabChange}>
          <Tab label="Workstation" value="Workstation" size="small" />
          {/* <Tab label="Map" value="Map" size="small" /> */}
          <Tab label="Password" value="Password" size="small" />
        </Tabs>
      </div>
    );
  };

  const renderWrokstationSettings = () => {
    return (
      <>
        <h4>Workstation settings</h4>
        <div className={classes.form}>
          <TextField
            label="Workstation ID"
            variant="outlined"
            size="small"
            value={workstationID}
            onChange={handleWorkstationIDChange}
            className={classes.w150x250}
          />
        </div>
      </>
    );
  };

  const renderMapSettings = () => {
    return (
      <>
        <h4>Map Settings</h4>
        <div className={classes.form}>
          <TextField
            className={classes.w150x250}
            label="Latitude"
            variant="outlined"
            value={lat}
            onChange={onLatChange}
            size="small"
            type="number"
            error={!isLatValid()}
          />
          <TextField
            className={classes.w150x250}
            label="Longitude"
            variant="outlined"
            value={lng}
            onChange={onLngChange}
            size="small"
            error={!isLngValid()}
          />
        </div>
        <div className={classes.form}>
          <TextField
            className={classes.w150x250}
            label="Zoom Level"
            variant="outlined"
            value={zoom}
            onChange={onZoomChange}
            size="small"
            error={!isZoomValid()}
          />
        </div>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title="Local settings"
      actions={renderActions()}
      className={classes.dialog}>
      <div className={classes.content}>
        {renderTabs()}
        <div className={classes.settingsWrap}>
          {tab === 'Workstation' && renderWrokstationSettings()}
          {/* {tab === 'Map' && renderMapSettings()} */}
          {tab === 'Password' && <ChangeUserPass />}
        </div>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeSettings,
  notify,
  handleError,
})(Settings);
