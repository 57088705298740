import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8noentry100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M25 2C12.316 2 2 12.316 2 25s10.316 23 23 23 23-10.316 23-23S37.684 2 25 2Zm12 26c0 .602-.398 1-1 1H14c-.602 0-1-.398-1-1v-6c0-.602.398-1 1-1h22c.602 0 1 .398 1 1Z" />
    </SvgIcon>
  );
}

export default icons8noentry100Icon;
