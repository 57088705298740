const SET_UNIT_NEED_ATTENTION = 'SET_UNIT_NEED_ATTENTION';

export const setUnitNeedAttention = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_UNIT_NEED_ATTENTION, payload: data });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_UNIT_NEED_ATTENTION:
      return action.payload;
    default:
      return state;
  }
}
