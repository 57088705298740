import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Events from 'pages/Events';
// import Units from 'pages/Units';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    padding: theme.spacing(2),
    '& > div': {
      '&:first-child': {
        width: '50%',
      },
      '&:nth-child(2)': {
        width: '50%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  units: {
    paddingLeft: '1em',
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.pageWrap}>
      {/* <div className={classes.events}>
        <Events />
      </div>
      <div className={classes.units}>
        <Units />
      </div> */}
    </div>
  );
}

export default Home;
