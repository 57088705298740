import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 15,
    marginBottom: 15,
    '& th': {
      paddingLeft: 10,
    },
    '& h4 span': {
      color: theme.palette.error.main,
      fontWeight: 400,
      fontSize: '0.8em',
    },
  },
  creator: {
    '& strong': {
      fontWeight: 500,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 3),
  },
  address: {
    position: 'relative',
    '& strong': {
      fontWeight: 500,
    },
  },
  actions: {
    position: 'absolute',
    right: 0,
    top: -10,
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

function Location(props) {
  const classes = useStyles();
  const { edited, data } = props;
  const { ptsLocationAddressID, FullAddressText } = data;
  const IsPrimary = data.IsPrimary ? <span> (Primary)</span> : '';
  const edit = () => props.edit(ptsLocationAddressID);
  return (
    <div className={classes.single}>
      {props.idx > 0 && <hr />}
      <div className={classes.address}>
        <strong>{FullAddressText}</strong>
        {IsPrimary}
        <div className={classes.actions}>
          <Fab color="secondary" aria-label="edit" onClick={edit} disabled={edited} size="small">
            <EditIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
}

function sortLocation(data) {
  const primary = data.find((location) => location.IsPrimary);
  if (!primary) return data;
  const out = data.filter((location) => !location.IsPrimary);
  out.unshift(primary);
  return out;
}

export default function LocationsCard(props) {
  const classes = useStyles();
  const data = sortLocation(props.data);
  const dataAvailable = !!data.length;

  return (
    <Card className={classes.card} variant="outlined">
      {!dataAvailable && <p>No locations available for this event</p>}
      {dataAvailable &&
        data.map((data, idx) => <Location {...props} key={idx} data={data} idx={idx} />)}
    </Card>
  );
}
