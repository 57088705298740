import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '../../components/Tooltip';
import RebidsMapPanel from './RebidsMapPanel';
import EventMap from './EventMap';
import EventForm from './EventForm';
import CallerForm from './CallerForm';
import LocationForm from './LocationForm';
import InfoPanel from './InfoPanel';
import Badge from '@material-ui/core/Badge';
import {
  newEvent,
  updateEvents,
  getEventData,
  saveEventNote,
  saveEventCaller,
  saveLocation,
  saveEventEvent,
  unitInitiatedEvent,
} from 'reducers/EventsReducer';
import { notify, showCustomMsgBox, hideCustomMsgBox } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import CustomMsgBox from 'components/CustomMsgBox';
import {
  locationPrimaryEmptyData,
  callerEmptyData,
  eventEmptyData,
  notesEmptyData,
} from './eventEmptyData';
// import { demoData1, demoData2, demoData3 } from './demoData';
import { asyncForEach } from 'utils/functions';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { handleError } from 'reducers/ErrorReducer';
// import { getAddressHistory } from 'reducers/AddressReducer';
import Dialog from 'components/Dialog';
import EventDuplicates from './EventDuplicates';
import AddressDuplicates from './AddressDuplicates';
import {
  dispRecommendations,
  closeAddEvent,
  editEvent,
  showSops,
  showAddressDialog,
} from 'reducers/DialogsReducer';
import settings from 'config/settings';
import { geocodeLocation, getAddressFromLocation } from 'utils/mapFunctions';
import { getEventDuplicates } from 'reducers/EventsReducer';
import { getAddressDuplicates, getLocationHistoryCount } from 'reducers/AddressReducer';
import NotesPanel from './NotesPanel';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';
import { togglePin } from 'reducers/EventSortingReducer';
import { getZoneMatch } from 'reducers/ZonesReducer';
import { getEventESNMatch } from 'reducers/EsnReducer';
import { sortObjArr } from 'utils/functions';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { showReport } from 'reducers/DialogsReducer';
import { sendRipAndRun } from 'reducers/ReportsReducer';

const useStyles = makeStyles((theme) => ({
  seconderyBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'spaces-between',
    alignItems: 'center',
    '& > div': {
      '&:first-child': {
        width: 'calc(50% - 45px)',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        width: 90,
        flex: '1 0 90px',
      },
      '&:last-child': {
        textAlign: 'right',
        width: 'calc(50% - 45px)',
      },
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  leftActions: {
    '& button': {
      '& svg': {
        marginRight: 0,
      },
    },
  },
}));

function AddEvent(props) {
  const classes = useStyles();
  const { dictionary, data, events, eventSort, options } = props;
  const evDuplicateOpts = options.EventDuplicateCheck;
  const addrDuplicateOpts = options.AddressDuplicateCheck;
  const { RipAndRunEnabled } = options;
  const eventPins = eventSort.pins;
  const CallerPhone = data?.Event?.CallerPhone || null;
  const type = data.type;
  const [eventData, setEventData] = useState({ ...eventEmptyData });
  const [callersData, setCallersData] = useState([{ ...callerEmptyData }]);
  const [locationsData, setLocationsData] = useState([{ ...locationPrimaryEmptyData }]);
  const [notesData, setNotesData] = useState([{ ...notesEmptyData }]);
  const [parsedLocation, setParsedLocation] = useState(null);
  const [ptsEventID, setPtsEventID] = useState(null);
  const [sopsNo, setSopsNo] = useState(0);
  const [dispRectActive, setDispRectActive] = useState(false);
  const [eventDuplicates, setEventDuplicates] = useState([]);
  const [addressDuplicates, setAddressDuplicates] = useState([]);
  const [AddressHistory, setAddressHistory] = useState(0);
  const [ptsAddressID, setPtsAddressID] = useState(null);
  const [eventPinned, setEventPinned] = useState(false); // only new events
  const [priority, setPriority] = useState(null);
  const [zones, setZones] = useState([]);
  const [esns, setEsns] = useState([]);
  const newEventData = useRef({ ...eventEmptyData });
  const newCallersData = useRef([{ ...callerEmptyData }]);
  const newLocationData = useRef([{ ...locationPrimaryEmptyData }]);
  const evDuplicateThrottle = useRef(0);
  const addressDuplicateThrottle = useRef(0);
  const addressHistoryThrottle = useRef(0);
  const zoneCheckThrottle = useRef(0);
  const mountedRef = useRef(true);
  const eventExists = ptsEventID && events.findIndex((ev) => ev.ptsEventID === ptsEventID) !== -1;
  const pinned = eventExists
    ? eventPins.findIndex((ev) => ev.ptsEventID === ptsEventID) !== -1
    : eventPinned;

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      clearTimeout(evDuplicateThrottle.current);
      clearTimeout(addressDuplicateThrottle.current);
      clearTimeout(addressHistoryThrottle.current);
      clearTimeout(zoneCheckThrottle.current);
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) return;
    const { Callers, Event, Locations, Notes } = data;
    Event && setEventData(Event);
    Callers && setCallersData(Callers);
    Locations && setParsedLocation(Locations);
    Notes && setNotesData(Notes);
    checkCoords(Event);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!ptsEventID) return;
    const ev = events.find((ev) => ev.ptsEventID === ptsEventID);
    if (!ev) {
      setSopsNo(0);
      setDispRectActive(false);
      return;
    }
    setDispRectActive(true);
    const sops = ev.sops;
    if (sops.length) {
      setSopsNo(sops.length);
    } else {
      setSopsNo(0);
    }
    // eslint-disable-next-line
  }, [events]);

  const saveEvent = async (open = false, action = null) => {
    props.hideCustomMsgBox();
    let errors = false;
    if (newEventData?.current?.ptsEventID) {
      updateEvent(open, action);
      return;
    }
    const data = {};
    data.Event = { ...newEventData.current, AddressHistory };
    data.Locations = await getLocationsData();
    const callers = getNonEmptyCallers();
    if (callers) data.Callers = callers;
    const notes = getNonEmptyNotes();
    if (notes.length > 0) data.Notes = notes;
    props.showSpinner();
    let ptsEventID = null;
    try {
      ptsEventID = await newEvent(data);
      setPtsEventID(ptsEventID);
      props.updateEvents();
      if (eventPinned) props.togglePin(ptsEventID);
    } catch (e) {
      errors = true;
      if (e.errors) {
        const errMessages = [];
        Object.values(e.errors).forEach((msg) => errMessages.push(msg));
        props.showCustomMsgBox('Form validation errors', errMessages, 'error');
      } else {
        props.handleError(e, 'Event saving error');
      }
    }
    if (type === 'unit-initiated') {
      await unitInitEvent(ptsEventID);
    }
    props.hideSpinner();
    if (open) {
      updateEventData(ptsEventID);
    } else {
      if (!errors) props.closeAddEvent();
    }
    if (action) {
      if (action === 'RipAndRun' && ptsEventID) ripAndRun(ptsEventID);
    }
  };

  const ripAndRun = async (ptsEventID) => {
    try {
      await sendRipAndRun(ptsEventID);
      props.notify('Report sent', 'success');
    } catch (err) {
      props.handleError(err);
    }
    // props.showReport({ id: 233, data: { ptsEventID }, title: 'Rip & Run' });
  };

  const unitInitEvent = async (ptsEventID) => {
    try {
      await unitInitiatedEvent(ptsEventID, data.Unit.ptsUnitID, data.Unit.Created);
    } catch (err) {
      props.handleError(err, 'Error updating unit initiated event');
    }
  };

  const getLocationsData = async () => {
    const Locations = [];
    await asyncForEach(getNonEmptyLocations(), async (location) => {
      let updatedLocation = location;
      try {
        updatedLocation = await addCoordsToLocation(location, dictionary);
      } catch (err) {
        props.handleError(err, 'Error, cannot contact geolocation service', 'error');
      }
      Locations.push(updatedLocation);
    });
    return Locations;
  };

  const updateEvent = async (open, action) => {
    const ptsEventID = newEventData.current.ptsEventID;
    // Event
    try {
      const Event = newEventData.current;
      await saveEventEvent(Event);
    } catch (err) {
      props.handleError(err, 'Error updating event details');
    }
    // Locations
    const Locations = await getLocationsData();
    try {
      await asyncForEach(Locations, async (Location) => {
        await saveLocation(Location, ptsEventID, dictionary);
      });
    } catch (err) {
      props.handleError(err, 'Error updating locations');
    }

    // Callers
    try {
      await asyncForEach(newCallersData.current, async (Caller) => {
        await saveEventCaller(Caller, ptsEventID);
      });
    } catch (err) {
      props.handleError(err, 'Error updating callers');
    }

    // Notes
    try {
      await asyncForEach(notesData, async (Note) => {
        await saveEventNote(Note, ptsEventID);
      });
    } catch (err) {
      props.handleError(err, 'Error updating notes');
    }
    if (!open) return props.closeAddEvent();
    await updateEventData();
    if (action) {
      if (action === 'RipAndRun' && ptsEventID) ripAndRun(ptsEventID);
    }
  };

  const updateEventData = async (id = ptsEventID) => {
    if (!id) return;
    const event = await getEventData(id);
    if (!event) return;
    const { Event, Notes, Callers, Locations } = event;
    const callersData = Callers.length ? Callers : [{ ...callerEmptyData }];
    const locationsData = Locations.length ? Locations : [{ ...locationPrimaryEmptyData }];
    const notesData = Notes.length ? Notes : [{ ...notesEmptyData }];
    newEventData.current = Event;
    newCallersData.current = callersData;
    newLocationData.current = locationsData;
    setEventData(Event);
    setCallersData(callersData);
    setLocationsData(locationsData);
    setNotesData(notesData);
    checkCoords(Event);
  };

  const getNonEmptyLocations = () => {
    return newLocationData.current.filter(
      (location) =>
        location.ptsAddressID || location.AddressNumber || location.StreetName || location.ptsCityID
    );
  };

  const getNonEmptyCallers = () => {
    return newCallersData.current.filter(
      (caller) => caller.FirstName || caller.LastName || caller.Info || caller.CallerLocation
    );
  };

  const getNonEmptyNotes = () => {
    return notesData.filter((note) => note.Comment && note.Comment.trim() !== '');
  };

  const locationCheckMet = (location) => {
    const { AddressNumber, StreetName, ptsCityID, StreetType, ptsCoordinateID } = location;
    const addressValid = AddressNumber && StreetName && ptsCityID;
    if (
      (evDuplicateOpts.SearchRadiusActive ||
        evDuplicateOpts.Address ||
        evDuplicateOpts.StreetRangeActive) &&
      addressValid
    )
      return true;
    if (evDuplicateOpts.Coordinates && ptsCoordinateID) return true;
    return false;
  };

  const updateNewEvent = (data) => {
    if (evDuplicateOpts.CheckForDuplicates) {
      const callTypeCheck =
        evDuplicateOpts.CallType &&
        data.CallType &&
        newEventData.current.CallType !== data.CallType;
      const locationRequired =
        evDuplicateOpts.Address ||
        evDuplicateOpts.Coordinates ||
        evDuplicateOpts.SearchRadiusActive ||
        evDuplicateOpts.StreetRangeActive;
      if (
        callTypeCheck &&
        ((locationRequired && locationCheckMet(newLocationData.current[0])) || !locationRequired)
      ) {
        newEventData.current = data;
        checkForEvDuplicates();
      }
    }
    newEventData.current = data;
  };

  const addPlace = (place, no) => {
    const newData = [...newLocationData.current];
    newData[no] = { ...newData[no], ...place };
    setLocationsData(newData);
    const { lat, lng } = newData[no];
    if (
      newData[no].IsPrimary &&
      lat !== undefined &&
      lat !== null &&
      lng !== undefined &&
      lng !== null
    ) {
      const newEvent = { ...eventData, lat, lng };
      setEventData(newEvent);
      checkCoords(newEvent);
    }
  };

  const updateNewNotes = (data, no) => {
    const newNotesData = [...notesData];
    newNotesData[no] = data;
    setNotesData(newNotesData);
  };

  const updateNewCaller = (data, no) => {
    newCallersData.current[no] = data;
  };

  const updateNewLocation = (data, no) => {
    // const { IsPrimary, ptsAddressID } = data;
    // if (IsPrimary && ptsAddressID) {
    //   const idChanged = newLocationData.current[no]?.ptsAddressID !== ptsAddressID;
    //   if (idChanged) checkForAddressHistory(ptsAddressID);
    // } else {
    //   if (AddressHistory) setAddressHistory(0);
    //   if (ptsAddressID) setPtsAddressID(null);
    // }
    checkAddrHistoryCount(data);
    if (evDuplicateOpts.CheckForDuplicates) {
      if (evDuplicateOpts.CallType && !newEventData.current.CallType) {
        newLocationData.current[no] = data;
        getAddrDuplicates();
        return;
      }
      if (locationCheckMet(data)) {
        newLocationData.current[no] = data;
        checkForEvDuplicates();
      } else {
        newLocationData.current[no] = data;
      }
    } else {
      newLocationData.current[no] = data;
    }
    getAddrDuplicates();
  };

  // const addCaller = () => {
  //   const newData = [...callersData];
  //   const newContact = { ...callerEmptyData };
  //   newData.push(newContact);
  //   setCallersData(newData);
  // };

  // const addLocation = () => {
  //   const newData = [...locationsData];
  //   const newLocation = { ...locationPrimaryEmptyData };
  //   newData.push(newLocation);
  //   setLocationsData(newData);
  // };

  const addNote = () => {
    const newData = [...notesData];
    newData.unshift({ ...notesEmptyData });
    setNotesData(newData);
  };

  // const saveDemo = (no) => () => {
  //   switch (no) {
  //     case 1:
  //       setEventData({ ...demoData1.Event });
  //       setCallersData([...demoData1.Callers]);
  //       setLocationsData([...demoData1.Locations]);
  //       setNotesData([...demoData1.Notes]);
  //       break;
  //     case 2:
  //       setEventData({ ...demoData2.Event });
  //       setCallersData([...demoData2.Callers]);
  //       setLocationsData([...demoData2.Locations]);
  //       setNotesData([...demoData2.Notes]);
  //       break;
  //     case 3:
  //       setEventData({ ...demoData3.Event });
  //       setCallersData([...demoData3.Callers]);
  //       setLocationsData([...demoData3.Locations]);
  //       setNotesData([...demoData3.Notes]);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const useCallerLocation = () => {
    setLocationsData(parsedLocation);
    setParsedLocation(null);
  };

  // const clearForm = () => {
  //   setEventData({ ...eventEmptyData });
  //   setCallersData([{ ...callerEmptyData }]);
  //   setLocationsData([{ ...locationPrimaryEmptyData }]);
  //   setNotesData([{ ...notesEmptyData }]);
  // };

  const removeNoteForm = (no) => {
    const removeNote = () => {
      const newData = [...notesData];
      newData.splice(no, 1);
      setNotesData(newData);
    };
    const content = notesData[no].Comment;
    if (!content || window.confirm('Note is not empty. Are you sure you want to remove it?')) {
      removeNote();
    }
  };

  const cancelEvent = () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      props.closeAddEvent();
    }
  };

  const showDispatchRecommendations = () => props.dispRecommendations(ptsEventID);

  const showSOPs = () => props.showSops(ptsEventID);

  const showAddressHistory = () => props.showAddressDialog({ ptsAddressID, tab: 'history' });

  const togglePin = () => {
    if (eventExists) {
      props.togglePin(ptsEventID);
    } else {
      setEventPinned(!eventPinned);
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div className={classes.leftActions}>
          {dispRectActive ? (
            <Tooltip title="Show Dispatch Recommendations">
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={showDispatchRecommendations}>
                <AssignmentIndIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentIndIcon />
            </IconButton>
          )}
          {sopsNo > 0 ? (
            <Tooltip title="Show SOPs">
              <IconButton variant="contained" color="primary" size="small" onClick={showSOPs}>
                <Badge badgeContent={sopsNo} color="error" overlap="circle">
                  <AssignmentLateIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentLateIcon />
            </IconButton>
          )}
          {Boolean(ptsAddressID) ? (
            <Tooltip title="Address History">
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={showAddressHistory}>
                <Badge badgeContent={AddressHistory} color="secondary" overlap="circle">
                  <AssignmentIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton variant="contained" color="primary" size="small" disabled>
              <AssignmentIcon />
            </IconButton>
          )}
          <Tooltip title="Pin event">
            <IconButton variant="contained" color="primary" size="small" onClick={togglePin}>
              {pinned ? <AiFillPushpin /> : <AiOutlinePushpin />}
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => saveEvent(true, 'RipAndRun')}
            disabled={!RipAndRunEnabled}>
            <AssignmentTurnedInIcon /> Rip &amp; Run
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={() => saveEvent(true)}>
            <SaveIcon /> Save
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={() => saveEvent(false)}>
            <SaveIcon /> Save and Close
          </Button>
          <Button onClick={cancelEvent} color="primary" size="small">
            <CloseIcon /> cancel
          </Button>
        </div>
      </div>
    );
  };

  const updateCoords = (data) => {
    const EventData = { ...newEventData.current };
    const { XCoordinate, YCoordinate } = data;
    EventData.lat = XCoordinate;
    EventData.lng = YCoordinate;
    setEventData(EventData);
    checkCoords(EventData);
  };

  const setLocation = (address) => {
    setLocationsData([address]);
    checkAddrHistoryCount(address);
  };

  const checkForEvDuplicates = () => {
    if (!evDuplicateOpts.CheckForDuplicates) return;
    clearTimeout(evDuplicateThrottle.current);
    evDuplicateThrottle.current = setTimeout(() => {
      if (!isLocationValid(newLocationData.current[0])) return;
      const coordsNeeded = evDuplicateOpts.Coordinates || evDuplicateOpts.SearchRadiusActive;
      if (coordsNeeded) {
        geocodeLocation(newLocationData.current[0], dictionary)
          .then((result) => {
            if (!mountedRef.current) return;
            getEvDuplicates(result.lat, result.lng);
          })
          .catch((err) => {
            console.log(err);
            // props.handleError(err, 'Error geocoding the location.');
          });
      } else {
        getEvDuplicates();
      }
    }, settings.eventDuplicateWait);
  };

  const isLocationValid = (location) => {
    const { AddressNumber, StreetName, ptsCityID } = location;
    return AddressNumber && StreetName && ptsCityID;
  };

  const getEvDuplicates = (lat = null, lng = null) => {
    const { CallType } = newEventData.current;
    const { AddressNumber, StreetName, StreetType } = newLocationData.current[0];
    const data = {
      CallType,
      FullAddressText: getAddressFromLocation(newLocationData.current[0], dictionary),
      AddressNumber,
      StreetType,
      StreetName,
      lat,
      lng,
    };
    getEventDuplicates(data)
      .then((duplicates) => {
        if (!mountedRef.current) return;
        setEventDuplicates(duplicates.filter((ev) => ev.ptsEventID !== ptsEventID));
      })
      .catch((err) => {
        props.handleError(err, 'Error receiving event duplicates');
      });
  };

  const checkAddrHistoryCount = (location) => {
    const { AddressNumber, StreetName, StreetType, ptsCityID } = location;
    if (!AddressNumber || !StreetName || !StreetType || !ptsCityID) return;
    clearTimeout(addressHistoryThrottle.current);
    addressHistoryThrottle.current = setTimeout(() => {
      addrHistoryCount(location);
    }, settings.eventDuplicateWait);
  };

  const addrHistoryCount = async (location) => {
    try {
      const { ptsAddressID, count } = await getLocationHistoryCount(location, ptsEventID);
      setAddressHistory(count);
      if (ptsAddressID) setPtsAddressID(ptsAddressID);
    } catch (err) {
      props.handleError(err);
    }
  };

  const checkCoords = (eventData) => {
    if (!eventData) return;
    clearTimeout(zoneCheckThrottle.current);
    zoneCheckThrottle.current = setTimeout(() => {
      const { lat, lng } = eventData;
      checkZones({ lat, lng });
      checkEsns({ lat, lng });
    }, settings.eventDuplicateWait);
  };

  const checkZones = async (coords) => {
    try {
      const zones = await getZoneMatch(coords);
      const sortedZones = sortObjArr(zones, 'AgencyID');
      setZones(sortedZones);
    } catch (err) {
      props.handleError(err);
    }
  };

  const checkEsns = async (coords) => {
    try {
      const esns = await getEventESNMatch(coords);
      setEsns(esns);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getAddrDuplicates = () => {
    if (!addrDuplicateOpts.CheckForDuplicates) return;
    clearTimeout(addressDuplicateThrottle.current);
    addressDuplicateThrottle.current = setTimeout(() => {
      const address = newLocationData.current[0];
      const {
        ptsAddressID,
        AddressNumber,
        PreDirection,
        StreetName,
        StreetType,
        PostDirection,
        ptsCityID,
        State,
        PostalCode,
        ptsPlaceID,
      } = address;
      const emptyAddress = !(
        AddressNumber ||
        PreDirection ||
        StreetName ||
        StreetType ||
        PostDirection ||
        ptsCityID ||
        State ||
        PostalCode ||
        ptsAddressID ||
        ptsPlaceID
      );
      if (emptyAddress) return setAddressDuplicates([]);
      getAddressDuplicates(address)
        .then((duplicates) => {
          if (!mountedRef.current) return;
          setAddressDuplicates(duplicates.filter((addr) => addr.ptsAddressID !== ptsAddressID));
        })
        .catch((err) => {
          if (!mountedRef.current) return;
          props.handleError(err, 'Error receiving address duplicates');
        });
    }, settings.eventDuplicateWait);
  };

  // const checkForAddressHistory = async (ptsAddressID) => {
  //   mountedRef.current = true;
  //   try {
  //     const data = await getAddressHistory(ptsAddressID);
  //     if (!mountedRef.current) return;
  //     setAddressHistory(data.length);
  //     setPtsAddressID(ptsAddressID);
  //   } catch (err) {
  //     props.handleError(err, "Error receiving event location data.");
  //   }
  // }

  const closeDialog = (data) => {
    props.closeAddEvent(data);
  };

  return (
    <Dialog toolbar onClose={closeDialog} title="Add Event" actions={renderActions()} maxWidth="xl">
      <CustomMsgBox />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={4}>
          {locationsData.map((location, idx) => (
            <LocationForm
              dictionary={dictionary}
              data={location}
              key={idx}
              no={idx}
              addPlace={addPlace}
              updateData={updateNewLocation}
              title={'Address'}
              useCallerLocation={idx === 0 && parsedLocation ? useCallerLocation : undefined}
            />
          ))}
          <EventForm
            dictionary={dictionary}
            data={eventData}
            updateData={updateNewEvent}
            setPriority={setPriority}
            title="Event"
          />
        </Grid>
        <Grid item xs={4}>
          {callersData.map((Caller, idx) => (
            <CallerForm
              dictionary={dictionary}
              key={idx}
              data={Caller}
              no={idx}
              title="Caller"
              updateData={updateNewCaller}
            />
          ))}
          <NotesPanel
            notes={notesData}
            dictionary={dictionary}
            updateNewNotes={updateNewNotes}
            addNote={addNote}
            clear={removeNoteForm}
          />
          <InfoPanel zones={zones} esns={esns} priority={priority} />
        </Grid>
        <Grid item xs={4}>
          {data.type === '911' && (
            <RebidsMapPanel
              CallerPhone={CallerPhone}
              update={updateCoords}
              setLocation={setLocation}
            />
          )}
          {data.type !== '911' && <EventMap data={eventData} setLocation={setLocation} />}
          <EventDuplicates data={eventDuplicates} />
          <AddressDuplicates data={addressDuplicates} setLocation={setLocation} />
        </Grid>
      </Grid>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    events: state.events,
    options: state.config.options,
    eventSort: state.eventSort,
  };
};

export default connect(mapStateToProps, {
  notify,
  showCustomMsgBox,
  hideCustomMsgBox,
  showSpinner,
  hideSpinner,
  updateEvents,
  handleError,
  closeAddEvent,
  editEvent,
  dispRecommendations,
  showSops,
  showAddressDialog,
  togglePin,
  showReport,
})(AddEvent);
