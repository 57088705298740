import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { processInputPhoneNo } from '../utils/functions';

function PhoneTextField(props) {
  const { short } = props;
  const [PhoneNo, setPhoneNo] = useState(props.value);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    props.isValid && props.isValid(validate(props.value));
    // eslint-disable-next-line
  }, []);

  const onPhoneNoChange = (ev) => {
    const val = ev.target.value;
    const no = processInputPhoneNo(val);
    if (no !== false) {
      !short && setPhoneNo(no);
      short && setPhoneNo(no.substr(0, 14));
    }
    !touched && setTouched(true);
    props.isValid && props.isValid(validate(no));
    props.onChange && props.onChange(val);
  };

  const validate = (no) => no.length > 13;

  return (
    <TextField
      error={!validate(PhoneNo) && touched}
      value={PhoneNo}
      onChange={onPhoneNoChange}
      label={props.label}
      className={props.className}
      variant={props.variant}
      size={props.size || 'small'}
    />
  );
}

export default PhoneTextField;
