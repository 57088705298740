import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8policydocument100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="100"
      height="100">
      <path d="M7 2a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h8.758l-2.88-2.879a2.999 2.999 0 0 1 0-4.242 2.981 2.981 0 0 1 2.386-.854c.088-.156.19-.306.322-.44l4-4c.133-.132.281-.234.437-.321a2.978 2.978 0 0 1 .856-2.385 2.99 2.99 0 0 1 4.119-.1V9.5a.997.997 0 0 0-.293-.707l-6.5-6.5A.993.993 0 0 0 17.5 2H7zm10 1.904L23.096 10H18a1 1 0 0 1-1-1V3.904zm5.99 10.086a1 1 0 0 0-.697 1.717l4 4a1 1 0 1 0 1.414-1.414l-4-4a1 1 0 0 0-.717-.303zM21 17l-4 4 4 4 4-4-4-4zm-6.01 4.99a1 1 0 0 0-.697 1.717l4 4a1 1 0 1 0 1.414-1.414l-4-4a1 1 0 0 0-.717-.303zm10.018 1.604-1.414 1.414 4.69 4.69a1 1 0 0 0 .009.009A1 1 0 0 0 29 30a1 1 0 0 0 1-1 1 1 0 0 0-.293-.707l-4.7-4.7z" />
    </SvgIcon>
  );
}

export default icons8policydocument100Icon;
