import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import Highlight from 'react-highlight.js';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Paper from '@material-ui/core/Paper';
import './styles/color-brewer.css';
import './style.scss';

function Code(props) {
  const [md, setMd] = useState('');
  const [shown, setShown] = useState(false);
  const escapeHtml = props.escapeHtml !== undefined ? props.escapeHtml : true;
  let basename = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : '/';

  useEffect(() => {
    getMdFile(props.source);
    // eslint-disable-next-line
  }, []);

  const getMdFile = (name) => {
    const path = `${basename}Developer/${name}.md`;
    fetch(path)
      .then((r) => r.text())
      .then((text) => {
        setMd(text);
      })
      .catch((e) => console.log(e));
  };

  const toggleShown = () => {
    setShown(!shown);
  };

  return (
    <div>
      <h3>
        {props.title}
        <IconButton onClick={toggleShown}>
          <InfoIcon fontSize="small" />
        </IconButton>
      </h3>
      {shown && (
        <Paper className="hl-code">
          <Highlight language="javascript">
            <Markdown escapeHtml={escapeHtml} source={md} />
          </Highlight>
        </Paper>
      )}
    </div>
  );
}

export default Code;
