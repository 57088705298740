import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditAttachment } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import AttachmentIcon from 'components/AttachmentIcon';
import Checkbox2 from 'components/Checkbox2';
import TextField2 from 'components/TextField2';
import { useError } from 'utils/formStyles';
import { updateAttachment } from 'reducers/FileReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  txtField: {
    minWidth: 350,
  },
}));

function EditAttachment(props) {
  const classes = useStyles();
  const { FileType } = props.data;
  const [FileName, setFileName] = useState('');
  const [Description, setDescription] = useState('');
  const [IsDeleted, setIsDeleted] = useState(false);
  const [errors, setErrors] = useState();
  const { err, isValidAndChanged, formChanged } = useError(errors, setErrors);

  useEffect(() => {
    const { Description, IsDeleted, FileName } = props.data;
    setDescription(Description);
    setIsDeleted(Boolean(IsDeleted));
    setFileName(FileName.substr(0, FileName.length - FileType.length - 1));
    // eslint-disable-next-line
  }, []);

  const close = () => {
    props.closeEditAttachment();
  };

  const save = () => {
    const type = props.data.ParentType;
    const data = {
      ptsAttachmentID: props.data.ptsAttachmentID,
      FileName: `${FileName}.${FileType}`,
      Description,
      IsDeleted: IsDeleted ? 1 : 0,
    };
    props.showSpinner();
    updateAttachment(data)
      .then(() => {
        props.notifyDataUpdate({ type: 'file-upload', data: type });
        props.closeEditAttachment();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderFileName = () => {
    const onChange = (ev, val) => {
      setFileName(val);
      formChanged();
    };
    const error = err(FileName, 'FileName', 'File name cannot be empty');
    return (
      <div>
        <TextField2
          label="File Name"
          value={FileName}
          onChange={onChange}
          className={classes.txtField}
          error={error}
          max={255}
        />
      </div>
    );
  };

  const renderDescription = () => {
    const onChange = (ev, val) => {
      setDescription(val);
      formChanged();
    };
    return (
      <div>
        <TextField2
          label="Description"
          value={Description}
          onChange={onChange}
          className={classes.txtField}
          compact
          max={50}
        />
      </div>
    );
  };

  const renderIsDeleted = () => {
    const onChange = (ev, val) => {
      setIsDeleted(val);
      formChanged();
    };
    return (
      <div>
        <Checkbox2 checked={IsDeleted} onChange={onChange} label="Is Deleted" />
      </div>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit Attachment" actions={renderActions()}>
      <div className={classes.fileInfo}>
        <AttachmentIcon type={FileType} />
        {props.data.FileName}
      </div>
      {renderFileName()}
      {renderDescription()}
      {renderIsDeleted()}
    </Dialog>
  );
}

export default connect(null, {
  closeEditAttachment,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(EditAttachment);
