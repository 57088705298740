import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap } from '@react-google-maps/api';
import { mapNight, mapDay } from 'config/configureMap';

import { handleError } from 'reducers/ErrorReducer';
import MarkersEvents from './MarkersEvents';
import MarkersUnits from './MarkersUnits';
import EventPanel from './EventPanel';
import UnitPanel from './UnitPanel';
import { getLocalSettings, saveLocalSettings } from 'reducers/ConfigReducer';
import { updateUnitsResources } from 'reducers/UnitResourcesReducer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { addEvent } from 'reducers/DialogsReducer';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@material-ui/icons/Home';
import SelectedUnitMarker from './SelectedUnitMarker';
import './map.scss';

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  marker: {
    position: 'sticky',
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 3,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
  },
  activeMarker: {
    position: 'sticky',
    minWidth: 22,
    height: 22,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 4,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
    transform: 'translate(-1px, -1px)',
  },
  addresses: {
    height: 75,
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.25, 2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey4,
      },
    },
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

function Map(props) {
  const classes = useStyles();
  const { mapOptions, themeMode, units, mapActions, isAuthenticated } = props;
  const [menuPos, setMenuPos] = useState({ x: 100, y: 100 });
  const [coords, setCoords] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [mapBounds, setMapBounds] = useState({
    latMin: -90,
    latMax: 90,
    lngMin: -180,
    lngMax: 180,
  });
  const mapRef = useRef();
  const zoomRef = useRef(8);
  const centerRef = useRef({ lat: 30.39, lng: -91.07 });
  const menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [zoom, setZoom] = useState(16);
  const boundsRef = useRef({ latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 });
  const timeoutRef = useRef(0);
  const selectionRef = useRef(null);
  mapOptions.styles = themeMode === 'day' ? mapDay : mapNight;

  useEffect(() => {
    centerMap();
    props.updateUnitsResources();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedUnits.length) return;
    setSelectedUnits(
      selectedUnits.map((unit) => {
        const newUnit = units.find((u) => u.ptsUnitID === unit.ptsUnitID);
        return newUnit || unit;
      })
    );
    // eslint-disable-next-line
  }, [units]);

  useEffect(() => {
    if (!mapActions) return;
    const { type, zoom = 15, lat, lng } = mapActions;
    if (type === 'zoom-and-center') {
      zoomRef.current = zoom;
      centerRef.current = { lat, lng };
      setZoom(zoomRef.current);
      setCoords(centerRef.current);
    }
    // eslint-disable-next-line
  }, [mapActions]);

  const centerMap = () => {
    const settings = getLocalSettings();
    const { mapZoom, mapLat, mapLng } = settings;
    zoomRef.current = mapZoom;
    centerRef.current = { lat: mapLat, lng: mapLng };
    setZoom(zoomRef.current);
    setCoords(centerRef.current);
  };

  const saveMapSettings = () => {
    const zoom = mapRef.current.getZoom();
    const center = mapRef.current.getCenter().toJSON();
    saveLocalSettings({
      mapZoom: zoom,
      mapLat: center.lat,
      mapLng: center.lng,
    });
  };

  const MapSettings = () => (
    <>
      <IconButton onClick={saveMapSettings} className="map-icon-button">
        <SaveIcon />
      </IconButton>
      <IconButton onClick={centerMap} className="map-icon-button">
        <HomeIcon />
      </IconButton>
    </>
  );

  const onCenterChanged = () => {
    if (!mapRef.current) return;
    centerRef.current = mapRef.current.getCenter().toJSON();
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.setZoom(zoomRef.current);
    setZoom(zoomRef.current);
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(<MapSettings />, controlButtonDiv);
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(controlButtonDiv);
  }, []);

  const onMapClick = (ev) => {
    if (selectedUnits.length > 0) {
      setSelectedUnits([]);
      return;
    }
    if (!ev?.pixel?.x) return;
    const screen = {
      x: ev.pixel.x,
      y: ev.pixel.y,
    };
    const coords = {
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    };
    setMenuPos(screen);
    setCoords(coords);
    setAnchorEl(menuRef.current);
  };

  const onMapChange = (ev) => {
    clearTimeout(timeoutRef.current);
    if (selectionRef.current) selectionRef.current.style.display = 'none';
    timeoutRef.current = setTimeout(() => {
      updateMapBounds();
      if (selectionRef.current) selectionRef.current.style.display = 'block';
    }, 350);
  };

  const onZoomChanged = () => {
    if (!mapRef.current) return;
    onMapChange();
    zoomRef.current = mapRef.current.getZoom();
    setZoom(zoomRef.current);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const newEvent = () => {
    closeMenu();
    props.addEvent({
      Event: {
        CallType: null,
        CallMethod: null,
        RequestedAction: null,
        Description: null,
        lat: coords.lat,
        lng: coords.lng,
      },
    });
  };

  const onUnitClick = (ev, unit) => {
    ev.preventDefault();
    ev.stopPropagation();
    const ctrlKey = ev.ctrlKey;
    updateMapBounds();
    if (ctrlKey) {
      let newSelectedUnits;
      if (selectedUnits.findIndex((u) => u.ptsUnitID === unit.ptsUnitID) !== -1) {
        newSelectedUnits = selectedUnits.filter((u) => u.ptsUnitID !== unit.ptsUnitID);
      } else {
        newSelectedUnits = [...selectedUnits, unit];
      }
      setSelectedUnits(newSelectedUnits);
    } else {
      setSelectedUnits([unit]);
    }

    return false;
  };

  const updateMapBounds = () => {
    if (!mapRef.current) return;
    const bounds = mapRef.current.getBounds();
    if (!bounds || !bounds.tc || !bounds.Hb) return;
    const mapBounds = {
      latMin: bounds.tc.g,
      latMax: bounds.tc.i,
      lngMin: bounds.Hb.g,
      lngMax: bounds.Hb.i,
    };
    boundsRef.current = mapBounds;
    setMapBounds(mapBounds);
  };

  const renderAddEventMenu = () => (
    <>
      <div style={{ position: 'absolute', top: menuPos.y, left: menuPos.x }} ref={menuRef}></div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        <MenuItem onClick={newEvent}>New Event</MenuItem>
        <MenuItem onClick={closeMenu}>Close</MenuItem>
      </Menu>
    </>
  );

  const dragStart = () => {
    const dragObj = {
      units: selectedUnits.map((unit) => unit.ptsUnitID),
      ptsEventID: null,
    };
    localStorage.setItem('dragNdrop', JSON.stringify(dragObj));
  };

  const dragEnd = () => {
    localStorage.removeItem('dragNdrop');
    setSelectedUnits([]);
  };

  const renderMap = () => {
    return (
      <div className={classes.map}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoomRef.current}
          center={centerRef.current}
          options={mapOptions}
          key={themeMode}
          onLoad={onMapLoad}
          onZoomChanged={onZoomChanged}
          onCenterChanged={onCenterChanged}
          className={classes.map}
          onClick={onMapClick}
          onDrag={onMapChange}>
          <MarkersEvents />
          <MarkersUnits onUnitClick={onUnitClick} />
        </GoogleMap>
        {renderAddEventMenu()}
        <div ref={selectionRef}>
          {zoom > 6 &&
            selectedUnits.map((unit) => (
              <SelectedUnitMarker
                unit={unit}
                key={unit.ptsUnitID}
                mapBounds={mapBounds}
                onClick={onUnitClick}
                dragStart={dragStart}
                dragEnd={dragEnd}
                zoom={zoom}
              />
            ))}
        </div>
        <EventPanel />
        <UnitPanel />
      </div>
    );
  };

  return <div className={classes.map}>{isAuthenticated && renderMap()}</div>;
}

const mapStateToProps = (state) => {
  return {
    units: state.units,
    themeMode: state.theme.mode,
    mapOptions: state.map.options,
    mapActions: state.map.action,
    isAuthenticated: state.user.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  handleError,
  updateUnitsResources,
  addEvent,
})(Map);
