import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect, useDispatch } from 'react-redux';

import { closePeopleSOP, showPeopleSOP } from 'reducers/DialogsReducer';
import { savePeopleSOP, deletePeopleSOP } from 'reducers/PersonReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

import { handleError } from 'reducers/ErrorReducer';

import CheckIcon from '@material-ui/icons/Check';

import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { getPeopleSops } from 'reducers/SearchReducer';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Dialog from 'components/Dialog';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SOPFilter from '../Address/SOPFilter';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);
// const showIcon = (params) => {
//   return <AttachmentIcon type={params.row.FileType} style={{ width: 24, height: 'auto' }} />;
// };
const defaultColumns = [
  {
    field: 'SOPID',
    headerName: 'Title',
    valueGetter: (params) => params.row.SOP.SOPID,
    width: 200,
    hide: false,
  },
  {
    field: 'DispatcherMessage',
    headerName: 'Dispatcher Message',
    valueGetter: (params) => params.row.SOP.DispatcherMessage,
    width: 150,
    hide: false,
  },
  {
    field: 'AlertMessage',
    headerName: 'Alert Message',
    valueGetter: (params) => params.row.SOP.AlertMessage,
    width: 150,
    hide: false,
  },
  {
    field: 'IsPagingMessageUsed',
    headerName: 'Is paging used',
    valueGetter: (params) => params.row.SOP.IsPagingMessageUsed,
    width: 150,
    hide: false,
  },
  {
    field: 'ShowPopupNotification',
    headerName: 'Popup Notification',
    valueGetter: (params) => params.row.SOP.ShowPopupNotification,
    width: 150,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  { field: 'IsDeleted', headerName: 'Is Deleted', width: 150, hide: false },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 150, hide: false },
];

const Sops = (props) => {
  const classes = useStyles();
  const { personId, addPeopleSOP } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedSOP, setSelectedSOP] = useState(null);

  useEffect(() => {
    personId && getData();
  }, []);

  const getData = async () => {
    try {
      const data = await getPeopleSops(personId);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      console.log(err);
      props.handleError(err, 'Error receiving person sops data.');
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const add = () => {
    dispatch(showPeopleSOP({ ptsPersonId: personId }));
  };

  const close = () => {
    dispatch(closePeopleSOP());
  };

  const save = async () => {
    dispatch(showSpinner());
    await savePeopleSOP({
      ptsParentID: personId,
      ParentType: 'Person',
      ptsSOPID: selectedSOP,
    })
      .then(() => {
        close();
        getData();
        dispatch(hideSpinner());
      })
      .catch(() => {
        close();
        getData();
        dispatch(hideSpinner());
      });
  };

  const deleteSOP = async () => {
    const item = rows.find((row) => row.id === selection);

    const updatedData = rows.filter((row) => row.id !== selection);
    setRows(updatedData);

    if (item) {
      dispatch(showSpinner());
      await deletePeopleSOP({
        ptsParentID: personId,
        ParentType: 'Person',
        ptsSOPID: item.ptsSOPID,
      })
        .then(() => {
          getData();
          dispatch(hideSpinner());
        })
        .catch((err) => {
          console.log(err);
          getData();
          dispatch(hideSpinner());
        });
    }
  };

  const formContent = () => {
    return <SOPFilter selectSOP={setSelectedSOP} />;
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add SOP">
            <Fab size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Delete SOP">
                <Fab size="small" color="secondary" onClick={deleteSOP}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      {addPeopleSOP && (
        <Dialog toolbar onClose={close} title="Add SOP" actions={renderActions()}>
          {formContent()}
        </Dialog>
      )}

      <XGrid3
        name="personSops"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    addPeopleSOP: state.dialogs.addPeopleSOP,
  };
};
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(Sops);
