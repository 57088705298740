import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { XGrid, GridToolbar } from '@material-ui/x-grid';
import { arraysWithSameValues } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

function XGrid3(props) {
  const classes = useStyles();
  const { name, columns, setColumns, rows, loading, setSelection } = props;
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);

  useEffect(() => {
    adjustColsWidth();
    const fetchedLSTables = getLocalSearchTables();
    setTables(fetchedLSTables);
    const { sequence } = fetchedLSTables[name];
    updateColsOrder(sequence);
    setColsSeq(sequence);
  }, []);

  useEffect(() => {
    if (colsSeq.length === 0) return;
    saveLSTSequence(name, colsSeq);
    updateColsOrder(colsSeq);
  }, [colsSeq]);

  useEffect(() => {
    if (!tables) return;
    saveLocalSearchTables(tables);
    updateColsHideProperty();
  }, [tables]);

  const adjustColsWidth = () => {
    const newColumns = columns.map((col) => {
      const { width } = col;
      if (!width) col.width = 150;
      return col;
    });
    setColumns(newColumns);
  };

  const saveLSTSequence = (table, cols) => {
    const currentTables = getLocalSearchTables();
    const targetTable = currentTables[[table]];
    const newTables = { ...currentTables, [table]: { ...targetTable, sequence: cols } };
    localStorage.setItem('SearchTables', JSON.stringify(newTables));
    setTables(newTables);
  };

  const getLocalSearchTables = () => {
    const initialColSeq = columns.map((col) => col.field);
    const currentTable = {
      [name]: {
        hiddenCols: [],
        sequence: initialColSeq,
      },
    };
    const savedTables = localStorage.getItem('SearchTables');
    if (!savedTables) {
      localStorage.setItem('SearchTables', JSON.stringify(currentTable));
      return currentTable;
    }
    const parsedTables = JSON.parse(savedTables);
    if (parsedTables[name] && arraysWithSameValues(parsedTables[name].sequence, initialColSeq))
      return parsedTables;
    const newTables = { ...parsedTables, ...currentTable };
    localStorage.setItem('SearchTables', JSON.stringify(newTables));
    return newTables;
  };

  const saveLocalSearchTables = (tables) =>
    localStorage.setItem('SearchTables', JSON.stringify(tables));

  const updateColsHideProperty = () => {
    const { hiddenCols } = tables[name];
    const columnsClone = [...columns];
    for (const col of columnsClone) {
      col.hide = Boolean(hiddenCols.find((hc) => hc === col.field));
    }
    setColumns(columnsClone);
  };

  const handleColVisChange = (col) => {
    if (!col) return;
    const tablesClone = { ...tables };
    if (col.isVisible) {
      const filteredHiddenCols = tablesClone[name].hiddenCols.filter((c) => c !== col.field);
      const filteredTables = {
        ...tablesClone,
        [name]: { ...tablesClone[name], hiddenCols: filteredHiddenCols },
      };
      setTables(filteredTables);
    } else {
      tablesClone[name].hiddenCols.push(col.field);
      setTables(tablesClone);
    }
  };

  const handleColOrderChange = (col) => {
    if (!col) return;
    const seqClone = [...colsSeq];
    const { oldIndex, targetIndex } = col;
    const current = seqClone[oldIndex];
    if (oldIndex < targetIndex) {
      for (let i = oldIndex; i < targetIndex; i++) {
        seqClone[i] = seqClone[i + 1];
      }
    } else {
      for (let i = oldIndex; i > targetIndex; i--) {
        seqClone[i] = seqClone[i - 1];
      }
    }
    seqClone[targetIndex] = current;
    setColsSeq(seqClone);
  };

  const updateColsOrder = (sequence) => {
    const columnsClone = [...columns];
    const result = [];
    sequence.forEach((c) => {
      const current = columnsClone.find((cc) => cc.field === c);
      result.push(current);
    });
    setColumns(result);
  };

  return (
    <div className={classes.gridWrap}>
      <XGrid
        {...props}
        rowHeight={38}
        disableMultipleSelection={true}
        showToolbar
        // disableColumnFilter
        onSelectionModelChange={(newSelection) => setSelection(newSelection[0])}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{ panel: { className: classes.panel } }}
        onColumnVisibilityChange={(col) => handleColVisChange(col)}
        onColumnOrderChange={(col) => handleColOrderChange(col)}
      />
    </div>
  );
}

export default XGrid3;
