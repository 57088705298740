import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'Routes';
import store from 'config/configureStore';
import { history } from 'config/configureStore';
import ScrollToTop from 'utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from 'theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import NotificationPanel from 'components/NotificationPanel';
import Dialogs from 'Dialogs';
import { useLoadScript } from '@react-google-maps/api';
import { notify } from 'reducers/NotifierReducer';
import 'assets/base.scss';
import 'icons';
import { subscribeEvents, unsubscribeEvents } from 'reducers/EventsReducer';
import { subscribeUnits, unsubscribeUnits } from 'reducers/UnitsReducer';
import { subscribeE911, unsubscribeE911 } from 'reducers/E911Reducer';
import { subscribeBulletins, unsubscribeBulletins } from 'reducers/BulletinReducer';
import { subscribeDictionary, unsubscribeDictionary } from 'reducers/DictionaryReducer';
import { getSettings } from 'reducers/ConfigReducer';
import { getDictionaries } from 'reducers/DictionaryReducer';
import { updateZones } from 'reducers/ZonesReducer';
import { LicenseInfo } from '@material-ui/x-grid';
import { findUnreadUserBulletins } from 'reducers/BulletinReducer';
import { checkPassExpiration } from 'reducers/UserReducer';
import { handleError } from 'reducers/ErrorReducer';
import IdleTimerContainer from 'components/IdleTimerContainer';
import {
  getUserPermissions,
  subscribePermissions,
  unsubscribePermissions,
} from 'reducers/PermissionsReducer';
import { getServerTimeSettings, timeSyncInit } from './reducers/TimeReducer';
import { subscribeKeyboard, unsubscribeKeyboard } from './reducers/KeyboardReducer';
import GenericKeyboardHandler from 'components/GenericKeyboardHandler';

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const App = (props) => {
  const snackbarRef = useRef();
  const [mapError, setMapError] = useState(false);
  const {
    authenticated,
    websocket,
    subscribeEvents,
    subscribeUnits,
    subscribeE911,
    unsubscribeE911,
    unsubscribeEvents,
    unsubscribeUnits,
    subscribeBulletins,
    unsubscribeBulletins,
    subscribeDictionary,
    unsubscribeDictionary,
    subscribePermissions,
    unsubscribePermissions,
    findUnreadUserBulletins,
    ui,
    getSettings,
    updateZones,
    timeSyncInit,
    getDictionaries,
    checkPassExpiration,
    getUserPermissions,
    getServerTimeSettings,
    subscribeKeyboard,
    unsubscribeKeyboard,
  } = props;

  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    // libraries: mapLibraries,
  });
  // if (loadError && !mapError) setMapError(true);
  const { showSpinner } = ui;
  const themeMode = () => (props.mode === 'night' ? NightTheme : DayTheme);

  // useEffect(() => {
  //   if (!mapError) return;
  //   props.notify('Map loading error', 'error');
  //   // eslint-disable-next-line
  // }, [mapError]);

  useEffect(() => {
    if (websocket.websocket && authenticated) {
      getUserPermissions();
      //subscribeEvents();
      //subscribeUnits();
      //subscribeE911();
      getSettings();
      getDictionaries();
      //updateZones();
      //subscribeBulletins();
      timeSyncInit();
      // findUnreadUserBulletins();
      checkPassExpiration();
      //subscribeDictionary();
      getServerTimeSettings();
      subscribePermissions();
      //subscribeKeyboard();
    }
    return () => {
      // unsubscribeEvents();
      // unsubscribeUnits();
      // unsubscribeE911();
      // unsubscribeBulletins();
      unsubscribeDictionary();
      unsubscribePermissions();
      // unsubscribeKeyboard();
    };
    // eslint-disable-next-line
  }, [websocket, authenticated]);

  const closeSnackbar = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  if (!isLoaded) return 'Loading...';

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
              <SnackbarProvider
                ref={snackbarRef}
                action={(key) => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <NotificationPanel />
                <Routes />
                <IdleTimerContainer />
                <GenericKeyboardHandler />
              </SnackbarProvider>
              <Dialogs />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  mode: state.theme.mode,
  ui: state.ui,
  websocket: state.websocket,
  authenticated: state.user.isAuthenticated,
  user: state.user,
  addPage: state.paging.addPage,
});

export default connect(mapStateToProps, {
  getSettings,
  subscribeEvents,
  notify,
  updateZones,
  unsubscribeEvents,
  subscribeUnits,
  unsubscribeUnits,
  subscribeE911,
  unsubscribeE911,
  subscribeBulletins,
  unsubscribeBulletins,
  findUnreadUserBulletins,
  timeSyncInit,
  getDictionaries,
  handleError,
  checkPassExpiration,
  subscribeDictionary,
  unsubscribeDictionary,
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions,
  getServerTimeSettings,
  subscribeKeyboard,
  unsubscribeKeyboard,
})(App);
