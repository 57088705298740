import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() => ({
  formSection: {
    paddingBottom: '10px',
  },
}));

const OlnStateFilter = (props) => {
  const classes = useStyles();

  const handleFilterOlnStateChange = (event, value) => {
    props.setSelectedOlnState(value);
  };

  const getLabel = (option) => option.Code;
  const getOptionLabel = (option) =>
    option.Code + (option.Description ? ` - ${option.Description}` : '');

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        labelid="plate-state"
        value={props.selectedOlnState}
        onChange={handleFilterOlnStateChange}
        options={props.states}
        getOptionLabel={getLabel}
        renderOption={getOptionLabel}
        getOptionSelected={(option, value) => option.code === value.code}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="OLN State" variant="outlined" size="small" />
        )}
      />
    </FormControl>
  );
};

export default OlnStateFilter;
