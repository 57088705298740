import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
// import Events from './Events';
// import Booking from './Booking';
// import Cases from './Cases';

const useStyles = makeStyles((theme) => ({
  wrap: {
    background: theme.colors.paper,
  },
}));

function VFC() {
  const classes = useStyles();
  const [tab, setTab] = useState('events'); // events, cases, booking

  return (
    <div className={classes.wrap}>
      <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
        <Tab label="Events" value="events" />
        <Tab label="Cases" value="cases" />
        <Tab label="Booking" value="booking" />
        <Tab label="Warrants" value="warrants" />
      </Tabs>
      {/* {tab === 'events' && <Events />}
      {tab === 'cases' && <Cases />}
      {tab === 'booking' && <Booking />} */}
    </div>
  );
}

export default VFC;
