import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import Radio2 from 'components/Radio2';
import formStyles, { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import { getAddressDetails } from 'reducers/AddressReducer';
import { getAddressFromLocation } from 'utils/mapFunctions';
import { getService } from 'reducers/service';
import SaveIcon from '@material-ui/icons/Save';
import { notify } from 'reducers/NotifierReducer';
import { Button, RadioGroup, FormControl } from '@material-ui/core';
import Dictionary from 'components/Dictionary';
import { showAddressDialog } from 'reducers/DialogsReducer';
import { useError, validatePostcode, parsePostcode } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getAccessPermission } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  addressCategory: gridStyle(220, 220),
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function AddressEdit(props) {
  // TODO: validation as in: src\Dialogs\Address\AddressParties.js
  const classes = useStyles();
  const { dictionary, ptsAddressID } = props;
  const {
    AddressCategories,
    StreetDirections,
    StreetTypes,
    Cities,
    States,
    Counties,
    Countries,
    AddressUnitTypes,
    AddressFloorTypes,
    ESN,
  } = dictionary;
  const [data, setData] = useState(null);
  const [AddressCategory, setAddressCategory] = useState(null);
  const [AddressNumber, setAddressNumber] = useState('');
  const [AddressNumberPrefix, setAddressNumberPrefix] = useState('');
  const [AddressNumberSuffix, setAddressNumberSuffix] = useState('');
  const [PreDirection, setPreDirection] = useState(null);
  const [PostDirection, setPostDirection] = useState(null);
  const [StreetName, setStreetName] = useState('');
  const [StreetType, setStreetType] = useState(null);
  const [City, setCity] = useState(null);
  const [State, setState] = useState(null);
  const [Postal, setPostal] = useState('');
  const [County, setCounty] = useState(null);
  const [Country, setCountry] = useState(null);
  const [Notes, setNotes] = useState('');
  const [UnitType, setUnitType] = useState(null);
  const [UnitIdentifier, setUnitIdentifier] = useState('');
  const [FloorType, setFloorType] = useState(null);
  const [FloorIdentifier, setFloorIdentifier] = useState('');
  const [BldgType, setBldgType] = useState(null);
  const [BldgIdentifier, setBldgIdentifier] = useState('');
  const [ShowInEventLookup, setShowInEventLookup] = useState(false);
  const [SexOffender, setSexOffender] = useState(false);
  const [Parolee, setParolee] = useState(false);
  const [Probation, setProbation] = useState(false);
  const [NonValidatedAddress, setNonValidatedAddress] = useState(false);
  const [CleryLocationRadio, setCleryLocationRadio] = useState(null);
  const [CrossStreet, setCrossStreet] = useState('');
  const [Highway, setHighway] = useState('');
  const [Esn, setEsn] = useState(null);
  // const [SubdivisionName, setSubdivisionName] = useState('');
  const [Landmark, setLandmark] = useState('');
  const [SurroundingArea, setSurroundingArea] = useState('');
  const [Area, setArea] = useState('');
  const [Description, setDescription] = useState('');
  const [RangeDescription, setRangeDescription] = useState('');
  const [MapLocation, setMapLocation] = useState('');
  const [RelativeLocation, setRelativeLocation] = useState('');
  const [AltitudeMeasure, setAltitudeMeasure] = useState('');
  const [DepthMeasure, setDepthMeasure] = useState('');
  const [GeographicalElevation, setGeographicalElevation] = useState('');
  const [Coordinates, setCoordinates] = useState('');
  const [UtmCoordinate, setUtmCoordinate] = useState('');
  const [TwoDGeographicalLocation, setTwoDGeographicalLocation] = useState('');
  const [FullAddressText, setFullAddressText] = useState('');
  const [errors, setErrors] = useState();
  const { err, isValid } = useError(errors, setErrors, true);
  const mountedRef = useRef(true);

  const userName = useSelector((state) => state.user.userData.user.Username);

  useEffect(() => {
    if (!data) return;
    setAddressCategory(AddressCategories.find((a) => a.Code === data.AddressCategory) || null);
    setAddressNumber(data.AddressNumber || '');
    setAddressNumberPrefix(data.AddressNumberPrefix || '');
    setAddressNumberSuffix(data.AddressNumberSuffix || '');
    setPreDirection(StreetDirections.find((d) => d.Code === data.PreDirection) || null);
    setPostDirection(StreetDirections.find((d) => d.Code === data.PostDirection) || null);
    setStreetName(data.StreetName || '');
    setStreetType(StreetTypes.find((st) => st.Code === data.StreetType) || null);
    setCity(Cities.find((c) => c.ptsCityID === data.ptsCityID) || null);
    setState(States.find((s) => s.Code === data.State) || null);
    renderExistingPostal(data.PostalCode, data.PostalCodeExtension);
    setCounty(Counties.find((c) => c.Code === data.County) || null);
    setCountry(Countries.find((c) => c.Code === data.Country) || null);
    setNotes(data.Notes || '');
    setUnitType(AddressUnitTypes.find((a) => a.Code === data.UnitType) || null);
    setUnitIdentifier(data.UnitIdentifier || '');
    setFloorType(AddressFloorTypes.find((a) => a.Code === data.FloorType) || null);
    setFloorIdentifier(data.FloorIdentifier || '');
    setBldgType(data.BldgType);
    setBldgIdentifier(data.BldgIdentifier || '');
    setShowInEventLookup(data.ShowInEventLookup);
    setSexOffender(data.SexOffender);
    setParolee(data.Parolee);
    setProbation(data.Probation);
    setNonValidatedAddress(data.NonValidatedAddress);
    //setCleryLocationRadio(data.CLERYLocationType)
    if (data.IsCampusResidential === true) setCleryLocationRadio('CampusResidential');
    else if (data.IsCampusNonResidential === true) setCleryLocationRadio('CampusNonResidential');
    else if (data.IsNonCampus === true) setCleryLocationRadio('NonCampus');
    else if (data.IsPublic === true) setCleryLocationRadio('Public');
    setCrossStreet(data.CrossStreet || '');
    setHighway(data.Highway || '');
    setEsn(ESN.find((e) => e.ESN === data.ESN) || null);
    //setSubdivisionName();  //cant find in fetched data
    setLandmark(data.LandmarkText || '');
    setSurroundingArea(data.SurroundingArea || '');
    setArea(data.Area || '');
    setDescription(data.Description || '');
    setRangeDescription(data.RangeDescription || '');
    setMapLocation(data.MapLocation || '');
    setRelativeLocation(data.RelativeLocation || '');
    setAltitudeMeasure(data.AltitudeMeasure || '');
    setDepthMeasure(data.DepthMeasure || '');
    setGeographicalElevation(data.GeographicElevation || '');
    setCoordinates(data.MGRSCoordinate || '');
    setUtmCoordinate(data.UTMCoordinate || '');
    setTwoDGeographicalLocation(data.TwoDGeographicCoordinate || '');
    setFullAddressText(data.FullAddressText ? data.FullAddressText : '');
  }, [data]);

  useEffect(() => {
    const address = getAddressObj();
    const FullAddressText = getAddressFromLocation(address);
    setFullAddressText(FullAddressText);
    // eslint-disable-next-line
  }, [
    AddressNumber,
    AddressNumberPrefix,
    AddressNumberSuffix,
    PreDirection,
    PostDirection,
    StreetName,
    StreetType,
    City,
    State,
    Postal,
    UnitType,
    UnitIdentifier,
  ]);

  useEffect(() => {
    if (!ptsAddressID) return;
    getAddress();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [ptsAddressID]);

  const getAddress = async () => {
    try {
      const data = await getAddressDetails(ptsAddressID);
      if (!mountedRef.current || !data.data || !data.data.length) return;
      setData(data.data[0]);
      processData(data.data[0]);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    const AddressCategory = AddressCategories.find((a) => a.Code === data.AddressCategory) || null;
    setAddressCategory(AddressCategory);
  };

  const renderExistingPostal = (code, ext) => {
    if (!code) return;
    if (!ext) setPostal(code);
    else setPostal(`${code} - ${ext}`);
  };

  const getAddressObj = () => {
    const { PostalCode, PostalCodeExtension } = parsePostcode(Postal);
    return {
      ...data,
      ptsAddressID: ptsAddressID,
      AddressCategory: AddressCategory?.Code || null,
      AddressNumber: AddressNumber || null,
      AddressNumberPrefix: AddressNumberPrefix || null,
      AddressNumberSuffix: AddressNumberSuffix || null,
      PreDirection: PreDirection?.Code || null,
      PostDirection: PostDirection?.Code || null,
      StreetName: StreetName || null,
      StreetType: StreetType?.Code || null,
      ptsCityID: City?.ptsCityID || null,
      State: State?.Code || null,
      PostalCode,
      PostalCodeExtension,
      County: County?.Code || null,
      Country: Country?.Code || null,
      Notes: Notes || null,
      UnitType: UnitType?.Code || null,
      UnitIdentifier: UnitIdentifier,
      FloorType: FloorType?.Code || null,
      FloorIdentifier: FloorIdentifier,
      BldgType: BldgType?.Code || null,
      BldgIdentifier: BldgIdentifier,
      ShowInEventLookup: ShowInEventLookup === true ? 1 : 0,
      SexOffender: SexOffender === true ? 1 : 0,
      Parolee: Parolee === true ? 1 : 0,
      Probation: Probation === true ? 1 : 0,
      NonValidatedAddress: NonValidatedAddress === true ? 1 : 0,
      IsCampusResidential: CleryLocationRadio === 'CampusResidential' ? 1 : 0,
      IsCampusNonResidential: CleryLocationRadio === 'CampusNonResidential' ? 1 : 0,
      IsNonCampus: CleryLocationRadio === 'NonCampus' ? 1 : 0,
      IsPublic: CleryLocationRadio === 'Public' ? 1 : 0,
      CrossStreet: CrossStreet,
      Highway: Highway,
      ESN: Esn?.ESN || null,
      LandmarkText: Landmark,
      SurroundingArea: SurroundingArea,
      Area: Area,
      Description: Description,
      RangeDescription: RangeDescription,
      MapLocation: MapLocation,
      RelativeLocation: RelativeLocation,
      AltitudeMeasure: AltitudeMeasure,
      DepthMeasure: DepthMeasure,
      GeographicElevation: GeographicalElevation,
      MGRSCoordinate: Coordinates,
      UTMCoordinate: UtmCoordinate,
      TwoDGeographicCoordinate: TwoDGeographicalLocation,
      FullAddressText,
    };
  };

  const handleSave = () => {
    const address = getAddressObj();
    const service = getService('cad-history-edit-address');
    service
      .create({ ...address, CurrentUser: userName })
      .then((data) => {
        const { ptsAddressID } = data;
        props.notifyDataUpdate({ type: 'Address', data });
        if (ptsAddressID) props.showAddressDialog({ ptsAddressID });
        props.notify('Address saved', 'success');
      })
      .catch((err) => props.handleError(err, 'Error saving address'));
  };

  const renderAddressCategory = () => {
    const onChange = (ev, val) => setAddressCategory(val);
    return (
      <Dictionary
        options="AddressCategories"
        className={classes.addressCategory}
        onChange={onChange}
        value={AddressCategory}
        label="Address Category"
      />
    );
  };

  const renderAddressNumberPrefix = () => {
    const onChange = (ev, val) => setAddressNumberPrefix(val);
    return (
      <TextField2
        className={classes.item}
        label="Prefix"
        value={AddressNumberPrefix}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderAddressNumberSuffix = () => {
    const onChange = (ev, val) => setAddressNumberSuffix(val);
    return (
      <TextField2
        className={classes.item}
        label="Suffix"
        value={AddressNumberSuffix}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderAddressNumber = () => {
    const onChange = (ev, val) => setAddressNumber(val);
    const error = err(AddressNumber, 'AddressNumber');
    return (
      <TextField2
        className={classes.item}
        label="Number"
        value={AddressNumber}
        onChange={onChange}
        type="number"
        error={error}
      />
    );
  };

  const renderPreDirection = () => {
    const onChange = (ev, val) => setPreDirection(val);
    return (
      <Dictionary
        options="StreetDirections"
        className={classes.item}
        onChange={onChange}
        value={PreDirection}
        label="Predirectional"
      />
    );
  };

  const renderStreetName = () => {
    const onChange = (ev, val) => setStreetName(val);
    const error = err(StreetName.length > 1, 'StreetName');
    return (
      <TextField2
        className={classes.item}
        label="Street Name"
        value={StreetName}
        onChange={onChange}
        error={error}
        max={100}
      />
    );
  };

  const renderStreetType = () => {
    const onChange = (ev, val) => setStreetType(val);
    return (
      <Dictionary
        options="StreetTypes"
        className={classes.item}
        onChange={onChange}
        value={StreetType}
        label="Street Type"
      />
    );
  };

  const renderPostDirection = () => {
    const onChange = (ev, val) => setPostDirection(val);
    return (
      <Dictionary
        options="StreetDirections"
        className={classes.item}
        onChange={onChange}
        value={PostDirection}
        label="Postdirectional"
      />
    );
  };

  const renderCities = () => {
    const onChange = (ev, val) => setCity(val);
    const error = err(City, 'City');
    return (
      <Dictionary
        options="Cities"
        className={classes.item}
        onChange={onChange}
        value={City}
        label="City"
        error={error}
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => setState(val);
    const error = err(State, 'State');
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange}
        value={State}
        label="State"
        error={error}
      />
    );
  };

  const renderPostal = () => {
    const onChange = (ev, val) => setPostal(val);
    const isValid = !Postal || validatePostcode(Postal);
    const error = err(isValid, 'Postal', 'Must consist of 5 or 9 digits');
    return (
      <TextField2
        className={classes.item}
        label="Postal Code - Ext."
        value={Postal}
        onChange={onChange}
        error={error}
        type="postcode"
      />
    );
  };

  const renderCounty = () => {
    const onChange = (ev, val) => setCounty(val);
    return (
      <Dictionary
        className={classes.item}
        options="Counties"
        value={County}
        onChange={onChange}
        label="County"
      />
    );
  };

  const renderCountry = () => {
    const onChange = (ev, val) => setCountry(val);
    return (
      <Dictionary
        className={classes.item}
        options="Countries"
        value={Country}
        onChange={onChange}
        label="Country"
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <TextField2
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        multiline
        rows={4}
      />
    );
  };

  const renderUnitType = () => {
    const onChange = (ev, val) => setUnitType(val);
    const error = !UnitType && UnitIdentifier ? 'Unit Type is missing' : null;
    return (
      <Dictionary
        className={classes.item}
        options="AddressUnitTypes"
        value={UnitType}
        onChange={onChange}
        label="Unit Type"
        error={error}
      />
    );
  };

  const renderUnitIdentifier = () => {
    const onChange = (ev, val) => setUnitIdentifier(val);
    const error = UnitType && !UnitIdentifier ? 'Unit Identifier is missing' : null;
    return (
      <TextField2
        className={classes.item}
        label="Unit Identifier"
        value={UnitIdentifier}
        onChange={onChange}
        max={20}
        error={error}
      />
    );
  };

  const renderFloorType = () => {
    const onChange = (ev, val) => setFloorType(val);
    return (
      <Dictionary
        className={classes.item}
        options="AddressFloorTypes"
        value={FloorType}
        onChange={onChange}
        label="Floor Type"
      />
    );
  };

  const renderFloorIdentifier = () => {
    const onChange = (ev, val) => setFloorIdentifier(val);
    return (
      <TextField2
        className={classes.item}
        label="Floor Identifier"
        value={FloorIdentifier}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderBldgType = () => {
    const onChange = (ev, val) => setBldgType(val);
    return (
      <Dictionary
        className={classes.item}
        options="AddressBldgTypes"
        value={BldgType}
        onChange={onChange}
        label="Building Type"
      />
    );
  };

  const renderBldgIdentifier = () => {
    const onChange = (ev, val) => setBldgIdentifier(val);
    return (
      <TextField2
        className={classes.item}
        label="Building identifier"
        value={BldgIdentifier}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderShowInEventLookup = () => {
    const onChange = () => setShowInEventLookup(!ShowInEventLookup);
    const access = getAccessPermission('cad', 'Setup Event Address Lookup');
    return (
      <Checkbox2
        checked={ShowInEventLookup}
        onChange={onChange}
        label="Show In Event Lookup"
        disabled={!access}
      />
    );
  };

  const renderSexOffender = () => {
    const onChange = () => setSexOffender(!SexOffender);
    return <Checkbox2 checked={SexOffender} onChange={onChange} label="Sex Offender" />;
  };

  const renderParolee = () => {
    const onChange = () => setParolee(!Parolee);
    return <Checkbox2 checked={Parolee} onChange={onChange} label="Parolee" />;
  };

  const renderProbation = () => {
    const onChange = () => setProbation(!Probation);
    return <Checkbox2 checked={Probation} onChange={onChange} label="Probation" />;
  };

  const renderNonValidatedAddress = () => {
    const onChange = () => setNonValidatedAddress(!NonValidatedAddress);
    return (
      <Checkbox2 checked={NonValidatedAddress} onChange={onChange} label="Non Validated Address" />
    );
  };

  const renderCleryRadio = () => {
    const onChange = (ev, val) => setCleryLocationRadio(val);
    return (
      <FormControl component="fieldset">
        <RadioGroup row value={CleryLocationRadio} onChange={onChange}>
          <Radio2 value="CampusResidential" label="Campus Residential" />
          <Radio2 value="CampusNonResidential" label="Campus Non-Residential" />
          <Radio2 value="NonCampus" label="Non-Campus" />
          <Radio2 value="Public" label="Public" />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderCrossStreet = () => {
    const onChange = (ev, val) => setCrossStreet(val);
    return (
      <TextField2
        className={classes.item}
        label="Cross Street"
        value={CrossStreet}
        onChange={onChange}
        max={100}
      />
    );
  };

  const renderHighway = () => {
    const onChange = (ev, val) => setHighway(val);
    return (
      <TextField2
        className={classes.item}
        label="Highway"
        value={Highway}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderESN = () => {
    const onChange = (ev, val) => setEsn(val);
    return (
      <Dictionary
        className={classes.item}
        options="ESN"
        value={Esn}
        onChange={onChange}
        label="ESN"
      />
    );
  };

  const renderLandmark = () => {
    const onChange = (ev, val) => setLandmark(val);
    return (
      <TextField2
        className={classes.item}
        label="Landmark"
        value={Landmark}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderSurroundingArea = () => {
    const onChange = (ev, val) => setSurroundingArea(val);
    return (
      <TextField2
        className={classes.item}
        label="Surrounding Area"
        value={SurroundingArea}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderArea = () => {
    const onChange = (ev, val) => setArea(val);
    return (
      <TextField2 className={classes.item} label="Area" value={Area} onChange={onChange} max={50} />
    );
  };

  const renderDescription = () => {
    const onChange = (ev, val) => setDescription(val);
    return (
      <TextField2
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={100}
      />
    );
  };

  const renderRangeDescription = () => {
    const onChange = (ev, val) => setRangeDescription(val);
    return (
      <TextField2
        className={classes.item}
        label="Range Description"
        value={RangeDescription}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderMapLocation = () => {
    const onChange = (ev, val) => setMapLocation(val);
    return (
      <TextField2
        className={classes.item}
        label="Map Location"
        value={MapLocation}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderRelativeLocation = () => {
    const onChange = (ev, val) => setRelativeLocation(val);
    return (
      <TextField2
        className={classes.item}
        label="Relative Location"
        value={RelativeLocation}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderAltitudeMeasure = () => {
    const onChange = (ev, val) => setAltitudeMeasure(val);
    return (
      <TextField2
        className={classes.item}
        label="Altitude Measure"
        value={AltitudeMeasure}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderDepthMeasure = () => {
    const onChange = (ev, val) => setDepthMeasure(val);
    return (
      <TextField2
        className={classes.item}
        label="Depth Measure"
        value={DepthMeasure}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderGeographicalElevation = () => {
    const onChange = (ev, val) => setGeographicalElevation(val);
    return (
      <TextField2
        className={classes.item}
        label="Geo. Elevation"
        value={GeographicalElevation}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderCoordinates = () => {
    const onChange = (ev, val) => setCoordinates(val);
    return (
      <TextField2
        className={classes.item}
        label="Coordinates"
        value={Coordinates}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderUtmCoordinate = () => {
    const onChange = (ev, val) => setUtmCoordinate(val);
    return (
      <TextField2
        className={classes.item}
        label="Utm Coordinate"
        value={UtmCoordinate}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderTwoDGeographicalLocation = () => {
    const onChange = (ev, val) => setTwoDGeographicalLocation(val);
    return (
      <TextField2
        className={classes.item}
        label="2-D Geographical Location"
        value={TwoDGeographicalLocation}
        onChange={onChange}
        max={25}
      />
    );
  };

  // create columns and set min and max width of the column
  return (
    <div className={classes.wrap}>
      <div className={classes.rowSpaces}>
        <h4 className={classes.w50pr}>{FullAddressText}</h4>
        {renderAddressCategory()}
      </div>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Address Details" />
          <RowInner>
            {renderAddressNumberPrefix()}
            {renderAddressNumber()}
            {renderAddressNumberSuffix()}
            {renderPreDirection()}
            {renderStreetName()}
            {renderStreetType()}
            {renderPostDirection()}
            {renderCities()}
            {renderState()}
            {renderPostal()}
            {renderCounty()}
            {renderCountry()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Notes" />
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Identifiers" />
          <RowInner>
            {renderUnitType()}
            {renderUnitIdentifier()}
            {renderFloorType()}
            {renderFloorIdentifier()}
            {renderBldgType()}
            {renderBldgIdentifier()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Flags" />
          <RowInner>
            {renderShowInEventLookup()}
            {renderSexOffender()}
            {renderParolee()}
            {renderProbation()}
            {renderNonValidatedAddress()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Clery Location" />
          <RowInner>{renderCleryRadio()}</RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Location" />
          <RowInner>
            {renderCrossStreet()}
            {renderHighway()}
            {renderESN()}
            {/* {renderSubdivisionName()} */}
            {renderLandmark()}
            {renderSurroundingArea()}
            {renderArea()}
            {renderDescription()}
            {renderRangeDescription()}
            {renderMapLocation()}
            {renderRelativeLocation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Geographical Details" />
          <RowInner>
            {renderAltitudeMeasure()}
            {renderDepthMeasure()}
            {renderGeographicalElevation()}
            {renderCoordinates()}
            {renderUtmCoordinate()}
            {renderTwoDGeographicalLocation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Button
        className={classes.btn}
        color="primary"
        variant="contained"
        autoFocus
        onClick={handleSave}
        disabled={!isValid()}>
        <SaveIcon /> Save
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
  showAddressDialog,
  notifyDataUpdate,
})(AddressEdit);
