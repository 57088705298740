import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { displayDateTime, displayDate } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport, showAddPerson, showEditPerson } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchPeople } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SearchHotKeys from 'Search/SearchHotKeys';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import XGrid3 from 'components/XGrid3';
import { getPersonWarrantCount } from 'reducers/WarrantReducer';
import { notify } from 'reducers/NotifierReducer';
import Badge from '@material-ui/core/Badge';
import { showWarrants } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 8,
    },
  },
  warrantBtn: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.error.dark,
    },
    '& > span > span > span': {
      background: theme.card.bg,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    '&.MuiDataGrid-row:hover': {
      background: theme.palette.error.light,
    },
    '&.MuiDataGrid-row.Mui-selected': {
      background: theme.palette.error.dark,
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      background: theme.palette.error.dark,
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PersonID', headerName: 'PersonID', width: 150, hide: false },
  { field: 'FullName', headerName: 'Full Name', width: 150, hide: false },
  { field: 'FirstName', headerName: 'First Name', width: 150, hide: false },
  { field: 'MiddleName', headerName: 'Middle Name', width: 150, hide: false },
  { field: 'LastName', headerName: 'Last Name', width: 150, hide: false },
  { field: 'Suffix', headerName: 'Suffix', width: 150, hide: false },
  { field: 'NickName', headerName: 'Nick Name', width: 150, hide: false },
  { field: 'Age', headerName: 'Age', width: 150, hide: false },
  { field: 'Sex', headerName: 'Sex', width: 150, hide: false },
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  {
    field: 'BirthDate',
    headerName: 'Birth Date',
    width: 150,
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'Deceased', headerName: 'Deceased', width: 150, hide: false, renderCell: bool },
  {
    field: 'Died',
    headerName: 'Died',
    width: 120,
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
    hide: false,
  },
  {
    field: 'ActiveWarrant',
    headerName: 'ActiveWarrant',
    width: 150,
    hide: false,
    renderCell: bool,
  },
  { field: 'Incarcerated', headerName: 'Incarcerated', width: 150, hide: false, renderCell: bool },
  { field: 'Officer', headerName: 'Officer', width: 150, hide: false, renderCell: bool },
  {
    field: 'PublicOfficial',
    headerName: 'Public Official',
    width: 150,
    hide: false,
    renderCell: bool,
  },
  {
    field: 'ProtectiveOrder',
    headerName: 'Protective Order',
    width: 150,
    hide: false,
    renderCell: bool,
  },
  { field: 'SexOffender', headerName: 'Sex Offender', width: 150, hide: false, renderCell: bool },
  { field: 'Military', headerName: 'Military', width: 150, hide: false, renderCell: bool },
  {
    field: 'ConcealedWeaponPermit',
    headerName: 'Concealed Weapon',
    width: 150,
    hide: false,
    renderCell: bool,
  },
  {
    field: 'SeniorCitizen',
    headerName: 'Senior Citizen',
    width: 150,
    hide: false,
    renderCell: bool,
  },
  { field: 'Dangerous', headerName: 'Dangerous', width: 150, hide: false, renderCell: bool },
  { field: 'Gang', headerName: 'Gang', width: 150, hide: false, renderCell: bool },
  { field: 'Ethnicity', headerName: 'Ethnicity', width: 150, hide: false },
  { field: 'EyeColor', headerName: 'Eye Color', width: 150, hide: false },
  { field: 'HairColor', headerName: 'Hair Color', width: 150, hide: false },
  { field: 'Race', headerName: 'Race', width: 150, hide: false },
  { field: 'Height', headerName: 'Height', width: 150, hide: false },
  { field: 'Weight', headerName: 'Weight', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Person(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [personColumns, setPersonColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const [hasWarrant, setHasWarrant] = useState(false);
  const [warrantsNo, setWarrantsNo] = useState([]);
  const kbdTimeoutRef = useRef(0);
  const dataUpdate = useSelector((state) => state.dataUpdate);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type == 'Person') {
      search();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
    // eslint-disable-next-line
  }, [kbdKey]);

  useEffect(() => {
    if (selection === null) return;
    const warrant = () => {
      const item = rows.find((row) => row.id === selection);
      return item.ActiveWarrant;
    };
    if (warrant !== hasWarrant) {
      setHasWarrant(warrant);
      getWarrants();
    }
    // eslint-disable-next-line
  }, [selection]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const search = async () => {
    setLoaded(false);
    searchPeople()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 45, data: { ptsPersonID: item.ptsPersonID }, title: 'Person Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editPerson = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showEditPerson(ptsPersonID);
  };

  const getWarrants = async () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    try {
      const warrants = await getPersonWarrantCount(ptsPersonID);
      setWarrantsNo(warrants);
    } catch (err) {
      props.handleError(err);
    }
  };

  const viewWarrant = async () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID, FirstName, LastName } = item;
    const Name = `${FirstName} ${LastName}`;
    props.showWarrants({ Name, ptsPersonID });
  };

  const handleAdd = () => props.showAddPerson();
  const getRowClassName = (data) => (data.row.ActiveWarrant ? classes.warrant : null);

  return (
    <div>
      <h5>People History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={personColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="People Records"
            data={filteredRows}
            cols={personColumns}
            kbdKey={kbdKey}
          />
          <Tooltip title="Add">
            <Fab size="small" color="secondary" onClick={handleAdd}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {warrantsNo > 0 && (
            <Tooltip title="View warrant info">
              <Fab size="small" onClick={viewWarrant} className={classes.warrantBtn}>
                <Badge badgeContent={warrantsNo}>ACT</Badge>
              </Fab>
            </Tooltip>
          )}
          {selection !== null && (
            <>
              <Tooltip title="View details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Edit person">
                <Fab size="small" color="secondary" onClick={editPerson}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="people"
        columns={personColumns}
        setColumns={setPersonColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        getRowClassName={getRowClassName}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showAddAttachment,
  showAddPerson,
  showEditPerson,
  showDisplayDataWindow,
  notify,
  showWarrants,
})(Person);
