import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import { showReport } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchZones } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ZoneCode', headerName: 'Zone Code', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'Zone1', headerName: 'Zone 1', width: 150, hide: false },
  { field: 'Zone2', headerName: 'Zone 2', width: 150, hide: false },
  { field: 'Zone3', headerName: 'Zone 3', width: 150, hide: false },
  { field: 'Zone4', headerName: 'Zone 4', width: 150, hide: false },
  { field: 'Zone5', headerName: 'Zone 5', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Zone(props) {
  const classes = useStyles();
  const { filter } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [zoneColumns, setZoneColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    getAllZones();
    // eslint-disable-next-line
  }, [filter]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getAllZones();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getAllZones = async () => {
    setLoaded(false);
    searchZones()
      .then((result) => {
        const tabFiltered = tabFilter(result);
        const processed = processData(tabFiltered);
        setRows(processed);
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const tabFilter = (zones) => {
    if (!filter) return zones;
    return zones.filter((zone) => zone.AgencyID === filter);
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 39, data: { ZoneCode: item.ZoneCode }, title: 'Zone Details' });
  };

  return (
    <div>
      <h5>Zones History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={zoneColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Zones Records"
            data={filteredRows}
            cols={zoneColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      <XGrid3
        name="zones"
        columns={zoneColumns}
        setColumns={setZoneColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
})(Zone);
