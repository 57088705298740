import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

function DatePicker2(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        autoOk
        size="small"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker2;
