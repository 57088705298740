import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FoundPropertyIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M221.2 262.7h69.6v23.2h-69.6v-23.2z" />
      <path d="M379.3 285.9H314v11.6c0 6.4-5.2 11.6-11.6 11.6h-92.8c-6.4 0-11.6-5.2-11.6-11.6v-11.6h-65.3c-15 0-28.3-9.6-33-23.8L58 136.9v253.4c0 19.2 15.6 34.8 34.8 34.8h326.4c19.2 0 34.8-15.6 34.8-34.8V136.9l-41.7 125.2c-4.7 14.2-18 23.8-33 23.8z" />
      <path d="M302.4 77h-92.8c-19.2 0-34.8 15.6-34.8 34.8v11.6H77.9l43.8 131.3c1.6 4.7 6 7.9 11 7.9H198V251c0-6.4 5.2-11.6 11.6-11.6h92.8c6.4 0 11.6 5.2 11.6 11.6v11.6h65.3c5 0 9.4-3.2 11-7.9l43.8-131.3h-96.8v-11.6c-.1-19.2-15.7-34.8-34.9-34.8zM198 123.4v-11.6c0-6.4 5.2-11.6 11.6-11.6h92.8c6.4 0 11.6 5.2 11.6 11.6v11.6H198z" />
    </SvgIcon>
  );
}

export default FoundPropertyIcon;
