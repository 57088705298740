import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect } from 'react-redux';

import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';

import CheckIcon from '@material-ui/icons/Check';

import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { useDispatch } from 'react-redux';
import { getPeopleFeatures } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);
// const showIcon = (params) => {
//   return <AttachmentIcon type={params.row.FileType} style={{ width: 24, height: 'auto' }} />;
// };
const defaultColumns = [
  { field: 'ptsFeatureID', headerName: 'Feature ID', width: 150, hide: false },
  { field: 'AttributeCategory', headerName: 'AttributeCategory', width: 150, hide: false },
  { field: 'Attribute', headerName: 'Attribute', width: 150, hide: false },

  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const Features = (props) => {
  const classes = useStyles();
  const { personId } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);
  useEffect(() => {
    personId && getData();
  }, []);

  const getData = async () => {
    try {
      const data = await getPeopleFeatures(personId);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };
  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
      </div>
      <XGrid3
        name="PeopleFeatures"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
  };
};
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(Features);
