import React from 'react';
import { connect } from 'react-redux';
import { do911Test } from '../reducers/E911Reducer';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { notifyPanel } from '../reducers/NotifierReducer';
import { getWorkstationID } from '../reducers/ConfigReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    padding: theme.spacing(1, 2),
  },
  btnWrap: {
    marginBottom: theme.spacing(1),
    '& button': {
      marginRight: theme.spacing(1),
    },
  },
}));

const notificationOpts = {
  // uid: 'once-please', // you can specify your own uid if required
  title: 'Notification test',
  message: 'New Event',
  position: 'br',
  autoDismiss: 0,
  action: {
    label: 'Create Event',
    callback: () => alert('clicked!'),
  },
};

function Page911(props) {
  const classes = useStyles();
  const { notifyPanel } = props;

  const doTest = (no) => async () => {
    await do911Test(getWorkstationID(), no);
  };

  return (
    <div className={classes.pageWrap}>
      <h1>911 Testing</h1>
      <div className={classes.btnWrap}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => notifyPanel(notificationOpts, 'info')}>
          Notify test
        </Button>
      </div>
      <div className={classes.btnWrap}>
        <Button variant="contained" color="primary" onClick={doTest(0)}>
          Test 1
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(1)}>
          Test 2
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(2)}>
          Test 3
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(3)}>
          Test 4
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(4)}>
          Test 5
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  config: state.config,
});

export default connect(mapStateToProps, { notifyPanel })(Page911);
