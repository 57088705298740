import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchNames } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SearchHotKeys from 'Search/SearchHotKeys';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showEditName, showEditPerson } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PersonID', headerName: 'PersonID', width: 150, hide: false },
  { field: 'FullName', headerName: 'Full Name', width: 150, hide: false },
  { field: 'FirstName', headerName: 'First Name', width: 150, hide: false },
  { field: 'LastName', headerName: 'Last Name', width: 150, hide: false },
  { field: 'NickName', headerName: 'Nick Name', width: 150, hide: false },
  {
    field: 'IsPersonDeleted',
    headerName: 'Is Person Deleted',
    width: 150,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Name(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [nameColumns, setNameColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'People');

  useEffect(() => {
    getNameData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
    // eslint-disable-next-line
  }, [kbdKey]);

  useEffect(() => {
    const type = dataUpdate?.type;
    if (type === 'Name' || type === 'Person') getNameData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getNameData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getNameData = async () => {
    setLoaded(false);
    searchNames()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 45, data: { ptsPersonID: item.ptsPersonID }, title: 'Person Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editName = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID, ptsNameID } = item;
    if (ptsNameID) {
      props.showEditName(ptsNameID);
    } else {
      props.showEditPerson(ptsPersonID);
    }
  };

  const editPerson = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showEditPerson(ptsPersonID);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Names</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={nameColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Name Details"
            data={filteredRows}
            cols={nameColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <>
              {permissions.Edit && (
                <Tooltip title="Edit name">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editName}>
                    <InsertDriveFileIcon />
                  </Fab>
                </Tooltip>
              )}
              {permissions.Edit && (
                <Tooltip title="Edit person">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editPerson}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="names"
        columns={nameColumns}
        setColumns={setNameColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditName,
  showEditPerson,
})(Name);
