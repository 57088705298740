import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchItems } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ItemID', headerName: 'Item ID', width: 400 },
  { field: 'Name', headerName: 'Name' },
  { field: 'Description', headerName: 'Description' },
  { field: 'Condition', headerName: 'Condition' },
  { field: 'Make', headerName: 'Make' },
  { field: 'Model', headerName: 'Model' },
  { field: 'ParentType', headerName: 'Parent Type' },
  { field: 'SerialNumber', headerName: 'Serial Number' },
  { field: 'ParentID', headerName: 'Parent ID' },
  {
    field: 'Created',
    headerName: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By' },
  {
    field: 'Updated',
    headerName: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By' },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By' },
  { field: 'DeletedIP', headerName: 'Deleted IP' },
];

function Item(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [itemColumns, setItemColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    getItemData();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getItemData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getItemData = async () => {
    setLoaded(false);
    searchItems()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 34, data: { ptsItemID: item.ptsItemID }, title: 'Item Details' });
  };

  return (
    <div>
      <h5>Item History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={itemColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Item Records"
            data={filteredRows}
            cols={itemColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      <XGrid3
        name="items"
        columns={itemColumns}
        setColumns={setItemColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
})(Item);
