import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { filterResults } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minWidth: 548,
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  filter: {
    minWidth: 400,
    width: '100%',
  },
  agency: {
    minWidth: 200,
    width: 250,
  },
}));

function QuickFilterSearch(props) {
  const classes = useStyles();
  const { rows, columns, setFilteredRows, style } = props;
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!filter) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(filterResults(filter, rows, columns));
    }
    // eslint-disable-next-line
  }, [filter, rows]);

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  return (
    <div className={classes.wrap}>
      <TextField
        className={classes.filter}
        label="Quick filter"
        type="search"
        variant="outlined"
        size="small"
        onChange={quickFilter}
        value={filter}
        style={style}
      />
    </div>
  );
}

export default QuickFilterSearch;
