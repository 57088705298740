import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeWarrants } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { handleError } from 'reducers/ErrorReducer';
import WarrantCard from 'components/WarrantCard';
import { getPersonWarrant } from 'reducers/WarrantReducer';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: 500,
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function WarrantDialog(props) {
  const classes = useStyles();
  const { ptsPersonID, Name } = props.data;
  const [warrants, setWarrants] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWarrants();
    // eslint-disable-next-line
  }, []);

  const getWarrants = async () => {
    try {
      const warrants = await getPersonWarrant(ptsPersonID);
      setWarrants(warrants);
      setLoading(false);
    } catch (err) {
      props.handleError(err);
    }
  };

  const close = () => {
    props.closeWarrants();
  };

  const renderLoading = () => {
    return (
      <div className={classes.loading}>
        <div style={{ marginBottom: 8, fontSize: 22 }}>Loading...</div>
        <CircularProgress />
      </div>
    );
  };

  return (
    <Dialog toolbar onClose={close} title={`Warrants: ${Name}`}>
      {!loading && <WarrantCard warrants={warrants} />}
      {loading && renderLoading()}
    </Dialog>
  );
}

export default connect(null, {
  closeWarrants,
  handleError,
})(WarrantDialog);
