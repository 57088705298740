import React from 'react';
import NotificationsSearchResults from './NotificationsSearchResults';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

function NotificationsSearch(props) {
  const access = getAccessPermission('globals', 'View Paging History');
  if (!access) return <Redirect to="/" />;
  return (
    <SearchMenu>
      <FilterConsumer>
        {(AgencyFilter) => <NotificationsSearchResults filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default NotificationsSearch;
