import store from '../config/configureStore';
import { sortObjArr, groupAndSort } from 'utils/functions';

const SET_UNIT_PINS = 'SET_UNIT_PINS';
const SET_UNIT_GROUP_BY = 'SET_EVENT_GROUP_BY';
const SET_UNIT_SORT_BY = 'SET_UNIT_SORT_BY';
const SET_UNIT_ASC_DESC = 'SET_UNIT_ASC_DESC';
const SET_UNIT_FILTER = 'SET_UNIT_FILTER';
const SET_UNIT_SHOW_PINNED = 'SET_UNIT_SHOW_PINNED';
const SET_UNIT_AGENCY_FILTER = 'SET_UNIT_AGENCY_FILTER';
const SET_UNIT_GROUP_FOLDED = 'SET_UNIT_GROUP_FOLDED';

const defaultSort = {
  pins: [], // { idx: 0, ptsUnitID: 7943 }
  agencyFilter: [], // hide unit units with given AgencyID e.g. ['POLICEDEP']
  folded: [], // shows which units are unfolded and open tab [{ ptsUnitID: 47889, tab: 'units' }]
  groupBy: 'agency',
  sortBy: 'unit',
  ascDesc: 'DESC',
  filter: '',
  showPinned: false,
};

const filterVer = '1';

export const toggleExpandUnitGroup = (expand, groupKey) => (dispatch) => {
  const folded = store.store.getState().unitSort.folded;
  let newFolded;
  if (expand) {
    newFolded = folded.filter((key) => key !== groupKey);
  } else {
    newFolded = [...folded];
    newFolded.push(groupKey);
  }
  dispatch({ type: SET_UNIT_GROUP_FOLDED, payload: newFolded });
};

export const toggleUnitAgencyFilter = (AgencyID) => {
  const agencyFilter = store.store.getState().unitSort.agencyFilter;
  let newAgencyFilter;
  const present = agencyFilter.indexOf(AgencyID) !== -1;
  if (present) {
    newAgencyFilter = agencyFilter.filter((id) => id !== AgencyID);
  } else {
    newAgencyFilter = [...agencyFilter];
    newAgencyFilter.push(AgencyID);
  }
  return (dispatch) => {
    dispatch({ type: SET_UNIT_AGENCY_FILTER, payload: newAgencyFilter });
  };
};

// export const setUnitPins = (unitPins) => {
//   return (dispatch) => {
//     dispatch({ type: SET_UNIT_PINS, payload: unitPins });
//   };
// };

export const setUnitGroupBy = (groupBy) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_GROUP_BY, payload: groupBy });
  };
};

export const setUnitSortBy = (sortBy) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_SORT_BY, payload: sortBy });
  };
};

export const setUnitAscDesc = (ascDesc) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_ASC_DESC, payload: ascDesc });
  };
};

export const setUnitFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_FILTER, payload: filter });
  };
};

export const setUnitShowPinned = (showPinned) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_SHOW_PINNED, payload: showPinned });
  };
};

export const saveUnitSort = (unitSort) => {
  if (!unitSort) unitSort = store.store.getState().unitSort;
  const savedObj = { ...unitSort, filter: '', showPinned: false };
  localStorage.setItem('u-sort-' + filterVer, JSON.stringify(savedObj));
};

export const loadUnitSort = () => {
  const seqText = window.localStorage.getItem('u-sort-' + filterVer);
  if (!seqText) return defaultSort;
  const unitSort = JSON.parse(seqText);
  if (!typeof unitSort === 'object') return defaultSort;
  return unitSort;
};

// export const getSortedUnits = () => {
//   const state = store.store.getState();
//   const { unitSort, units } = state;
//   const { sortBy, groupBy, ascDesc, pins } = unitSort;
//   let sortedUnits;
//   if (groupBy === 'none') {
//     sortedUnits = sortObjArr(units, sortBy, ascDesc);
//   } else {
//     sortedUnits = groupAndSort(units, sortBy, groupBy, ascDesc);
//   }
//   if (pins.length > 0) {
//     const pinIds = pins.map(p => p.ptsUnitID);
//     sortedUnits = sortedUnits.filter(ev => {
//       return pinIds.indexOf(ev.ptsUnitID) === -1;
//     });
//     pins.forEach(pin => {
//       const { idx, ptsUnitID } = pin;
//       const unit = units.find(ev => ev.ptsUnitID === ptsUnitID);
//       if (unit) sortedUnits.splice(idx, 0, unit);
//     });
//   }
//   return sortedUnits;
// }

// export const togglePin = (ptsUnitID) => {
//   const state = store.store.getState();
//   const unitPins = state.unitSort.pins;
//   const units = getSortedUnits();
//   const isPinned = !!unitPins.find(pin => pin.ptsUnitID === ptsUnitID);
//   return (dispatch) => {
//     if (isPinned) {
//       const removedPin = unitPins.find(s => s.ptsUnitID === ptsUnitID);
//       if (!removedPin) return;
//       const removedIdx = removedPin.idx;
//       const newSeq = unitPins
//         .filter(s => s.ptsUnitID !== ptsUnitID)
//         .map(s => {
//           if (s.idx <= removedIdx) return s;
//           return { idx: s.idx - 1, ptsUnitID: s.ptsUnitID };
//         });
//       dispatch(setUnitPins(newSeq));
//     } else {
//       const pinned = unitPins.map(pin => pin.ptsUnitID);
//       pinned.push(ptsUnitID);
//       const unit = units.find((ev) => ev.ptsUnitID === ptsUnitID);
//       const newUnits = units.filter((ev) => ev.ptsUnitID !== ptsUnitID);
//       newUnits.unshift(unit);
//       let i = 0;
//       const newSeq = [];
//       newUnits.forEach(({ ptsUnitID }) => {
//         if (pinned.indexOf(ptsUnitID) !== -1) newSeq.push({ idx: i++, ptsUnitID });
//       });
//       dispatch(setUnitPins(newSeq));
//     }
//   }
// };

// export const saveSeq = (reorderedUnits) => {
//   const state = store.store.getState();
//   const unitPins = state.unitSort.pins;
//   const units = state.units;
//   return (dispatch) => {
//     const newPins = [];
//     unitPins.forEach((s) => {
//       const idx = reorderedUnits.findIndex((ev) => ev.ptsUnitID === s.ptsUnitID);
//       const removed = !units.find(ev => ev.ptsUnitID === s.ptsUnitID);
//       if (!removed) newPins.push({ idx, ptsUnitID: s.ptsUnitID });
//     });
//     const sortedPins = sortObjArr(newPins, 'idx', 'ASC');
//     dispatch(setUnitPins(sortedPins));
//   }
// }

export default function reducer(state = loadUnitSort(), action) {
  switch (action.type) {
    case SET_UNIT_PINS:
      return { ...state, pins: action.payload };
    case SET_UNIT_GROUP_BY:
      return { ...state, groupBy: action.payload };
    case SET_UNIT_SORT_BY:
      return { ...state, sortBy: action.payload };
    case SET_UNIT_ASC_DESC:
      return { ...state, ascDesc: action.payload };
    case SET_UNIT_FILTER:
      return { ...state, filter: action.payload };
    case SET_UNIT_SHOW_PINNED:
      return { ...state, showPinned: action.payload };
    case SET_UNIT_AGENCY_FILTER:
      return { ...state, agencyFilter: action.payload };
    case SET_UNIT_GROUP_FOLDED:
      return { ...state, folded: action.payload };
    default:
      return state;
  }
}
