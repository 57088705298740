import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8alarm100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M25 6c-1.645 0-3 1.355-3 3v1.363c-4.828 1.188-8 5.25-8 10.7v7.8c0 2.172-1.281 4.633-2.02 5.864l-1.812 2.718A1 1 0 0 0 11 39h28c.367 0 .707-.203.883-.527a1.001 1.001 0 0 0-.051-1.028l-1.785-2.68C36.668 32.474 36 30.586 36 29v-7.8c0-5.516-3.172-9.63-8-10.829V9c0-1.645-1.355-3-3-3Zm0 2c.555 0 1 .445 1 1v1.047c-.328-.027-.66-.047-1-.047-.34 0-.672.02-1 .047V9c0-.555.445-1 1-1ZM3.48 9.477C1.25 13.102 0 17.418 0 22s1.25 8.898 3.48 12.523l1.708-1.046C3.151 30.168 2 26.219 2 22s1.152-8.168 3.188-11.477Zm43.04 0-1.708 1.046C46.849 13.832 48 17.781 48 22s-1.152 8.168-3.188 11.477l1.708 1.046C48.75 30.898 50 26.582 50 22s-1.25-8.898-3.48-12.523ZM7.816 12.14C5.996 15.082 5 18.355 5 22c0 3.672 1.129 7.047 2.809 9.848l1.714-1.032C8.008 28.286 7 25.262 7 22c0-3.29.871-6.148 2.516-8.809Zm34.368 0-1.7 1.05C42.13 15.851 43 18.711 43 22c0 3.262-1.008 6.285-2.527 8.816l1.718 1.032C43.871 29.047 45 25.672 45 22c0-3.645-.996-6.918-2.816-9.86ZM20.422 41c.777 1.762 2.531 3 4.578 3s3.8-1.238 4.578-3Z" />
    </SvgIcon>
  );
}

export default icons8alarm100Icon;
