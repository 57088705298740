import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormDivider from 'components/FormDivider';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  validatePhoneNo,
  validateEmail,
  decodePhoneNo,
} from 'utils/formStyles';
import { useError } from 'utils/formStyles';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import Dictionary from 'components/Dictionary';
import Button from '@material-ui/core/Button';
import AddressLookup from 'components/AddressLookup';
import SaveIcon from '@material-ui/icons/Save';

import { addPlace } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { updatePtsPlace } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(300, 500),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function AddPlace(props) {
  const classes = useStyles();
  const { ptsPlaceID, rowData } = props;
  const [place, setPlace] = useState(null);
  const [ptsInfoID, setPtsInfoID] = useState(null);
  const [errors, setErrors] = useState();
  const [PlaceName, setPlaceName] = useState('');
  const [PlaceORI, setPlaceORI] = useState('');
  const [PlaceDepartmentID, setPlaceDepartmentID] = useState('');
  const [PlaceType, setPlaceType] = useState(null);
  const [IsDestination, setIsDestination] = useState(false);
  const [IsPrimary, setIsPrimary] = useState(false);
  const [Address, setAddress] = useState(null);
  const [ContactMethod, setContactMethod] = useState(null);
  const [ContactMethodType, setContactMethodType] = useState(null);
  const [Info, setInfo] = useState('');
  const [IsDefault, setIsDefault] = useState(true);
  const [Notes, setNotes] = useState('');
  const { err, delErr, isValidAndChanged, formChanged } = useError(errors, setErrors);
  const editPlace = ptsPlaceID !== true;
  useEffect(() => {
    rowData.ptsPlaceID && parseData(rowData);
  }, [rowData.ptsPlaceID]);

  const parseData = (data) => {
    const {
      PlaceName,
      PlaceORI,
      PlaceType,
      PlaceDepartmentID,
      IsDestination,
      PlaceAddress: Addresses,
      ContactInfo,
    } = data;
    setPlaceType(PlaceType);
    setPlaceName(PlaceName);
    setPlaceORI(PlaceORI);
    setPlaceDepartmentID(PlaceDepartmentID);
    setIsDestination(Boolean(IsDestination));
    // console.log('addresses: ', Addresses);
    if (Addresses.length) {
      const add = { ...Addresses[0] };

      setAddress(add);
      setPlace(add);
      setIsPrimary(Boolean(add.IsPrimary));
    }

    if (ContactInfo.length) {
      const { ptsInfoID, ContactMethod, MethodType, Info, Notes, IsDefault } = ContactInfo[0];
      setPtsInfoID(ptsInfoID);
      setContactMethod(ContactMethod);
      setContactMethodType(MethodType);
      setInfo(Info);
      setNotes(Notes);
      setIsDefault(Boolean(IsDefault));
    }
  };

  const close = () => {
    props.close();
  };

  const save = () => {
    if (ptsPlaceID && ptsPlaceID !== true) {
      updatePlace();
    } else {
      saveNewPlace();
    }
  };

  const formatSaveData = () => {
    let data = {
      PlaceName,
      PlaceORI: PlaceORI ? PlaceORI : null,
      PlaceDepartmentID: PlaceDepartmentID ? PlaceDepartmentID : null,
      PlaceType: PlaceType ? PlaceType.Code : null,
      IsDestination,
      IsPrimary,
      ptsAddressID: Address ? Address.ptsAddressID : null,
      ContactMethod: ContactMethod ? ContactMethod.Code : null,
      ContactMethodType: ContactMethodType ? ContactMethodType.Code : null,
      Info: Info ? Info : null,
      Notes: Notes ? Notes : null,
      IsDefault,
      PhoneNumber: null,
      AreaCode: null,
      Extension: null,
    };
    if (ContactMethod && ContactMethod.Code === 'Phone' && Info.length > 10) {
      data = { ...data, ...decodePhoneNo(Info) };
    }
    return data;
  };

  const saveNewPlace = () => {
    const data = formatSaveData();
    addPlace(data)
      .then((result) => {
        if (result) {
          props.notify('Place Created', 'success');
          props.notifyDataUpdate({ type: 'Place', data: { action: 'Create', ptsPlaceID: result } });
          close();
        } else {
          props.notyfy('There was a problem with creating the place', 'error');
        }
      })
      .catch(props.handleError);
  };

  const updatePlace = () => {
    const data = formatSaveData();
    const {
      AreaCode,
      ContactMethod,
      ContactMethodType,
      Extension,
      Info,
      IsDefault,
      IsDestination,
      IsPrimary,
      PhoneNumber,
      PlaceDepartmentID,
      PlaceName,
      PlaceORI,
      PlaceType,
      ptsAddressID,
    } = data;

    const saveData = {
      ptsPlaceID,
      Place: {
        PlaceName,
        IsDestination,
        PlaceORI,
        PlaceType,
        PlaceDepartmentID,
      },
      Addresses: [],
      Contacts: [],
    };
    if (ptsAddressID) {
      saveData.Addresses[0] = {
        ptsAddressID,
        IsPrimary,
      };
    }
    if (ContactMethod) {
      saveData.Contacts[0] = {
        ptsInfoID,
        ContactMethod,
        ContactMethodType,
        Info,
        IsDefault,
        AreaCode,
        PhoneNumber,
        Extension,
      };
    }
    updatePtsPlace(ptsPlaceID, saveData)
      .then(() => {
        props.notify('Place Updated', 'success');
        props.notifyDataUpdate({ type: 'Place', data: { action: 'Update' } });
      })
      .catch(props.handleError);
  };

  const renderPlaceName = () => {
    const onChange = (ev, val) => {
      setPlaceName(val);
      formChanged();
    };
    const error = err(PlaceName, 'PlaceName');
    return (
      <TextField2
        className={classes.item}
        label="Place Name"
        value={PlaceName}
        onChange={onChange}
        max={100}
        error={error}
      />
    );
  };

  const renderPlaceORI = () => {
    const onChange = (ev, val) => {
      setPlaceORI(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Place ORI"
        value={PlaceORI}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderPlaceDepartmentID = () => {
    const onChange = (ev, val) => {
      setPlaceDepartmentID(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Place Department ID"
        value={PlaceDepartmentID}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderPlaceTypes = () => {
    const onChange = (ev, val) => {
      setPlaceType(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="PlaceTypes"
        onChange={onChange}
        value={PlaceType}
        label="Place Type"
      />
    );
  };

  const renderIsDestination = () => {
    const onChange = () => {
      setIsDestination(!IsDestination);
      formChanged();
    };
    return <Checkbox2 checked={IsDestination} onChange={onChange} label="Destination" />;
  };

  const renderActions = () => {
    return (
      <div className={classes.dialogActions}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged()}>
          <SaveIcon /> Save
        </Button>
      </div>
    );
  };

  const renderAddressLookup = () => {
    const clearPlace = () => {
      setAddress(null);
      setIsPrimary(false);
      formChanged();
    };
    const onChange = (address) => {
      formChanged();
      setAddress(address);
    };
    return (
      <AddressLookup
        className={classes.item}
        ptsPlaces={false}
        ptsAddresses={true}
        googleAddresses={false}
        onAddressValueSet={onChange}
        onPlaceValueSet={onChange}
        onReset={clearPlace}
        label="Address Lookup"
        setPlace={place}
      />
    );
  };

  const renderIsPrimary = () => {
    const onChange = () => {
      setIsPrimary(!IsPrimary);
      formChanged();
    };
    return (
      <div className={classes.item}>
        <Checkbox2 checked={IsPrimary} onChange={onChange} label="Primary" disabled={!Address} />
      </div>
    );
  };

  const renderContactMethods = () => {
    const onChange = (ev, val) => {
      setContactMethod(val);
      setInfo('');
      formChanged();
      delErr('Info');
      if (!val) setContactMethodType(null);
    };
    return (
      <Dictionary
        className={classes.item}
        options="ContactMethods"
        onChange={onChange}
        value={ContactMethod}
        label="Contact Method"
      />
    );
  };

  const renderContactMethodTypes = () => {
    const onChange = (ev, val) => {
      setContactMethodType(val);
      formChanged();
    };
    const errors = err(!ContactMethod || (ContactMethod && ContactMethodType), 'ContactMethodType');
    return (
      <Dictionary
        className={classes.item}
        options="ContactMethodTypes"
        onChange={onChange}
        value={ContactMethodType}
        label="Contact Type"
        error={errors}
        disabled={!ContactMethod}
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setInfo(val);
      formChanged();
    };
    const type = ContactMethod?.Code;
    let error;
    if (type === 'Phone') {
      error = err(validatePhoneNo(Info), 'Info', 'Invalid phone number');
    }
    if (type === 'Email') {
      error = err(validateEmail(Info), 'Info', 'Invalid email address');
    }

    return (
      <TextField2
        className={classes.item}
        label="Info"
        value={Info}
        onChange={onChange}
        max={100}
        error={error}
        type={type}
        disabled={!ContactMethod}
      />
    );
  };

  const renderIsDefault = () => {
    const onChange = () => {
      setIsDefault(!IsDefault);
      formChanged();
    };
    return (
      <div className={classes.item}>
        <Checkbox2
          checked={IsDefault}
          onChange={onChange}
          label="Default"
          disabled={!ContactMethod}
        />
      </div>
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => {
      setNotes(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={20}
        multiline
        rows={3}
      />
    );
  };

  return (
    <div className={classes.wrap}>
      <Row>
        <ColCard>
          <FormDivider title="Place Details" />
          <RowInner>
            {renderPlaceName()}
            {renderPlaceORI()}
            {renderPlaceDepartmentID()}
            {renderPlaceTypes()}
            <Fills className={classes.item} />
          </RowInner>
          <RowInner>{renderIsDestination()}</RowInner>
          {!editPlace && (
            <>
              <FormDivider title="Contact" />
              <RowInner>
                {renderAddressLookup()}
                {renderIsPrimary()}
                {renderContactMethods()}
                {renderContactMethodTypes()}
                {renderInfo()}
                {renderIsDefault()}
              </RowInner>
              <RowInner>{renderNotes()}</RowInner>
            </>
          )}
        </ColCard>
      </Row>
      {renderActions()}
    </div>
  );
}

export default connect(null, {
  handleError,
  notify,
  notifyDataUpdate,
})(AddPlace);
