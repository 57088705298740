import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ModifiersFilter = (props) => {
  const handleFilterChange = (event, values) => {
    if (values) props.setSelectedModifier(values);
  };

  return (
    <Autocomplete
      labelid="modifiers"
      multiple
      filterSelectedOptions={true}
      value={props.selectedModifiers}
      onChange={handleFilterChange}
      options={props.modifiers}
      getOptionLabel={(option) => option.Code}
      getOptionSelected={(option, value) => {
        return option.Code === value.Code;
      }}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Modifiers" variant="outlined" size="small" />
      )}
    />
  );
};

export default ModifiersFilter;
