import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { addEvent, addPageDialog, addBulletin } from 'reducers/DialogsReducer';

function GenericKeyboardHandler(props) {
  const { keyboard } = props;
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const keyboardSubscription = keyboard.subject.subscribe(handleKeyUp);
    return () => {
      keyboardSubscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!redirectTo) return;
    setRedirectTo(null);
  }, [redirectTo]);

  const handleKeyUp = (keyCode) => {
    // switch (keyCode) {
    //   case 'Ctrl+Alt+KeyA':
    //     props.addBulletin();
    //     break;
    //   case 'Ctrl+Shift+Digit3':
    //     props.addPageDialog();
    //     break;
    //   case 'Ctrl+Space':
    //     props.addEvent();
    //     break;
    //   case 'Ctrl+KeyD':
    //     setRedirectTo('/home');
    //     break;
    //   case 'Ctrl+KeyM':
    //     setRedirectTo('/map');
    //     break;
    //   case 'Ctrl+KeyE':
    //     setRedirectTo('/search/events');
    //     break;
    //   case 'Ctrl+KeyU':
    //     setRedirectTo('/search/units');
    //     break;
    // }
  };

  return redirectTo ? <Redirect to={redirectTo} /> : '';
}

const mapStateToProps = (state) => ({
  keyboard: state.keyboard,
});

export default connect(mapStateToProps, {
  addEvent,
  addPageDialog,
  addBulletin,
})(GenericKeyboardHandler);
