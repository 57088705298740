import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8dying100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M5.5 15a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9zm11.457 2.004c-2.568 0-4.172 1.328-4.72 2.842a2.008 2.008 0 0 0-.15.38L9.48 26.005H2.107c-.996 0-1.92.681-2.08 1.664A2.001 2.001 0 0 0 2 30.004h9c.899 0 1.633-.618 1.928-1.467C13.155 27.887 14 26 14 26h17l4.172 3.297c.535.44 1.207.681 1.9.683L47.5 30a2.5 2.5 0 0 0 2.486-2.758c-.13-1.3-1.322-2.242-2.629-2.242h-9.484s-5.946-6.072-6.22-6.258c-.61-.47-1.411-.74-2.471-.74l-4.141-.004c-3.558 0-6.238-.994-8.084-.994zm18.828 2.998L38.77 23h8.652c.85 0 1.655.238 2.351.639.18-.344.291-.736.291-1.15a2.487 2.487 0 0 0-2.486-2.487H35.785zm-20.103 8.057c2.15 2.2 6.316 2.056 6.316 3.35 0 1.09-2.998.811-2.998 2.384C19 35.523 24.987 36 30.236 36 35.486 36 41 35.358 41 33.504c0-.64-.801-1.106-1.945-1.46l-1.989-.005a4.993 4.993 0 0 1-2.761-.861l-.41-.05.34.003c-.112-.077-.23-.145-.335-.23l-3.457-2.842H15.682z" />
    </SvgIcon>
  );
}

export default icons8dying100Icon;
