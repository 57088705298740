import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
// import Units from 'pages/Units';
import { getLocalSettings, saveLocalSettings } from 'reducers/ConfigReducer';

const useStyles = makeStyles((theme) => ({
  openContainer: {
    width: 580,
    maxWidth: '50%',
    height: '100%',
    background: theme.content.bg,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.4)',
  },
  closedContainer: {
    width: 0,
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  openBtn: {
    position: 'absolute',
    top: 4,
    left: -24,
    background: '#fff',
    width: 24,
    height: 24,
    cursor: 'pointer',
    boxShadow: '-2px 2px 3px rgba(0, 0, 0, 0.3)',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  draggable: {
    position: 'absolute',
    width: 8,
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    cursor: 'col-resize',
  },
  dragCanvas: {
    position: 'absolute',
    inset: 0,
    cursor: 'col-resize',
  },
}));

function EventPanel() {
  const classes = useStyles();
  const [open, setOpen] = useState();
  const [dragged, setDragged] = useState(false);
  const [width, setWidth] = useState(580);
  const scrWidth = useRef();

  useEffect(() => {
    const settings = getLocalSettings();
    setOpen(settings.mapUnitOpen);
    setWidth(settings.mapUnitWidth);
    // eslint-disable-next-line
  }, []);

  const startDrag = (ev) => {
    scrWidth.current = ev.clientX + width;
    setDragged(true);
  };

  const dragging = (ev) => {
    setWidth(scrWidth.current - ev.clientX);
    saveLocalSettings({ mapUnitWidth: width });
  };

  const stopDrag = (ev) => {
    setDragged(false);
  };

  const handleOpen = (val) => {
    setOpen(val);
    saveLocalSettings({ mapUnitOpen: val });
  };

  return (
    <>
      <div
        className={open ? classes.openContainer : classes.closedContainer}
        style={{ width: open ? width : 0 }}>
        {!open && (
          <div className={classes.openBtn} onClick={() => handleOpen(true)}>
            <ArrowLeftIcon />
          </div>
        )}
        {open && (
          <div className={classes.openBtn} onClick={() => handleOpen(false)}>
            <ArrowRightIcon />
          </div>
        )}
        {/* {open && <Units />} */}
        <div className={classes.draggable} onMouseDown={startDrag}></div>
      </div>
      {dragged && (
        <div className={classes.dragCanvas} onMouseMove={dragging} onMouseUp={stopDrag}></div>
      )}
    </>
  );
}

export default EventPanel;
