import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchVehicleTowings } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import {
  showAddAttachment,
  showAddVehicle,
  showReport,
  showEditVehicle,
} from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 8,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'VehicleID', headerName: 'VehicleID', width: 150, hide: false },
  { field: 'PlateNumber', headerName: 'PlateNumber', width: 150, hide: false },
  { field: 'PlateState', headerName: 'PlateState', width: 150, hide: false },
  { field: 'Year', headerName: 'Year', width: 150, hide: false },
  { field: 'Make', headerName: 'Make', width: 150, hide: false },
  { field: 'Model', headerName: 'Model', width: 150, hide: false },
  { field: 'PrimaryColor', headerName: 'PrimaryColor', width: 150, hide: false },
  {
    field: 'IsTowed',
    headerName: 'IsTowed',
    width: 150,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  { field: 'PlaceID', headerName: 'PlaceID', width: 150, hide: false },
  { field: 'PlaceName', headerName: 'TowerReference', width: 150, hide: false },
  { field: 'PersonID', headerName: 'PersonID', width: 150, hide: false },
  { field: 'FullName', headerName: 'Driver', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function VehicleTowing(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [towingColumns, setTowingColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'Vehicles');

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const search = async () => {
    setLoaded(false);
    searchVehicleTowings()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 33,
      data: { ptsVehicleID: item.ptsVehicleID },
      title: 'Vehicle Towing Details',
    });
  };

  const addVehicle = () => {
    props.showAddVehicle();
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsVehicleID } = item;
    props.showAddAttachment({ id: ptsVehicleID, type: 'Vehicle' });
  };

  const editVehicle = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showEditVehicle(item.ptsVehicleID);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Vehicle Towings History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={towingColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          {permissions.Create && (
            <Fab onClick={addVehicle} size="small" color="secondary">
              <AddIcon />
            </Fab>
          )}
          <PrintsSearch2
            title="Vehicle Towings Records"
            data={filteredRows}
            cols={towingColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <>
              {permissions.Edit && (
                <Tooltip title="Edit Vehicle">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editVehicle}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="vehicleTowings"
        columns={towingColumns}
        setColumns={setTowingColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showAddAttachment,
  showAddVehicle,
  showEditVehicle,
})(VehicleTowing);
