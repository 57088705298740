import React, { useRef, useState } from 'react';
import { printRef } from '../utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'inline-block',
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
  tableWrap: {
    display: 'none',
  },
  btn: {
    marginLeft: 8,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

function TableCreator(props) {
  const { data, cols } = props;

  const formatBool = (str) => {
    if (str === true) return 'YES';
    if (str === false) return 'NO';
    return str;
  };

  return (
    <table>
      <thead>
        <tr>
          {cols.map((c) => (
            <th key={`header-${c.headerName}`}>{c.headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={`${item.id}-row`}>
            {cols.map((c) => (
              <td key={`${item.id}-${c.field}`}>
                {c.format === 'date' && displayDateTime(item[c.field])}
                {c.format === 'bool' && formatBool(item[c.field])}
                {c.format !== 'date' && c.format !== 'bool' && item[c.field]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function PrintsSearch(props) {
  const classes = useStyles();
  const { title = 'VFC', data, cols, hiddenCols = [] } = props;
  const ref = useRef();
  const [printFlag, setPrintFlag] = useState(false);

  const print = async () => {
    await setPrintFlag(true);
    printRef(ref, title);
  };

  const filterColumns = (columns) => {
    const res = columns.filter((col) => !hiddenCols.includes(col.field));
    return res;
  };

  return (
    <div className={classes.wrap}>
      <Button
        className={classes.btn}
        color="primary"
        variant="contained"
        autoFocus
        onClick={print}
        disabled={!data.length}>
        <PrintIcon />
        Print List
      </Button>
      <iframe id="print-iframe" className={classes.iframe}></iframe>
      {printFlag && (
        <div className={classes.tableWrap} ref={ref}>
          <TableCreator data={data} cols={filterColumns(cols)} />
        </div>
      )}
    </div>
  );
}

export default PrintsSearch;
