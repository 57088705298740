import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import TextField2 from 'components/TextField2';
import { ColCard, gridStyle, Row, RowInner, useError, formatSaveData } from 'utils/formStyles';
import Dictionary from 'components/Dictionary';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { closePeopleEmployment } from 'reducers/DialogsReducer';
import PlaceLookup from 'components/PlaceLookup';
import PersonLookup from 'components/PersonLookup';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addPersonEmployment } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  item: gridStyle(300, '100%'),
  txtField: {
    minWidth: 350,
  },
}));

function PeopleEmploymentDialog(props) {
  const classes = useStyles();
  const { ptsPersonID } = props;
  const [Place, setPlace] = useState(null);
  const [DepartmentName, setDepartmentName] = useState('');
  const [Position, setPosition] = useState(null);
  const [Occupation, setOccupation] = useState(null);
  const [Rank, setRank] = useState(null);
  const [Status, setStatus] = useState(null);
  const [Pay, setPay] = useState('');
  const [Rate, setRate] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [Shift, setShift] = useState('');
  const [Hired, setHired] = useState(null);
  const [Terminated, setTerminated] = useState(null);
  const [TerminationReason, setTerminationReason] = useState(null);
  const [Notes, setNotes] = useState('');

  const [errors, setErrors] = useState();
  const { err, isValidAndChanged, formChanged } = useError(errors, setErrors);
  const isEdit = typeof data === 'object';

  // useEffect(() => {
  //   if (pts) {
  //     const { ptsCredentialID, Type, ptsPersonID, Number } = data;
  //     setType(Type);
  //     setNumber(Number);
  //   }

  //   // eslint-disable-next-line
  // }, []);

  const close = () => {
    props.closePeopleEmployment();
  };

  const save = async () => {
    const data = formatOutData();
    props.showSpinner();
    try {
      addPersonEmployment(data);
      props.notifyDataUpdate({ type: 'employment' });
      close();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const formatOutData = () => {
    const data = formatSaveData({
      DepartmentName,
      Position,
      Occupation,
      Rank,
      Status,
      Pay,
      Rate,
      Shift,
      Hired,
      Terminated,
      TerminationReason,
      Notes,
    });
    data.ptsPlaceID = Place?.ptsPlaceID;
    data.ptsSupervisorID = supervisor ? supervisor.ptsPersonID : null;
    data.ptsPersonID = ptsPersonID;
    return data;
  };

  const renderEmployer = () => {
    const onChange = (val) => {
      setPlace(val);
      formChanged();
    };
    const error = err(Place, 'Place');
    return (
      <PlaceLookup onChange={onChange} className={classes.item} label="Employer" error={error} />
    );
  };

  const renderDepartmentName = () => {
    const onChange = (ev, val) => {
      setDepartmentName(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Department Name"
        value={DepartmentName}
        onChange={onChange}
        max={40}
      />
    );
  };

  const renderPosition = () => {
    const onChange = (ev, val) => {
      setPosition(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="Positions"
        onChange={onChange}
        value={Position}
        label="Position"
      />
    );
  };

  const renderOccupation = () => {
    const onChange = (ev, val) => {
      setOccupation(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="Occupations"
        onChange={onChange}
        value={Occupation}
        label="Occupation"
      />
    );
  };

  const renderRank = () => {
    const onChange = (ev, val) => {
      setRank(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="Ranks"
        onChange={onChange}
        value={Rank}
        label="Rank"
      />
    );
  };

  const renderStatus = () => {
    const onChange = (ev, val) => {
      setStatus(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="EmploymentStatuses"
        onChange={onChange}
        value={Status}
        label="Status"
      />
    );
  };

  const renderPay = () => {
    const onChange = (ev, val) => {
      setPay(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Pay"
        value={Pay}
        onChange={onChange}
        type="number"
      />
    );
  };

  const renderRate = () => {
    const onChange = (ev, val) => {
      setRate(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="PayRates"
        onChange={onChange}
        value={Rate}
        label="Rate"
      />
    );
  };

  const renderSupervisor = () => {
    const onChange = (val) => {
      setSupervisor(val);
      formChanged();
    };
    return <PersonLookup className={classes.item} onPersonChange={onChange} label="Supervisor" />;
  };

  const renderShift = () => {
    const onChange = (ev, val) => {
      setShift(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Shift"
        value={Shift}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderHired = () => {
    const onChange = (val) => {
      setHired(val);
      formChanged();
    };
    return (
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        margin="normal"
        label="Hired"
        value={Hired}
        onChange={onChange}
        maxDate={Terminated}
        autoOk
        size="small"
        className={classes.item}
        style={{ marginBottom: 16 }}
      />
    );
  };

  const renderTerminated = () => {
    const onChange = (val) => {
      setTerminated(val);
      formChanged();
    };
    return (
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        margin="normal"
        label="Terminated"
        value={Terminated}
        onChange={onChange}
        minDate={Hired}
        autoOk
        size="small"
        className={classes.item}
        style={{ marginBottom: 16 }}
      />
    );
  };

  const renderTerminationReason = () => {
    const onChange = (ev, val) => {
      setTerminationReason(val);
      formChanged();
    };
    return (
      <Dictionary
        className={classes.item}
        options="TerminationReasons"
        onChange={onChange}
        value={TerminationReason}
        label="Termination Reason"
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => {
      setNotes(val);
      formChanged();
    };
    return (
      <TextField2
        className={classes.item}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={2000}
        multiline
        rows={3}
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={isEdit ? 'Edit Employment' : 'Add Employment'}
      actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderEmployer()}
            {renderDepartmentName()}
            {renderPosition()}
            {renderOccupation()}
            {renderRank()}
            {renderStatus()}
            {renderPay()}
            {renderRate()}
            {renderSupervisor()}
            {renderShift()}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {renderHired()}
              {renderTerminated()}
            </MuiPickersUtilsProvider>
          </RowInner>
          <RowInner>{renderTerminationReason()}</RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  notifyDataUpdate,
  closePeopleEmployment,
})(PeopleEmploymentDialog);
