import React from 'react';
import { connect } from 'react-redux';
import SearchPanel from './SearchPanel';
import { makeStyles } from '@material-ui/core/styles';
import { editEvent } from '../../reducers/DialogsReducer';
import Tooltip from '../../components/Tooltip';
import EventAvatar from '../../components/EventAvatar';
import EventStatus from '../../components/EventStatus';
import { List, ListItem } from '@material-ui/core';

const infoWidth = 230;

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: `1px solid ${theme.colors.grey1}`,
    cursor: 'pointer',
  },
  locationInfo: {
    width: '100%',
    maxHeight: 44,
    lineHeight: '1.3em',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    fontSize: 12,
    marginTop: 0,
  },
  info: {
    width: infoWidth,
    flex: `0 0 ${infoWidth}px`,
    marginRight: 10,
  },
}));

function EventDuplicates(props) {
  const classes = useStyles();
  const { data } = props;

  const renderLocation = (ev) => {
    const { FullAddressText, CallType } = ev;
    return (
      <div className={classes.info}>
        <Tooltip title={FullAddressText}>
          <div className={classes.locationInfo}>
            <strong>{CallType}</strong> - {FullAddressText}
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <SearchPanel title="Event Duplicates">
      <List className={classes.list}>
        {data.map((ev) => (
          <ListItem
            className={classes.listItem}
            key={ev.ptsEventID}
            onClick={() => props.editEvent(ev.ptsEventID)}>
            <EventAvatar event={ev} />
            {renderLocation(ev)}
            <EventStatus event={ev} />
          </ListItem>
        ))}
      </List>
    </SearchPanel>
  );
}

export default connect(null, { editEvent })(EventDuplicates);
