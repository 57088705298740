import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditVehicle } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import EditVehicle from './EditVehicle';
import Registrations from './Registrations';
import Towing from './Towing';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Attachments from 'components/Attachments';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: -theme.spacing(3),
  },
}));

function EditVehicleDialog(props) {
  const classes = useStyles();
  const { ptsVehicleID } = props;
  const [tab, setTab] = useState('edit'); // edit, registrations, towing, attachments

  const close = () => {
    props.closeEditVehicle();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit Vehicle" actions={renderActions()} fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit Vehicle" value="edit" />
            <Tab label="Registrations" value="registrations" />
            <Tab label="Towing" value="towing" />
            <Tab label="Attachments" value="attachments" />
          </Tabs>
        </Paper>
        {tab === 'edit' && <EditVehicle ptsVehicleID={ptsVehicleID} />}
        {tab === 'registrations' && <Registrations ptsVehicleID={ptsVehicleID} />}
        {tab === 'towing' && <Towing ptsVehicleID={ptsVehicleID} />}
        {tab === 'attachments' && <Attachments type="Vehicle" id={ptsVehicleID} />}
      </div>
    </Dialog>
  );
}

export default connect(null, { closeEditVehicle })(EditVehicleDialog);
