import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import headerNames from './headerNames.json';
import clsx from 'clsx';
import { cloneObj } from '../../utils/functions';
import { displayDateTime, isValidDate } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  vTable: {
    '& th': {
      border: `1px solid ${theme.colors.grey1}`,
      padding: '4px 10px',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      textAlign: 'right',
    },
    '& td': {
      border: `1px solid ${theme.colors.grey1}`,
      padding: '4px 10px',
    },
  },
  tableNoBorder: {
    '& th': {
      padding: '4px 10px',
      border: 'none',
      fontWeight: 500,
      textAlign: 'right',
    },
    '& td': {
      padding: '4px 10px',
      border: 'none',
    },
  },
  tableWrap: {
    width: '100%',
    '& table': {
      width: '100%',
    },
  },
}));

function getHeaderName(str) {
  const predefinedName = headerNames[str];
  if (predefinedName) return predefinedName;
  const result = str.replace(/([A-Z])/g, ' $1').replace('Number', 'No');
  return result;
}

function formatValue(str) {
  if (isValidDate(str)) return displayDateTime(str);
  if (typeof str === 'object') {
    if (str === null) return '';
    return <DisplayData variant="V" data={str} noBorder />;
  }
  if (str === true) return 'YES';
  if (str === false) return 'NO';
  return str;
}

/** Output Vertical table from object */
function VTable(props) {
  const classes = useStyles();
  const obj = cloneObj(props.obj);
  return (
    <table className={clsx(props.noBorder ? classes.tableNoBorder : classes.vTable, 'v-table')}>
      <tbody>
        {Object.entries(obj).map(([key, val], idx) => (
          <tr key={idx}>
            <th title={key}>{getHeaderName(key)}</th>
            <td>{formatValue(val)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

/**
 * Display data component
 *   properties
 *   title - H4 title
 *   subtitle - H5 subtitle
 *   variant  - V for vertical table, H for horizontal
 *   filters - array of keys that shouldn't be printed
 *   paper (true/false) - wrap table with <Paper> element
 */
function DisplayData(props) {
  const { noBorder, data, setRef } = props;
  const classes = useStyles();
  const rawData = props.data;
  const isArray = Array.isArray(rawData);
  const ref = useRef();
  const isEmpty =
    rawData === undefined ||
    rawData === null ||
    (isArray && rawData.length === 0) ||
    (!isArray && Object.keys(rawData).length === 0);

  if (isEmpty) return '';

  useEffect(() => {
    if (!setRef) return;
    setRef(ref);
    // eslint-disable-next-line
  }, []);

  const displayTable = (tableData, key) => (
    <div key={key} style={{ marginBottom: 25 }}>
      <VTable obj={tableData} noBorder={noBorder} />
    </div>
  );

  return (
    <div className={classes.tableWrap} ref={ref}>
      {!isArray && displayTable(data)}
      {isArray && data.map(displayTable)}
    </div>
  );
}

export default DisplayData;
