import { getService } from './service';

export const getRotationUnits = (Rotation) => {
  const service = getService('cad-rotations');
  return service.find({ query: { Rotation } });
};

export const updateRotationUnits = (data) => {
  const service = getService('cad-rotations');
  return service.patch(data.Rotation, data);
};
