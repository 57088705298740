import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8bomb100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="100"
      height="100">
      <path d="M224.973 44a54.069 54.069 0 0 0-54.053 54 40.054 40.054 0 0 1-40.057 40H94.752a7 7 0 0 0 0 14h36.111a54.069 54.069 0 0 0 54.057-54 40.053 40.053 0 0 1 40.053-40h5.472C252.531 58 270 76.35 270 98.436V117h14V98.436C284 68.63 260.25 44 230.445 44h-5.472zM236 124v46.783a162.09 162.09 0 0 1 19.537-4.199c.268-.041.537-.086.8-.125.89-.133 1.78-.256 2.669-.367.749-.1 1.5-.187 2.25-.274a162.092 162.092 0 0 1 6.017-.582c.658-.05 1.316-.1 1.975-.144a152.7 152.7 0 0 1 4.123-.213c.51-.02 1.022-.034 1.533-.049 1.432-.043 2.864-.068 4.3-.074h.476c1.75 0 3.5.038 5.24.094h.117a158.275 158.275 0 0 1 37.955 5.898V124H236zm43.594 54.756a144.4 144.4 0 0 0-144.465 144.556c0 79.712 64.849 144.561 144.559 144.561s144.56-64.85 144.56-144.56a144.793 144.793 0 0 0-96.453-136.366c-.027-.009-.057-.01-.084-.02a147.1 147.1 0 0 0-48.117-8.171z" />
    </SvgIcon>
  );
}

export default icons8bomb100Icon;
