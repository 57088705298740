import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CrashInjuryIcon(props) {
  return (
    <SvgIcon
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 512 512">
      <path d="M423.9 414.5c9-2.8 14.1-12.3 11.4-21.4l-8.7-29.3c-2.6-9-12.1-14.2-21.1-11.5l-28.6 8.7-5.5 5.5 22.8 5.6-31.3 5.1-27.5-32c-4.1-4.7-11.1-5.6-16.2-2-5.5 4-6.8 11.7-2.8 17.2.2.3.4.5.6.8l31.7 37c2.7 3.1 6.7 4.6 10.7 4.1l28.5-.9c-20.6 7.8-15.3 8.5-24.4 12-3.9 1.5-10.8 1.3-16.8-2-4.7-2.5-6.7-5.4-10.4-9.6l-19.2 19.4c6.9 12.5 21.6 18.5 35.3 14.5 26.2-7.7 65.6-19.4 71.5-21.2z" />
      <path d="M302.6 355.7c-.9-9.4 3.7-18.4 11.9-23.2l-19.6-19.8 59.9-60.5 27.1 42.8-.4.4-39.6 40c1.7 1.5.9.7 16.3 18.7l48.9-49.4c3.6-3.6 4.2-9.3 1.5-13.6L359 212.8l-34-35.1c5.8-9.6 4.4-21.8-3.4-29.8-9.2-9.4-24.3-9.5-33.7-.2l-.2.2-9.1 9.2c22.3 22.6 22.3 59 0 81.7-22 22.3-57.9 22.6-80.2.6l-.6-.6-63.5 64.1c22.3 22.6 22.3 59 0 81.7-22 22.3-57.9 22.6-80.2.6l-.6-.6-3 3.1c-9.4 9.5-9.4 24.8 0 34.3 7.1 7.2 18.1 9.1 27.3 4.7l8.1 8.2c14.5 14.8 38.3 14.9 53.1.4l.4-.4 24-24.3 102.4 29.2c3.8.6 7.5.6 10.5-2.8l48.4-48.9c-16.8-19.5-21.1-22.5-22.1-32.4zm-38.1 57.9-54.2-15.5 67.2-67.9 20.6 20.9-17.4 3.3-36.5 36.9 51.5-9.2-31.2 31.5z" />
      <ellipse cx="238.1" cy="198" rx="36.6" ry="36.9" />
      <ellipse cx="93.8" cy="343.8" rx="36.6" ry="36.9" />
      <ellipse cx="462.2" cy="369" rx="25.1" ry="25.4" />
      <path d="M69.9 151.1c4.3 0 8.6-1.1 12.3-3.2 2.8 11.6 13 19.9 24.9 20.1.1 9.2 7.6 16.5 16.7 16.4 9.2-.1 16.5-7.6 16.4-16.7-.1-6.2-3.6-11.9-9.2-14.7 1.7-3.5 2.6-7.4 2.6-11.3 9.2-5.7 12.1-17.7 6.5-27-3.5-5.7-9.7-9.2-16.3-9.3 1.2-3.2 1.8-6.7 1.8-10.1.3-15.9-12.3-29-28.2-29.3s-29 12.3-29.3 28.2v1.1c0 1.5.1 3 .3 4.4-14.2.9-24.9 13.1-24 27.2.8 13.5 11.9 24 25.5 24.2z" />
      <ellipse cx="143.1" cy="204.1" rx="10.7" ry="10.8" />
    </SvgIcon>
  );
}

export default CrashInjuryIcon;
