import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';

const navWidth = 86;
const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nav: {
    width: 85,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  column: {
    flexGrow: 1,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.colors.border}`,
    width: `calc(50% - ${parseInt(navWidth / 2)}px)`,
    overflowY: 'auto',
    '& h5': {
      textAlign: 'center',
    },
  },
  activePanel: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 7,
  },
}));

function GroupEditPanel(props) {
  const classes = useStyles();
  const {
    options1,
    options2,
    setOptions1,
    setOptions2,
    keyID,
    labelID,
    title1,
    title2,
    height,
  } = props;
  const [opt1Selection, setOpt1Selection] = useState([]);
  const [opt2Selection, setOpt2Selection] = useState([]);
  const [lastIdx, setLastIdx] = useState(null);
  const [dragged, setDragged] = useState(null);
  const [draggedOver, setDraggedOver] = useState(null);
  const draggedOverRef = useRef(null);
  const draggedTimer = useRef(0);

  const handleOptClick = (ev, no, opt) => {
    const shiftPressed = ev.shiftKey;
    const selection = no === 1 ? opt1Selection : opt2Selection;
    const options = no === 1 ? options1 : options2;
    const idx = options.indexOf(opt);
    const setSelection = no === 1 ? setOpt1Selection : setOpt2Selection;
    const selectionIdx = selection.indexOf(opt[keyID]);
    const deselecting = selectionIdx !== -1;
    let newSelection;
    if (deselecting) {
      newSelection = [...selection];
      newSelection.splice(selectionIdx, 1);
    } else {
      newSelection = [...selection, opt[keyID]];
    }
    if (shiftPressed && idx !== lastIdx) {
      const from = Math.min(idx, lastIdx);
      const to = Math.max(idx, lastIdx);
      const objSelection = [...options].splice(from, to - from + 1);
      objSelection.forEach((obj) => {
        const objIdx = newSelection.indexOf(obj[keyID]);
        if (deselecting) {
          objIdx !== -1 && newSelection.splice(objIdx, 1);
        } else {
          objIdx === -1 && newSelection.push(obj[keyID]);
        }
      });
    }
    setSelection(newSelection);
    setLastIdx(idx);
  };

  const handleMove = (no, extraElement) => {
    const selection = no === 1 ? opt1Selection : opt2Selection;
    const setSelection = no === 1 ? setOpt1Selection : setOpt2Selection;
    const optionsFrom = no === 1 ? options1 : options2;
    const optionsTo = no === 1 ? options2 : options1;
    const setFrom = no === 1 ? setOptions1 : setOptions2;
    const setTo = no === 1 ? setOptions2 : setOptions1;
    const newElements = optionsFrom.filter((el) => selection.indexOf(el[keyID]) !== -1);
    let remainingElements = optionsFrom.filter((el) => selection.indexOf(el[keyID]) === -1);
    if (extraElement && remainingElements.find((el) => extraElement[keyID] === el[keyID])) {
      newElements.push(extraElement);
      remainingElements = remainingElements.filter((el) => el[keyID] !== extraElement[keyID]);
    }
    setFrom(remainingElements);
    setTo([...optionsTo, ...newElements]);
    setSelection([]);
  };

  const onDragStart = (no, opt) => {
    setDragged([no, opt]);
  };

  const onDrop = (no) => {
    if (dragged && no !== dragged[0]) {
      handleMove(3 - no, dragged[1]);
    }
    setDragged(null);
    setDraggedOver(null);
  };

  const onDragOver = (ev, no) => {
    ev.preventDefault();
    clearTimeout(draggedTimer.current);
    if (draggedOverRef.current !== no) {
      draggedOverRef.current = no;
      setDraggedOver(no);
    }
  };

  const onDragLeave = () => {
    draggedTimer.current = setTimeout(() => {
      draggedOverRef.current = null;
      setDraggedOver(null);
    }, 50);
  };

  const renderOpt = (no) => {
    const options = no === 1 ? options1 : options2;
    const title = no === 1 ? title1 : title2;
    const selection = no === 1 ? opt1Selection : opt2Selection;

    return (
      <div
        className={clsx(classes.column, draggedOver === no ? classes.activePanel : null)}
        onDragOver={(ev) => onDragOver(ev, no)}
        onDragLeave={() => onDragLeave()}
        onDrop={() => onDrop(no)}>
        <h5>{title}</h5>
        <PerfectScrollbar style={{ height: height - 40 }}>
          <List dense component="div" role="list">
            {options.map((opt) => {
              return (
                <ListItem
                  key={opt[keyID]}
                  role="listitem"
                  button
                  onClick={(ev) => handleOptClick(ev, no, opt)}
                  style={{ padding: 0 }}
                  draggable
                  onDragStart={() => onDragStart(no, opt)}>
                  <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
                    <Checkbox
                      checked={selection.indexOf(opt[keyID]) !== -1}
                      tabIndex={-1}
                      disableRipple
                      style={{ padding: 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={opt[labelID]} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </PerfectScrollbar>
      </div>
    );
  };

  const handleAllLeft = () => {
    setOptions1([...options1, ...options2]);
    setOptions2([]);
  };

  const handleAllRight = () => {
    setOptions2([...options1, ...options2]);
    setOptions1([]);
  };

  const renderNav = () => {
    return (
      <div className={classes.nav}>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllRight}
          disabled={options1.length === 0}
          aria-label="move all right">
          ≫
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => handleMove(1)}
          disabled={opt1Selection.length === 0}
          aria-label="move selected right">
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => handleMove(2)}
          disabled={opt2Selection.length === 0}
          aria-label="move selected left">
          &lt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllLeft}
          disabled={options2.length === 0}
          aria-label="move all left">
          ≪
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {renderOpt(1)}
      {renderNav()}
      {renderOpt(2)}
    </div>
  );
}

export default GroupEditPanel;
