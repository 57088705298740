import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DisturbanceWithWeaponIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 -64 512 512" {...props}>
      <circle transform="rotate(-85.269 170.026 209.964)" cx="170" cy="210" r="42.7" />
      <path d="M245 250.1c-5.4-3.7-12.8-2.4-16.5 3l-21.8 31.4-31.9-23.7c-.5-.3-.9-.7-1.4-1l-.6-.3c-7.4-4.8-16.4-7.2-25.9-6.2-21.5 2.4-36.9 21.8-34.4 43.3l9.4 82.3c.3 2.8 1 5.6 1.9 8.2l-5.6 17.7-57.6 14.8c-6.2 1.6-10.4 7.5-9.6 14 .9 7 7.3 12.1 14.3 11.2l69.6-8.6c5-.7 9.6-3.8 11.9-8.7l1-2.1 5.8-12.4c2.3.4 4.6.6 7 .6 1 0 2.1-.1 3.2-.2l6.8 16.2-79 20.9c-6.4 1.7-10.6 8.1-9.3 14.8 1.3 7 8 11.6 15 10.2l99.5-18.7c.4-.1.9-.2 1.3-.3 8.5-2.3 13.5-11.1 11.1-19.6l-.4-1.6-12.4-44.9c2.8-6.2 4.1-13.2 3.2-20.5l-6.4-56.1 8.5 5.1 1.5.9c.3.2.5.3.8.4 7.1 3.7 15.8.9 19.5-6.2l25.3-49c2.7-5 1.2-11.5-3.8-14.9z" />
      <circle cx="388.9" cy="109.3" r="42.7" />
      <path d="M415.6 150.5c-16-.5-30.1 8.7-36.5 22.4l-29.7 22.4-68 10.2c-5.9.9-10.4 6.2-10.1 12.3.3 6.5 5.9 11.6 12.4 11.2l73.4-3.7c2-.1 4.1-.7 5.9-1.8l1.3-.8 10.3-6.3-1.6 53.8c-.5 15.5 8.2 29.1 21.1 35.8L393 457.7c0 7.6 5.7 14.1 13.4 14.9 8.1.8 15.4-5.2 16.2-13.3l15.1-158.2c8-6.9 13.3-17 13.6-28.5l2.4-81.7c.5-21.8-16.5-39.8-38.1-40.4z" />
      <path d="M245.1 198l3.4-.3.9 3.7 21.9-1.7c.4 1.2 1.4 3.3 3.6 4.9l24.3-5.6-.6-1.8c.7-.5.9-1.3.8-2.3h.1c.4 0 .6-.4.6-.7l-.8-10.5c0-.4-.3-.6-.7-.6l-3.6.3c-.7-.5-1.6-.8-2.4-1.1l.1-.3c.1-.3-.1-.7-.4-.9l-.6-.2c-.3-.1-.7.1-.9.4l-.3.8h-.6l-38.7 3v-.4c0-.4-.3-.6-.7-.6l-3.1.2c-.4 0-.6.3-.6.7v.4l-1.4.1.7 2.8-1.8.1c-.4 0-.6.3-.6.7l.6 8.2c.2.5.5.7.8.7zm37.4.9l.7 2c-1.1.2-2 .4-2.9.7.5-.8.9-1.8 1.1-2.6l1.1-.1zm-2.3.2c-.4 1.3-1.1 2.7-1.9 3.2l-.3.3c-.6.3-1.2.7-1.7 1-1.9-1.2-2.8-2.9-3.2-3.9l7.1-.6z" />
    </SvgIcon>
  );
}

export default DisturbanceWithWeaponIcon;
