import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8death100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      width="100"
      height="100">
      <path d="M10.313 0c-.899 0-1.72 1.105-1.72 2.406 0 1.399.696 2.5 1.595 2.5.898 0 1.718-1.008 1.718-2.406 0-1.398-.695-2.5-1.594-2.5Zm5.374 0c-.898 0-1.593 1.102-1.593 2.5s.82 2.406 1.719 2.406c.898 0 1.593-1.101 1.593-2.5 0-1.398-.82-2.406-1.718-2.406Zm3.72 1.188c-.7 0-1.22.718-1.22 1.718-.101 1 .426 1.781 1.125 1.781.7 0 1.188-.687 1.188-1.687s-.492-1.71-1.094-1.813Zm-12.813.125c-.7 0-1.196.789-1.094 1.687 0 1 .586 1.688 1.188 1.688.699 0 1.226-.79 1.125-1.688 0-1-.618-1.688-1.22-1.688Zm-2.907 1.5c-.601.101-.882.792-.78 1.593.1.801.593 1.282 1.093 1.282.602-.102.914-.793.813-1.594-.102-.801-.625-1.282-1.125-1.282Zm18.407.03c-.469.071-.88.579-.969 1.25-.102.766.266 1.43.813 1.5.546.071 1.054-.484 1.156-1.25.101-.765-.266-1.429-.813-1.5a.604.604 0 0 0-.187 0ZM1.406 4.814c-.398.199-.511.773-.312 1.375.199.601.695.914 1.093.812.399-.102.512-.707.313-1.406-.2-.602-.695-.883-1.094-.782Zm22.782.093c-.32.07-.633.332-.782.782-.101.601.008 1.21.407 1.312.398.102.894-.21 1.093-.813.2-.601-.008-1.18-.406-1.28a.703.703 0 0 0-.313 0ZM7.5 5.188c-2.3.199-7.602 2.3-7 7 .602 4.898 3.207 6 4.406 10.5.5 1.699 1.688 3.613 3.688 3.312 3-.398 3.511-2.102 3.312-4-.3-3.5-2.5-2.387-3-6.188C8.606 12.915 12 12.595 12 8.095c0-2.801-3.398-3.008-4.5-2.906Zm10.5 0c-1.281.007-3.621.382-3.969 2.375v.03l-.719.595c-.198.198-.312.51-.312.812v8c0 .602.398 1 1 1h2.313c-.864 1.344-2.063 1.516-2.313 4-.2 1.898.313 3.602 3.313 4 2 .3 3.187-1.613 3.687-3.313 1.2-4.5 3.805-5.699 4.406-10.5.7-4.699-4.605-6.8-6.906-7-.137-.011-.3 0-.5 0Zm-1 1.218L20 9v8h-6V9l.094-.063c.008.02-.008.043 0 .063ZM.406 7.094c-.398.101-.511.593-.312 1.093.101.399.508.727.906.626.3-.102.414-.626.313-1.126-.2-.398-.606-.695-.907-.593Zm25 .062c-.246.059-.504.305-.625.657-.16.468-.031.921.282 1.03.312.11.714-.187.875-.655.16-.47.03-.922-.282-1.032a.453.453 0 0 0-.25 0ZM17 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Z" />
    </SvgIcon>
  );
}

export default icons8death100Icon;
