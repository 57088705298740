import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';

const btnStyle = {
  margin: '0 6px 0 8px',
  padding: 0,
};

function TextFieldPlus(props) {
  if (!props.onAdd)
    return (
      <TextField
        {...props}
        inputProps={{
          ...props.inputProps,
          autoComplete: 'new-password',
        }}
      />
    );
  const textFieldProps = { ...props };
  delete textFieldProps.onAdd;

  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        ...props.InputProps,
        autoComplete: 'new-password',
        startAdornment: (
          <IconButton style={btnStyle} onClick={props.onAdd}>
            <AddIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
}

export default TextFieldPlus;
