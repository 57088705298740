import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8fire100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="100"
      height="100">
      <path d="M13 2a1 1 0 0 0-1 1c0 5.057-7 9-7 16 0 5.318 5.763 8.756 7.793 8.979A1 1 0 0 0 13 28a1 1 0 0 0 1-1 1 1 0 0 0-.37-.775 1 1 0 0 0 0-.002c-.91-.738-2.63-2.88-2.63-4.834C11 18.179 14 17 14 17c-1.711 4.84 3.895 5.425 5.023 10.217h.002A1 1 0 0 0 20 28a1 1 0 0 0 .586-.191 1 1 0 0 0 .053-.041C20.8 27.662 25 24.885 25 19c0-2.403-1.202-6.53-2.066-8.357l-.002-.006a1 1 0 0 0-.002-.004A1 1 0 0 0 22 10a1 1 0 0 0-.982.818 1 1 0 0 0 0 .004c-.003.011-.462 2.051-2.018 3.178 0-4.712-3.402-9.578-5.195-11.592a1 1 0 0 0-.088-.103A1 1 0 0 0 13 2z" />
    </SvgIcon>
  );
}

export default icons8fire100Icon;
