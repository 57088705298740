import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditDictionary } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { getDictionary, saveDictionary, deleteDictionary } from 'reducers/DictionaryReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import { XGrid } from '@material-ui/x-grid';
import formStyles, { gridStyle, RowInner, ColCard, Fills } from 'utils/formStyles';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(250, '100%'),
  edit: {
    margin: '0 -4px',
  },
  gridWrap: {
    width: 1000,
    maxWidth: '100%',
    height: 400,
  },
  actions: {
    textAlign: 'right',
    marginBottom: 16,
    width: '100%',
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  gridActions: {
    textAlign: 'right',
    marginBottom: 16,
    '& > button': {
      marginLeft: 8,
    },
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultPermissions = {
  Create: false,
  Delete: false,
  Edit: false,
  Read: true,
  ViewDeleted: false,
};
const defaultColumns = [
  { field: 'Code', headerName: 'Code', width: 100 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 130 },
  {
    field: 'IsDefault',
    headerName: 'Is Default',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsActive',
    headerName: 'Is Active',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
];

const cityColumns = [
  { field: 'CityDescription', headerName: 'City', width: 250 },
  { field: 'Category', headerName: 'Category', width: 130 },
  {
    field: 'IsDefault',
    headerName: 'Is Default',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsActive',
    headerName: 'Is Active',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
];

function ExampleWindow(props) {
  const classes = useStyles();
  const { name, title } = props.data;
  const [rawRows, setRawRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [rawColumns, setRawColumns] = useState(defaultColumns);
  const [columns, setColumns] = useState(defaultColumns);
  const [selection, setSelection] = useState(null);
  const [Code, setCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Category, setCategory] = useState('');
  const [CityDescription, setCityDescription] = useState('');
  const [IsDefault, setIsDefault] = useState(false);
  const [IsActive, setIsActive] = useState(true);
  const [IsDeleted, setIsDeleted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [changed, setChanged] = useState(false);
  const [edited, setEdited] = useState(false);
  const [defaultDisabled, setDefaultDisabled] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [ROWGUID, setROWGUID] = useState(null);
  const mountedRef = useRef(true);
  const formValid = changed && !Object.keys(validationErrors).length;
  const permissions = props.permissions[name] ? props.permissions[name] : defaultPermissions;

  useEffect(() => {
    getData();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [CityDescription, Code, Description, IsDefault, IsActive, IsDeleted]);

  useEffect(() => {
    if (!permissions.ViewDeleted) {
      const columns = rawColumns.filter((col) => col.field !== 'IsDeleted');
      const rows = rawRows.filter((row) => !row.IsDeleted);
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns(rawColumns);
      setRows(rawRows);
    }

    // eslint-disable-next-line
  }, [rawColumns, permissions, rawRows]);

  const validateForm = () => {
    if (!changed) {
      Object.keys.length && setValidationErrors({});
      return;
    }
    let validation = {};
    if (!Code && !CityDescription) validation.Code = 'Code cannot be empty';
    if (isNew && rows.findIndex((r) => r.Code === Code) !== -1)
      validation.Code = 'Code already exists';
    if (name === 'Cities' && !CityDescription) validation.CityDescription = 'City cannot be empty';
    setValidationErrors(validation);
  };

  const getData = async () => {
    setLoaded(false);
    try {
      const result = await getDictionary(name);
      processResult(result);
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processResult = (result) => {
    const { data, permissions } = result;
    let rows = data.map((d) => ({ ...d, id: d.ROWGUID }));
    let columns;
    switch (name) {
      case 'Cities':
        columns = cityColumns;
        break;
      default:
        columns = defaultColumns;
    }
    setRawColumns(columns);
    setRawRows(rows);
    const { Create, Edit, Delete } = permissions;
    if (Create && !Edit && !Delete) add();
  };

  const close = (data) => {
    props.closeEditDictionary(data);
  };

  const save = async () => {
    let data;
    switch (name) {
      case 'Cities':
        data = { CityDescription, Category, IsActive, IsDefault, IsDeleted, ROWGUID };
        break;
      default:
        data = { Code, Description, Category, IsActive, IsDefault, IsDeleted, ROWGUID };
    }
    props.showSpinner();
    try {
      await saveDictionary(name, data);
      getData();
      setEdited(false);
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const add = (ev, newRows) => {
    const dataRows = newRows ? newRows : rows;
    const defaultExists = dataRows.findIndex((row) => row.IsDefault) !== -1;
    setDefaultDisabled(defaultExists);
    setCode('');
    setDescription('');
    setCategory('');
    setIsDefault(false);
    setIsActive(true);
    setIsDeleted(false);
    setEdited(true);
    setIsNew(true);
    setChanged(false);
    setROWGUID(null);
    setCityDescription('');
  };

  const edit = (ev) => {
    const row = rows.find((r) => r.ROWGUID === selection);
    const defaultRow = rows.find((row) => row.IsDefault);
    let defaultDisabled = false;
    if (defaultRow && defaultRow.Code !== row.Code) defaultDisabled = true;
    setDefaultDisabled(defaultDisabled);
    setCode(row.Code);
    setDescription(row.Description || '');
    setCategory(row.Category || '');
    setIsDefault(row.IsDefault);
    setIsActive(row.IsActive);
    setIsDeleted(row.IsDeleted);
    setEdited(true);
    setIsNew(false);
    setChanged(false);
    setROWGUID(selection);
    setCityDescription(row.CityDescription);
  };

  const del = async (ev) => {
    props.showSpinner();
    try {
      await deleteDictionary(name, selection);
      getData();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const WindowActions = () => {
    return (
      <Button color="primary" onClick={close}>
        <CloseIcon /> Close
      </Button>
    );
  };

  const EditActions = () => {
    return (
      <div className={classes.actions}>
        <hr />
        <Button color="primary" variant="contained" onClick={save} disabled={!formValid}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEdited(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const XgridActions = () => {
    return (
      <div className={classes.gridActions}>
        {permissions.Create && (
          <Fab size="small" color="secondary" onClick={add}>
            <AddIcon />
          </Fab>
        )}
        {permissions.Edit && (
          <Fab size="small" color="secondary" onClick={edit} disabled={!selection}>
            <EditIcon />
          </Fab>
        )}
        {permissions.Delete && (
          <Fab size="small" color="secondary" onClick={del} disabled={!selection}>
            <DeleteIcon />
          </Fab>
        )}
      </div>
    );
  };

  const CodeTextField = () => {
    const onChange = (ev, val) => {
      setCode(val);
      !changed && setChanged(true);
    };
    const error = validationErrors.Code;
    return (
      <TextField2
        className={classes.item}
        label="Code"
        value={Code}
        onChange={onChange}
        error={error}
        max={10}
        disabled={!isNew}
      />
    );
  };

  const CityDescriptionTextField = () => {
    const onChange = (ev, val) => {
      setCityDescription(val);
      !changed && setChanged(true);
    };
    return (
      <TextField2
        className={classes.item}
        label="City"
        value={CityDescription}
        onChange={onChange}
        max={100}
      />
    );
  };

  const DescriptionTextField = () => {
    const onChange = (ev, val) => {
      setDescription(val);
      !changed && setChanged(true);
    };
    return (
      <TextField2
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={100}
      />
    );
  };

  const CategoryTextField = () => {
    const onChange = (ev, val) => {
      setCategory(val);
      !changed && setChanged(true);
    };
    return (
      <TextField2
        className={classes.item}
        label="Category"
        value={Category}
        onChange={onChange}
        max={10}
      />
    );
  };

  const IsDefaultCheckbox = () => {
    const onChange = () => {
      setIsDefault(!IsDefault);
      !changed && setChanged(true);
    };

    return (
      <Checkbox2
        checked={IsDefault}
        onChange={onChange}
        label="Is Default"
        disabled={defaultDisabled}
      />
    );
  };

  const IsActiveCheckbox = () => {
    const onChange = () => {
      setIsActive(!IsActive);
      !changed && setChanged(true);
    };
    return <Checkbox2 checked={IsActive} onChange={onChange} label="Is Active" />;
  };

  const IsDeletedCheckbox = () => {
    const onChange = () => {
      setIsDeleted(!IsDeleted);
      !changed && setChanged(true);
    };
    return (
      <Checkbox2 checked={IsDeleted} onChange={onChange} label="Is Deleted" disabled={isNew} />
    );
  };

  const EditForm = () => {
    return (
      <div className={classes.edit}>
        <ColCard>
          <RowInner>
            {name !== 'Cities' && CodeTextField()}
            {name !== 'Cities' && DescriptionTextField()}
            {name === 'Cities' && CityDescriptionTextField()}
            {CategoryTextField()}
            <Fills className={classes.item} />
          </RowInner>
          <RowInner>
            {IsDefaultCheckbox()}
            {IsActiveCheckbox()}
            {permissions.ViewDeleted && permissions.Delete && IsDeletedCheckbox()}
          </RowInner>
          <RowInner>{EditActions()}</RowInner>
        </ColCard>
      </div>
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={<WindowActions />}>
      {edited && EditForm()}
      {!edited && XgridActions()}
      <XGrid
        className={classes.gridWrap}
        columns={columns}
        rows={rows}
        loading={!loaded}
        rowHeight={38}
        disableMultipleSelection={true}
        showToolbar
        disableColumnFilter
        checkboxSelection={false}
        disableSelectionOnClick={edited}
        onSelectionModelChange={(newSelection) => {
          setSelection(newSelection[0]);
        }}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeEditDictionary,
  handleError,
  showSpinner,
  hideSpinner,
})(ExampleWindow);
