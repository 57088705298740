import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DisturbanceDomesticIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="m465.7 450.5-26.3-96 11.1-72.3-7.3-87.5c-2.7-32.4-29.1-57.1-60.7-58.5-9.4 7.5-21.3 11.4-34.1 10.4-4 5-6.1 11.4-5.5 18.2l5.6 68.6 18.8-19.3 14.3-37.1-5.3 48.9-41 42c-6.6 6.8-6.5 17.6.3 24.2 6.8 6.6 17.6 6.5 24.2-.3l45.1-46.2c2.7-2.7 4.4-6.3 4.8-10.1l5.9-54.8 8.7 56.3c1.5 9.5-4.5 14.3-8.8 18.8-9.4 9.6-35.7 36.6-45.1 46.2-6.2 6.3-14.4 9.5-22.6 9.6L335 346.5c-.7 1.8-1.1 3.8-1.2 5.7l-6.7 102.4c-.8 11.8 8.6 21.9 20.5 21.9 10.7 0 19.8-8.3 20.5-19.2l6.5-99.5 23.8-64.8 9-.7-9.3 60.4c-.4 2.9-.3 5.8.5 8.5l27.5 100.2c3 10.9 14.3 17.4 25.2 14.4 10.9-3 17.4-14.3 14.4-25.3z" />
      <circle cx="352.2" cy="98.4" r="35.2" />
      <path d="m148.9 178.7-9.2 9.1 3 1.2 8.5-6.4zM279 129.9c-2.6-8.1-11.2-12.6-19.3-10l-61.6 19.5 4 6.6c10.8-3.2 22.9.4 30.2 9.9 1 1.3 1.9 2.7 2.6 4.1l34-10.8c8.2-2.5 12.7-11.2 10.1-19.3zM157.7 90.6c-3.8-16.7-20.4-27.2-37.1-23.4-9.9 2.2-17.6 9-21.4 17.5 16.4-6.4 35.4-.5 45 15.2 3.7 6 5.4 12.6 5.4 19.1 7.1-7.1 10.5-17.7 8.1-28.4z" />
      <path d="M135.5 105.3c-7.8-12.7-24.4-16.7-37.1-8.9-13.9 8.5-17.2 27.4-6.7 40.2 2.7-.6 5.6-.6 8.5-.2l28.2 4.7c11.3-8.2 14.5-23.8 7.1-35.8zM198 232l11-23-37.7 28.7 10.1 33.6c2.2 7.2 9.7 11.3 16.9 9.1 7.2-2.2 11.3-9.7 9.1-16.9L198 232z" />
      <path d="M222.1 163.8c-5-6.6-14.5-7.9-21.1-2.9l-53.3 40.6-16.9-12.9c-5-.8-32.3-5.4-38.2-6.4-5.1-.8-9.5-3.3-12.8-6.7-1.6 5.8.3 12.2 5.3 16.1l1.6 1.2-24.9 136.3c-1 5.6 3 10.9 8.2 10.9h19.9v116.9c0 10.8 8.8 19.6 19.6 19.6 2.7 0 5.2-.5 7.5-1.5-3.6-5.1-5.8-11.4-5.8-18.1v-117H123v116.9c0 10.8 8.8 19.6 19.6 19.6s19.6-8.8 19.6-19.6V339.9h24.5c5.3 0 9.3-5.2 8.2-10.9l-7.2-39.4c-7.3-2.1-13.4-7.8-15.8-15.6l-9.1-30.2-3.4 2.6c-9.1 6.9-22.1 5.2-29.1-3.8l-31.4-40.3 39.2 30.1c5.4 4.1 12.9 4.1 18.3 0l62.5-47.6c7-5 8.2-14.4 3.2-21z" />
      <path d="M184.8 135.3c-6.3-10.4-19.8-13.6-30.2-7.3-4.7 2.8-7.9 7.2-9.4 12l5-1-21.2 11.9-30.3-5.1c-7.4-1.2-14.4 3.8-15.7 11.2-1.2 7.4 3.8 14.4 11.2 15.7l39.8 6.7 29.5-16.6-8 8.8 3.2 5.3 34.5-26.3c.2-.2.4-.3.7-.5l-9.1-14.8z" />
    </SvgIcon>
  );
}

export default DisturbanceDomesticIcon;
