import React, { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import SearchPanel from './SearchPanel';
import { mapNight, mapDay } from 'config/configureMap';
import { geocodeCoords } from 'utils/mapFunctions';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  map: {
    height: 200,
  },
  activeMarker: {
    position: 'sticky',
    minWidth: 22,
    height: 22,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 4,
    boxSizing: 'border-box',
    border: '1px solid #000',
    transform: 'translate(-1px, -1px)',
  },
  addresses: {
    height: 75,
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.25, 2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey4,
      },
    },
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const positionOverlayView = (width, height) => ({
  x: -width / 2,
  y: -height / 2,
});

const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

function EventMap(props) {
  const classes = useStyles();
  const { themeMode, setLocation, data } = props;
  const { lat, lng, Description } = data;
  const coords = { lat, lng };
  const [mapCenter, setMapCenter] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const mapRef = useRef();
  const zoomRef = useRef();
  const mountedRef = useRef(true);
  mapOptions.styles = themeMode === 'day' ? mapDay : mapNight;

  useEffect(() => {
    zoomRef.current = 14;
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!lat || !lng) return;
    setMapCenter(coords);
    geocodeEvent();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!addresses.length) return;
    checkAddressMatch();
    // eslint-disable-next-line
  }, [addresses]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.setZoom(zoomRef.current);
  }, []);

  const MyMarker = (props) => {
    return (
      <OverlayView
        {...props}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={positionOverlayView}>
        <div className={classes.activeMarker}></div>
      </OverlayView>
    );
  };

  const geocodeEvent = () => {
    geocodeCoords(coords)
      .then((addresses) => {
        if (!mountedRef.current) return;
        setAddresses(addresses);
        // if (addresses.length) setLocation(addresses[0]);
      })
      .catch((err) => {
        props.handleError(err, 'Error, Problem with geocoding service');
      });
  };

  const checkAddressMatch = () => {
    if (!Description) return;
    const descArr = Description.trim().split(' ');
    if (!descArr.length) return;
    const no = descArr[0];
    if (isNaN(no)) return;
    const address = addresses.find((a) => a.AddressNumber === no);
    if (!address) return;
    setLocation({ ...address, IsPrimary: true });
  };

  const renderEvent = () => {
    return <MyMarker key={1} position={{ lat: data.lat, lng: data.lng }} />;
  };

  const renderMap = () => {
    return (
      <div className={classes.map}>
        {mapCenter !== null && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={8}
            center={mapCenter}
            options={mapOptions}
            key={themeMode}
            onLoad={onMapLoad}
            className={classes.map}>
            {renderEvent()}
          </GoogleMap>
        )}
      </div>
    );
  };

  const renderAddresses = () => {
    const addr = [];
    const uniqAddresses = addresses.filter((address) => {
      const uniq = addr.indexOf(address.formattedAddress) === -1;
      addr.push(address.formattedAddress);
      return uniq;
    });
    return (
      <div className={classes.addresses}>
        {uniqAddresses.map((address) => (
          <div
            key={address.formattedAddress}
            onClick={() => setLocation({ ...address, IsPrimary: true })}>
            {address.formattedAddress}
          </div>
        ))}
      </div>
    );
  };

  return (
    <SearchPanel title="Event Map">
      {renderMap()}
      {renderAddresses()}
    </SearchPanel>
  );
}

const mapStateToProps = (state) => {
  return {
    themeMode: state.theme.mode,
    mapOptions: state.map.options,
  };
};

export default connect(mapStateToProps, { handleError })(EventMap);
