/**
 * Library of functions that enables you to use SQL queries without converting the tome
 * to UTC.
 *
 * Usage examples are available on thest page: src\Dialogs\TestingDialog\TimeTesting.js
 *
 */
import { getService } from './service';
import { handleError } from './ErrorReducer';
import moment from 'moment';
import store from '../config/configureStore';

const localTZoffset = new Date().getTimezoneOffset();
const TIME_SETTINGS = 'TIME_SETTINGS';

// Used to display date and time on the screen
export function displayDateTime(date, utc = false) {
  //console.log("displayDateTime============", date);
  //console.log("displayDateTime============", formatTimeDate(date, 'L HH:mm', 'L LT', utc));
  return formatTimeDate(date, 'L HH:mm', 'L LT', utc);
}

// Used to display time on the screen
export function displayTime(date, utc = false) {
  return formatTimeDate(date, 'HH:mm', 'LT', utc);
}

// Used to display date on the screen
export function displayDate(date, utc = false) {
  return formatTimeDate(date, 'L', 'L', utc);
}

export const timeSyncInit = () => {
  return async (dispatch) => {
    setInterval(() => {
      const state = store.store.getState();
      const { isAuthenticated } = state.user;
      if (isAuthenticated) dispatch(getServerTimeSettings());
    }, 30000);
  };
};

export const getServerTimeSettings = () => async (dispatch) => {
  const service = getService('time');
  try {
    const time1 = new Date().getTime();
    const result = await service.get(0);
    const time2 = new Date().getTime();
    result.clockDiff = Math.round(time1 + (time2 - time1) / 2 - result.timestamp);
    result.localTZoffset = new Date().getTimezoneOffset();
    dispatch({ type: TIME_SETTINGS, payload: result });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const displayAgencyTime = () => (dispatch) => {
  dispatch({ type: TIME_SETTINGS, payload: { localTime: false } });
};

export const displayLocalTime = () => (dispatch) => {
  dispatch({ type: TIME_SETTINGS, payload: { localTime: true } });
};

// Time Format is set in the settings and this shouldn't be used in productions
export const doggleTimeFormat = () => (dispatch) => {
  const state = store.store.getState();
  const { format24h } = state.time;
  dispatch({ type: TIME_SETTINGS, payload: { format24h: !format24h } });
};

const useLocalTime = process.env.REACT_APP_LOCAL_TIME_ON === 'true';
const defaultState = {
  format24h: false, //  Do we use 24 hour format to display time or not
  dbTZoffset: 0, //      Difference between server time and UTC time (including time offset if server in the wrong timezone)
  clockDiff: 0, //      Time difference between server and local machine in ms. Used to sync internal clock
  localTime: useLocalTime, //   Time should be displayed in local or agency time [default: false]
  localTZoffset,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case TIME_SETTINGS:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export const getFormat24 = () => {
  const state = store.store.getState();
  return state.time.format24h;
};

export const dateTimePicker = (date) => {
  const state = store.store.getState();
  const { localTime, dbTZoffset, timeOffset, localTZoffset } = state.time;
  if (!date) return null;
  const m = moment(date);
  if (!m.isValid()) return false;
  if (localTime) {
    return m.utc().add(dbTZoffset, 'minutes');
  } else {
    return m.utc().add(timeOffset + localTZoffset, 'minutes');
  }
};

export function formatSaveDate(date) {
  const state = store.store.getState();
  const { localTime, dbTZoffset, timeOffset, localTZoffset } = state.time;
  if (!date) return null;
  const m = moment(date);
  if (!m.isValid()) return null;
  let d = m.utc();
  if (localTime) {
    d = m.add(-dbTZoffset, 'minutes');
  } else {
    d = m.add(-timeOffset - localTZoffset, 'minutes');
  }
  return d.format('YYYY-MM-DD HH:mm:ss');
}

export const formatSaveDateFrom = (date) => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 00:00:00.000';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss');
  return result;
};

export const formatSaveDateTo = (date) => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 23:59:59.999';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss.SSS');
  return result;
};

export function formatTimeDate(date, militaryFormat, localFormat, utc = false) {
  const state = store.store.getState();
  const { format24h, localTime, dbTZoffset, timeOffset, localTZoffset } = state.time;
  if (!date) return;
  // If there are cases where server doesn't return 'Z' character at the end uncomment the code below
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  // const m = moment(date);
  if (!m.isValid()) return '';
  const format = format24h ? militaryFormat : localFormat;
  let p;
  if (localTime) {
    p = m.utc().add(dbTZoffset - localTZoffset, 'minutes');
  } else {
    p = m.utc().add(timeOffset, 'minutes');
  }
  if (utc) {
    p.add(-localTZoffset, 'minutes');
  }
  return p.format(format);
}

// Return current server time and date (UTC)
// Clock difference between local computer and server are calculated
export function getServerDateTime() {
  const state = store.store.getState();
  const { clockDiff } = state.time;
  const time = new Date().getTime() - clockDiff;
  return new Date(time);
}

export function getClockTime() {
  const state = store.store.getState();
  const { format24h, localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;
  const time = new Date().getTime() - clockDiff;
  let m = moment(time);
  if (!localTime) {
    m = m.add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return format24h ? m.format('HH:mm:ss') : m.format('LTS');
}

export function getCurrentDate() {
  const state = store.store.getState();
  const { localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;
  const time = new Date().getTime() - clockDiff;
  let m = moment(time).utc();
  if (!localTime) {
    m = m.utc().add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return m;
}

// For displaying date time widget
export function getDateTimeFormat() {
  const state = store.store.getState();
  const { format24h } = state.time;
  return format24h ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy hh:mm a';
}

// For displaying time widget
export function getTimeFormat() {
  const state = store.store.getState();
  const { format24h } = state.time;
  return format24h ? 'HH:mm' : 'hh:mm a';
}

/** Fixes issue in some dates in database - adds Z at the end */
function addZtoDate(date) {
  if (typeof date !== 'string') return date;
  return date + (date.substr(-1) === 'Z' ? '' : 'Z');
}

// Functions that need to be tested - copied from functions

/** converts number of minutes to String hours and minutes [hh:mm] (must be positive) */
export function minsToHours(min) {
  const mins = min % 60;
  const hours = Math.floor(min / 60);
  return `00${hours}`.substr(-2) + ':' + `00${mins}`.substr(-2);
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

/** Returs passed time in minutes */
export function getPassedTime(date) {
  if (typeof date !== 'string' || date.length > 24) return 0;
  const state = store.store.getState();
  const { dbTZoffset } = state.time;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return 0;
  const currentDate = moment(getServerDateTime()).utc().subtract(dbTZoffset, 'minutes');
  const passedTime = currentDate.diff(m, 'minutes');
  return passedTime;
}
