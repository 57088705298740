// https://material-ui-pickers.dev/ - in package.json "@material-ui/pickers": "3.3.10",
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import Button from '@material-ui/core/Button';
import {
  displayDateTime,
  displayTime,
  displayDate,
  displayLocalTime,
  displayAgencyTime,
  doggleTimeFormat,
  dateTimePicker,
  getFormat24,
  formatSaveDateFrom,
  formatSaveDateTo,
  formatSaveDate,
  getCurrentDate,
  setDateTime,
  getDateTimeFormat,
} from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    '& code': {
      fontFamily: "'Courier New', Courier, monospace",
    },
  },
  timePicker: {
    margin: '8px 8px 8px 0',
    width: 220,
  },
  label: {
    display: 'inline-block',
    width: 50,
  },
  actions: {
    display: 'inline-block',
    paddingTop: 8,
    '& > button': {
      marginRight: 8,
    },
  },
}));

function TimeTesting(props) {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [Created, setCreated] = useState(null);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const { time } = props;
  const { localTime, utcOffset, timeOffset } = time;
  const format24 = getFormat24();

  useEffect(() => {
    getTime();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) return;
    // Date needs to be converted
    setCreated(dateTimePicker(data.Created));
    // eslint-disable-next-line
  }, [data, localTime]);

  const getTime = async () => {
    const service = getService();
    const data = await service.get({
      type: 'cad-testing',
      data: 2,
    });
    setData(data);
    setCreated(data.Created);
  };

  const toServerTime = () => {
    props.displayAgencyTime();
  };

  const toLocalTime = () => {
    props.displayLocalTime();
  };

  const handlePickerChange = (date) => {
    setCreated(date);
  };

  const saveDate = async () => {
    // Date needs to be converted and formatted for DB use
    const date = formatSaveDate(Created);
    const service = getService();
    await service.patch(0, { type: 'cad-testing', data: date });
    getTime();
  };

  const test = async (date) => {
    const service = getService();
    await service.patch(0, { type: 'cad-testing', data: date });
    getTime();
  };

  const getDate = () => {
    const date = getCurrentDate();
    setCreated(date);
  };

  return (
    <div className={classes.container}>
      <h5>
        Ev: 67749 ({localTime ? 'Local Time' : 'Agency Time'}), uct offset: {utcOffset}, server
        offset: {timeOffset}, format: {format24 ? '24 hour' : 'local'}
      </h5>
      <div style={{ marginBottom: 16 }}>
        {/* <Button variant="contained" style={{ marginRight: 8 }} color="primary" onClick={getTime}>Get Record</Button> */}
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={toServerTime}
          disabled={!localTime}>
          Agency Time
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={toLocalTime}
          disabled={localTime}>
          Local Time
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={props.doggleTimeFormat}>
          Toggle Format
        </Button>
      </div>
      {Created !== null && (
        <div>
          <p>Created (original): {data.Created}</p>
          <p>Created (date/time): {displayDateTime(data.Created)}</p>
          <p>Created (date): {displayDate(data.Created)}</p>
          <p>Created (time): {displayTime(data.Created)}</p>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date Picker"
                format="MM/dd/yyyy"
                className={classes.timePicker}
                value={Created}
                onChange={handlePickerChange}
                inputVariant="outlined"
                size="small"
              />

              <TimePicker
                clearable
                ampm={!format24}
                className={classes.timePicker}
                label="Time picker"
                value={Created}
                onChange={handlePickerChange}
                inputVariant="outlined"
                size="small"
              />

              <DateTimePicker
                autoOk
                ampm={!format24}
                className={classes.timePicker}
                value={Created}
                onChange={handlePickerChange}
                inputVariant="outlined"
                size="small"
                label="Date Time"
                format={getDateTimeFormat()}
              />
            </MuiPickersUtilsProvider>
            <div className={classes.actions}>
              <Button onClick={saveDate} variant="contained">
                Save
              </Button>
              <Button onClick={getDate} variant="contained">
                Get Current
              </Button>
            </div>
          </div>
          <div>
            <h5>Search From-To</h5>
            <p>
              Search pickers use agency time (not local time) to format date and time passed to the
              server. The <code>dateFrom</code> and <code>dateTo</code> contain dates that should be
              passed to the SQL query.
            </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date From"
                format="MM/dd/yyyy"
                className={classes.timePicker}
                value={dateFrom}
                onChange={setDateFrom}
                inputVariant="outlined"
                size="small"
              />
              <DatePicker
                label="Date To"
                format="MM/dd/yyyy"
                className={classes.timePicker}
                value={dateTo}
                onChange={setDateTo}
                inputVariant="outlined"
                size="small"
              />
            </MuiPickersUtilsProvider>
            <br />
            <span className={classes.label}>From: </span>
            {formatSaveDateFrom(dateFrom)}
            <br />
            <span className={classes.label}>To: </span>
            {formatSaveDateTo(dateTo)}
          </div>
          <div className={classes.actions}>
            <Button onClick={() => test('2021-12-21 03:00:0')} variant="contained">
              Test 1
            </Button>
            <Button onClick={() => test('2021-12-21 15:00:0')} variant="contained">
              Test 2
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    time: state.time,
  };
};

export default connect(mapStateToProps, {
  displayLocalTime,
  displayAgencyTime,
  doggleTimeFormat,
})(TimeTesting);
