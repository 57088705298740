import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NotesForm from './NotesForm';
import Card from '@material-ui/core/Card';
// import EditIcon from '@material-ui/icons/Edit';
// import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2, 2, 1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  note: {
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(4),
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    },
    '& svg': {
      fontSize: 20,
    },
    '& button': {
      width: 36,
      height: 36,
      marginLeft: 5,
    },
  },
}));

function NotesPanel(props) {
  const classes = useStyles();
  const { notes, dictionary, updateNewNotes, addNote } = props;
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    const edited = notes.findIndex((note) => note.Comment === '');
    if (edited !== -1) setEdited(edited);
    // eslint-disable-next-line
  }, [notes]);

  const renderEditedNote = (note, idx) => {
    return (
      <NotesForm
        dictionary={dictionary}
        key={idx}
        data={note}
        no={idx}
        title="Notes"
        updateData={updateNewNotes}
        add={addNote}
      />
    );
  };

  const renderCard = (note, idx) => {
    return (
      <Card className={classes.card} variant="outlined">
        <div className={classes.note} key={idx}>
          {note.Comment !== '' ? note.Comment : 'New note'}
        </div>
        {/* <div className={classes.actions}>
          <IconButton color="secondary" aria-label="edit" onClick={() => setEdited(idx)}>
            <EditIcon />
          </IconButton>
        </div> */}
      </Card>
    );
  };

  return (
    <>
      {notes.map((note, idx) => (
        <Fragment key={idx}>
          {idx === edited && renderEditedNote(note, idx)}
          {idx !== edited && renderCard(note, idx)}
        </Fragment>
      ))}
    </>
  );
}

export default NotesPanel;
