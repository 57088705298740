import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Query from './Query';

function QueriesSearch(props) {
  const { permissions } = props;
  const access = Boolean(permissions.globals['View NCIC Queries']);
  if (!access) return <Redirect to="/" />;
  return (
    <SearchMenu>
      <FilterConsumer>{(AgencyFilter) => <Query filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
});

export default connect(mapStateToProps)(QueriesSearch);
