import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UpdateIcon from '@material-ui/icons/Update';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import Dialog from '../components/Dialog';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { closeOutserviceUnitDialog, newUnitStatus } from '../reducers/DialogsReducer';
import { getOutserviceUnits, updateUnitStatusPromise } from '../reducers/UnitStatusReducer';
import { getUnitsAsync } from '../reducers/UnitsReducer';
import { asyncForEach, filterResults } from '../utils/functions';
import { notify } from '../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const columnsOutService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Resounces', headerName: 'Resounces', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

const columnsInService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Code', headerName: 'Status', width: 150 },
  { field: 'UnitResources', headerName: 'Resources', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

function AddOutserviceUnitDialog(props) {
  const classes = useStyles();
  const { UnitActionCodes } = props;
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filter, setFilter] = useState('');
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tabVal, setTabVal] = useState('outservice');

  useEffect(() => {
    setLoaded(false);
    const fn = tabVal === 'outservice' ? getOutserviceUnits : getUnitsAsync;
    fn()
      .then((result) => {
        setUnits(
          result.map((unit) => {
            return { ...unit, id: unit.ptsUnitID };
          })
        );
      })
      .catch((err) => props.handleError(err, 'Error, Units not loaded.'))
      .finally(() => setLoaded(true));
    // eslint-disable-next-line
  }, [tabVal]);

  useEffect(() => {
    if (!filter) {
      setFilteredUnits(units);
    } else {
      const columns = tabVal === 'outservice' ? columnsOutService : columnsInService;
      setFilteredUnits(filterResults(filter, units, columns));
    }
    // eslint-disable-next-line
  }, [filter, units]);

  const close = (openWindows) => {
    props.closeOutserviceUnitDialog(openWindows);
  };

  const handleSelectChange = (selection) => {
    setSelection(selection);
  };

  const apply = async () => {
    const UnitStatus =
      tabVal === 'outservice'
        ? UnitActionCodes.Inservice.status
        : UnitActionCodes.Outservice.status;
    let errors = false;
    props.showSpinner();
    await asyncForEach(selection, async (ptsUnitID) => {
      try {
        await updateUnitStatusPromise({ UnitStatus, ptsUnitID });
      } catch (err) {
        const unit = units.find((u) => u.ptsUnitID);
        const Unit = unit ? `to unit: ${unit.Unit}` : '';
        props.handleError(err, `${Unit} Problem with updating status ${Unit}`);
        errors = true;
      }
    });
    props.hideSpinner();
    !errors && close();
  };

  const newStatus = () => {
    const ptsUnitID = parseInt(selection[0]);
    props.newUnitStatus(ptsUnitID);
  };

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={newStatus}
            disabled={selection.length !== 1}>
            <UpdateIcon /> New Status
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={apply}
            disabled={!selection.length}>
            {tabVal === 'inservice' && (
              <>
                <SnoozeIcon /> Out Service
              </>
            )}
            {tabVal === 'outservice' && (
              <>
                <AddAlarmIcon /> In Service
              </>
            )}
          </Button>
          <Button color="primary" onClick={close}>
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  const handleTabChange = (ev, newValue) => {
    setTabVal(newValue);
  };

  const renderTabs = () => {
    return (
      <Tabs value={tabVal} onChange={handleTabChange} className={classes.tabs}>
        <Tab label="Out Of Service" value="outservice" />
        <Tab label="In Service" value="inservice" />
      </Tabs>
    );
  };

  const renderOutserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsOutService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  const renderInserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsInService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog onClose={close} title={'Add Unit'} actions={renderActions()}>
      {renderTabs()}
      {tabVal === 'outservice' && renderOutserviceUnits()}
      {tabVal === 'inservice' && renderInserviceUnits()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    UnitActionCodes: state.config.options.UnitActionCodes,
  };
};

export default connect(mapStateToProps, {
  closeOutserviceUnitDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  newUnitStatus,
})(AddOutserviceUnitDialog);
