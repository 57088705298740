import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import createRootReducer from '../reducers';

const isDevelopment = process.env.NODE_ENV === 'development';
const enableDevToolsOnProduction = process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let basename = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : '/';
export const history = createBrowserHistory({ basename });

const persistConfig = {
  key: 'root',
  whitelist: ['offline', 'theme', 'user', 'time'],
  storage,
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  persistAutoRehydrate: false,
});

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(createRootReducer(history))
);

let store;

if (isDevelopment || enableDevToolsOnProduction) {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      composeEnhancers(applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware(history)))
    )
  );
} else {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware(history))
    )
  );
}

const persistor = persistStore(store);
const config = { store, persistor };

export default config;
