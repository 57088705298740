import React from 'react';
import { connect } from 'react-redux';
import PagingDialog from './PagingDialog';
import AddOutserviceUnitDialog from './AddOutserviceUnitDialog';
import DialogUnitStatus from './DialogUnitStatus';
import AddEvent from './AddEvent';
import EditEvent from './EditEvent';
import AddSop from './AddSOP';
import GroupEdit from 'Settings/PagingSetup/GroupEdit';
import RecipientEdit from 'Settings/PagingSetup/RecipientEdit';
import TemplateEdit from 'Settings/PagingSetup/TemplateEdit';
import DispatchRecommendation from './DispatchRecommendation';
import UnitProps from './UnitProps';
import AddNote from './AddNote';
import UnitZone from './UnitZone';
import Settings from './Settings';
import DisplayData from './DisplayData';
import SOPs from './SOPs';
import AddBulletin from './AddBulletin';
import EditBulletin from './EditBulletin';
import Address from './Address';
import BulletinDetails from './BulletinDetails';
import ReportsDialog from './ReportsDialog';
import RotationsDialog from './RotationsDialog';
import AddVehicleDialog from './AddVehicleDialog';
import EditVehicleDialog from './EditVehicleDialog';
import TowingDialog from './TowingDialog';
import VehicleAddEditRegDialog from './EditVehicleDialog/VehicleAddEditRegDialog';
import AboutDialog from './AboutDialog';
import AddUnitDialog from './AddUnitDialog';
import EditUnitDialog from './EditUnitDialog';
import DictionaryDialog from './DictionaryDialog';
import TestingDialog from './TestingDialog';
import AddAttachment from './AddAttachment';
import EditAttachment from './EditAttachment';
import EditCredentialDialog from './EditCredentialDialog';
import PlaceEdit from './PlaceEdit';
import AddPersonDialog from './AddPersonDialog';
import EditNameDialog from './EditNameDialog';
import EditPersonDialog from './EditPersonDialog';
import ContactMethodDialog from './ContactMethodDialog';
import PeopleCredentialsDialog from './PeopleCredentialsDialog';
import PeopleEmploymentDialog from './PeopleEmploymentDialog.js';
import PlaceEmployeeDialog from './PlaceEmployeeDialog';
import WarrantDialog from './WarrantDialog';
import UnitResourceDialog from './UnitResourceDialog';

function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    addPage,
    addOutserviceUnit,
    addEvent,
    editEvent,
    editSop,
    editPageGroup,
    editPageRecipient,
    editPageTemplate,
    dispRecommendation,
    unitProps,
    addNote,
    newUnitStatus,
    unitZone,
    settings,
    displayData,
    sops,
    addBulletin,
    editBulletin,
    address,
    bulletinDetails,
    report,
    rotations,
    addVehicle,
    editVehicle,
    vehicleEditReg,
    towing,
    about,
    addUnit,
    editUnit,
    dictionary,
    testing,
    addAttachment,
    editAttachment,
    editCredential,
    editPlace,
    editName,
    editContactMethod,
    editPeopleCredentials,
    editPeopleEmployment,
    editPlaceEmployment,
    addPerson,
    editPerson,
    warrants,
    unitAddResource,
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {/* {Boolean(addPage) && <PagingDialog />}
          {addOutserviceUnit && <AddOutserviceUnitDialog />}
          {!!addEvent && <AddEvent data={addEvent} />}
          {Boolean(editEvent) && <EditEvent ptsEventID={editEvent} />}
          {Boolean(editSop) && <AddSop ptsSOPID={editSop} />}
          {editPageGroup !== null && <GroupEdit ptsGroupID={editPageGroup} />}
          {editPageRecipient !== null && <RecipientEdit ptsRecipientID={editPageRecipient} />}
          {editPageTemplate !== null && <TemplateEdit ROWGUID={editPageTemplate} />}
          {dispRecommendation !== null && (
            <DispatchRecommendation ptsEventID={dispRecommendation} />
          )}
          {unitProps !== null && <UnitProps ptsUnitID={unitProps} />}
          {addNote !== null && <AddNote ptsEventID={addNote} />}
          {newUnitStatus !== null && <DialogUnitStatus data={newUnitStatus} />}
          {unitZone !== null && <UnitZone ptsUnitID={unitZone} />} */}
          {settings !== null && <Settings />}
          {displayData !== null && (
            <DisplayData
              data={displayData.data}
              title={displayData.title}
              ptsAlertID={displayData.ptsAlertID}
            />
          )}
          {/* {sops !== null && <SOPs ptsEventID={sops} />}
          {addBulletin !== null && <AddBulletin />}
          {editBulletin !== null && <EditBulletin ptsAlertID={editBulletin} />}
          {address !== null && (
            <Address
              ptsAddressID={address.ptsAddressID}
              tab={address.tab}
              eventID={address.EventID}
            />
          )}
          {bulletinDetails !== null && <BulletinDetails data={bulletinDetails} />}
          {report !== null && <ReportsDialog report={report} />}
          {rotations !== null && <RotationsDialog data={rotations} />}
          {addVehicle !== null && <AddVehicleDialog data={addVehicle} />}
          {editVehicle !== null && <EditVehicleDialog ptsVehicleID={editVehicle} />}
          {vehicleEditReg !== null && <VehicleAddEditRegDialog vehicleData={vehicleEditReg} />}
          {towing !== null && <TowingDialog data={towing} />} */}
          {about !== null && <AboutDialog />}
          {/* {addUnit !== null && <AddUnitDialog />}
          {editUnit !== null && <EditUnitDialog data={editUnit} />}
          {dictionary !== null && <DictionaryDialog data={dictionary} />}
          {testing !== null && <TestingDialog />}
          {addAttachment !== null && <AddAttachment data={addAttachment} />}
          {editAttachment !== null && <EditAttachment data={editAttachment} />}
          {editCredential !== null && <EditCredentialDialog data={editCredential} />}
          {editPlace !== null && <PlaceEdit ptsPlaceID={editPlace} />}
          {addPerson !== null && <AddPersonDialog />}
          {editPerson !== null && <EditPersonDialog ptsPersonID={editPerson} />}
          {editName !== null && <EditNameDialog ptsNameID={editName} />}
          {editPlaceEmployment !== null && <PlaceEmployeeDialog data={editPlaceEmployment} />}
          {editContactMethod !== null && <ContactMethodDialog data={editContactMethod} />}
          {editPeopleCredentials !== null && (
            <PeopleCredentialsDialog data={editPeopleCredentials} />
          )}
          {editPeopleEmployment !== null && <PeopleEmploymentDialog ptsPersonID={editPeopleEmployment} />}
          {warrants !== null && <WarrantDialog data={warrants} />}
          {unitAddResource != null && <UnitResourceDialog ptsUnitID={unitAddResource} />} */}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialogs);
