import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeTestingDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TimeTesting from './TimeTesting';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PermissionsTesting from './PermissionsTesting';

const useStyles = makeStyles((theme) => ({
  content: {
    width: 1500,
    margin: -20,
    maxWidth: 'calc(100% + 40px)',
    height: 'calc(100vh - 50px)',
  },
}));

function TestingDialog(props) {
  const classes = useStyles();
  const [tab, setTab] = useState('permissions'); // time, permissions

  const close = () => {
    props.closeTestingDialog();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Dialog Title" actions={renderActions()}>
      <div className={classes.content}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Time" value="time" />
          <Tab label="Permissions" value="permissions" />
        </Tabs>
        {tab === 'time' && <TimeTesting />}
        {tab === 'permissions' && <PermissionsTesting />}
      </div>
    </Dialog>
  );
}

export default connect(null, { closeTestingDialog })(TestingDialog);
