import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Code from '../components/Code';
import TextField from '@material-ui/core/TextField'; //from '../pts-ui';

const useStyles = makeStyles(() => ({}));

let timeout = 0;

function PageDeveloper() {
  const classes = useStyles();
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={classes.pageWrap}>
      <Code source="design-specs" title="PTS-UI standards" />
      <div className={classes.buttons}>
        <Code source="text-field" title="TextField" />
        <TextField
          width="250px"
          label="This is label"
          tooltip="This is descriptive tooltip for this widget"
          placeholder="This is placeholder"
          value={textValue}
          onChange={(ev) => setTextValue(ev.target.value)}
          clearBtn
          labelAbove
        />
      </div>
    </div>
  );
}

export default connect(null, {})(PageDeveloper);
