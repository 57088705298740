import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8hazardwarningflasher100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M25.16 3.436a.998.998 0 0 0-.306.002.99.99 0 0 0-.72.5L.135 45.5a.991.991 0 0 0 .005 1.004c.18.309.512.5.87.496h48a.999.999 0 0 0 .872-.496.991.991 0 0 0 .004-1.004l-24-41.563a.998.998 0 0 0-.725-.501zm-.15 11.005L40.332 41H9.686L25.01 14.441zm0 9a.988.988 0 0 0-.864.498l-6.673 11.569A.995.995 0 0 0 18.336 37h13.348a.995.995 0 0 0 .863-1.492L25.87 23.939a.985.985 0 0 0-.861-.498z" />
    </SvgIcon>
  );
}

export default icons8hazardwarningflasher100Icon;
