import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { validateEmail } from '../utils/functions';

function EmailTextField(props) {
  const [Email, setEmail] = useState(props.value);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    props.isValid && props.isValid(validate(props.value));
    // eslint-disable-next-line
  }, []);

  const onEmailChange = (ev) => {
    const val = ev.target.value;
    setEmail(val);
    !touched && setTouched(true);
    props.isValid && props.isValid(validate(val));
    props.onChange && props.onChange(val);
  };

  const validate = (val) => validateEmail(val);

  return (
    <TextField
      error={!validate(Email) && touched}
      value={Email}
      onChange={onEmailChange}
      label={props.label}
      className={props.className}
      variant={props.variant}
      size={props.size || 'small'}
    />
  );
}

export default EmailTextField;
