import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { checkForUpdates, getApiCadVer, getApiVer } from '../reducers/AppInfoReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: 20,
  },
  table: {
    '& td': {
      padding: '2px 10px',
    },
  },
}));

function PageDebugInfo(props) {
  const classes = useStyles();
  const {
    apiVersion,
    compilationNo,
    apiCadVersion,
    availBuildNo,
    appVersion,
    availBuildTime,
    buildNo,
    buildTime,
  } = props.appInfo;

  useEffect(() => {
    props.getApiVer();
    props.getApiCadVer();
    props.checkForUpdates();
    // eslint-disable-next-line
  }, [props.websocket]);

  return (
    <div className={classes.wrap}>
      <h3>Debug info</h3>
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>App version:</th>
            <td>{appVersion}</td>
          </tr>
          <tr>
            <th>API version:</th>
            <td>{apiVersion}</td>
          </tr>
          <tr>
            <th>API CAD module version:</th>
            <td>{apiCadVersion}</td>
          </tr>
          <tr>
            <th>Compilation no:</th>
            <td>{compilationNo}</td>
          </tr>
          <tr>
            <th>Build no:</th>
            <td>{buildNo}</td>
          </tr>
          <tr>
            <th>Available build no:</th>
            <td>{availBuildNo}</td>
          </tr>
          <tr>
            <th>Build time:</th>
            <td>{buildTime}</td>
          </tr>
          <tr>
            <th>Available build time:</th>
            <td>{availBuildTime}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    appInfo: state.appInfo,
    websocket: state.websocket,
  };
};

export default connect(mapStateToProps, {
  checkForUpdates,
  getApiCadVer,
  getApiVer,
})(PageDebugInfo);
