import React from 'react';
import store from 'config/configureStore';
import BulletinNotification from 'components/BulletinNotification';
import { showBulletinDetails } from './DialogsReducer';
import { notifyPanel } from './NotifierReducer';
import { getService } from './service';
import { handleError } from './ErrorReducer';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';

const UNREAD_USER_BULLETINS = 'UNREAD_USER_BULLETINS';
const MARK_BULLETIN_AS_READ = 'MARK_BULLETIN_AS_READ';
const MARK_ALL_BULLETINS_AS_READ = 'MARK_ALL_BULLETINS_AS_READ';
const ADD_NEW_BULLETIN = 'ADD_NEW_BULLETIN';

let bulletinService = false;

export const subscribeBulletins = () => {
  const state = store.store.getState();
  const authenticated = state.user.isAuthenticated;
  const client = state.websocket.websocket;
  return async (dispatch) => {
    if (!client || !authenticated) return;
    try {
      bulletinService = client.service('alerts');
      bulletinService.on('created', (bulletin) => {
        const notification = {
          position: 'tr',
          autoDismiss: 0,
          action: { label: 'Show details' },
        };
        notification.title = getBulletinTitle(bulletin, 'New Bulletin created');
        notification.message = <BulletinNotification data={bulletin} />;
        notification.action.callback = () => dispatch(showBulletinDetails(bulletin));
        dispatch(notifyPanel(notification, 'warning'));
        dispatch({ type: ADD_NEW_BULLETIN, payload: bulletin });
      });
      bulletinService.on('unhandledRejection', (reason, p) => {
        console.log('Alert Subscribtion Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribeBulletins = () => {
  if (bulletinService) {
    try {
      bulletinService.off('created');
      bulletinService.off('unhandledRejection');
      bulletinService = false;
    } catch (error) {
      console.log('EventsReducer/unsubscribeEvents: error: ', error, error.code);
    }
  }
  return () => {};
};

export const bulletinFound = (data) => {
  return async (dispatch) => {
    try {
      const notification = {
        position: 'tr',
        autoDismiss: 0,
        action: { label: 'Go to the Bulletin!' },
      };
      notification.title = getBulletinTitle(data, 'Bulletin match found!');
      notification.message = <BulletinNotification data={data} />;
      notification.action.callback = () => {
        dispatch(showBulletinDetails(data));
      };
      dispatch(notifyPanel(notification, 'error'));
    } catch (error) {
      dispatch(handleError(error, 'Error, no bulletin found'));
    }
  };
};

export const markBulletinRead = (ptsAlertID) => {
  return async (dispatch) => {
    const service = getService('cad-user-alerts-read');
    const data = { ptsAlertID };
    try {
      await service.create(data);
      if (ptsAlertID === 'all') {
        dispatch({ type: MARK_ALL_BULLETINS_AS_READ });
      } else {
        dispatch({ type: MARK_BULLETIN_AS_READ, payload: ptsAlertID });
      }
    } catch (err) {
      dispatch(handleError(err, 'Error marking bulletin as read'));
    }
  };
};

export const findUnreadUserBulletins = () => {
  return async (dispatch) => {
    try {
      const service = getService('cad-user-alerts-read');
      const data = await service.find();
      dispatch({ type: UNREAD_USER_BULLETINS, payload: data });
    } catch (err) {
      dispatch(handleError(err, 'Error loading unread bulletins'));
    }
  };
};

function getBulletinTitle(bulletin, defaultTitle) {
  const { AgencyID, AlertType } = bulletin;
  let title = defaultTitle;
  if (AlertType) title = AlertType;
  if (AgencyID) {
    if (AlertType) title += ' - ';
    title += AgencyID;
  }
  return title;
}

export const findBulletins = (data) => async (dispatch) => {
  const service = getService('cad-alerts');
  const result = await service.find({ query: data });
  if (result.length) {
    result.forEach((bulletin) => dispatch(bulletinFound(bulletin)));
  }
};

export const searchBulletins = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-alerts');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const getBulletin = (id) => {
  const service = getService('cad-search-alerts');
  return service.get(id);
};

export const addAlert = (data) => {
  const service = getService('cad-search-alerts');
  return service.create(data);
};

export const editAlert = (id, data) => {
  const service = getService('cad-search-alerts');
  return service.update(id, data);
};

// ======================= REDUCER =======================
export default function reducer(state = [], action) {
  switch (action.type) {
    case UNREAD_USER_BULLETINS:
      return action.payload;
    case MARK_BULLETIN_AS_READ:
      return state.filter((bulletin) => bulletin.ptsAlertID !== action.payload);
    case ADD_NEW_BULLETIN:
      return [action.payload, ...state];
    case MARK_ALL_BULLETINS_AS_READ:
      return [];
    default:
      return state;
  }
}
