import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchEmployees } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import CheckIcon from '@material-ui/icons/Check';

import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 8,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PlaceName', headerName: 'PlaceName', width: 150, hide: false },
  { field: 'FullName', headerName: 'FullName', width: 150, hide: false },
  { field: 'Supervisor', headerName: 'Supervisor', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Employee(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [employeeColumns, setEmployeeColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'Employees');

  useEffect(() => {
    getEmployeeData();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getEmployeeData();
        break;
      case 'Ctrl+KeyP':
        viewReport();
        break;
    }
  };

  const getEmployeeData = async () => {
    setLoaded(false);
    searchEmployees()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewBadge = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    const { ptsPersonID, ptsPlaceID, Username } = item;
    props.showReport({ id: 136, data: { ptsPersonID, ptsPlaceID, Username }, title: 'Badge' });
  };

  const viewReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    const { ptsPersonID, ptsPlaceID } = item;
    props.showReport({ id: 120, data: { ptsPersonID, ptsPlaceID }, title: 'Employee Details' });
  };

  const showDetailsMenu = (ev) => {
    setViewMenuAnchor(ev.currentTarget);
  };

  const closeDetailsMenu = () => {
    setViewMenuAnchor(null);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Employees</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={employeeColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Employee Records"
            data={filteredRows}
            cols={employeeColumns}
            kbdKey={kbdKey}
          />
          <Tooltip title="Veiw details">
            <span>
              <Fab
                onClick={showDetailsMenu}
                size="small"
                color="secondary"
                disabled={typeof selection !== 'number'}>
                <InfoIcon />
              </Fab>
            </span>
          </Tooltip>
          <Menu
            anchorEl={viewMenuAnchor}
            keepMounted
            open={Boolean(viewMenuAnchor)}
            onClose={closeDetailsMenu}>
            <MenuItem onClick={viewBadge}>Badge</MenuItem>
            <MenuItem onClick={viewReport}>Selected</MenuItem>
          </Menu>
        </div>
      </div>
      <XGrid3
        name="employees"
        columns={employeeColumns}
        setColumns={setEmployeeColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
})(Employee);
