import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dictionary from 'components/Dictionary';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: 600,
    maxWidth: '100%',
    margin: '0 auto',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  addressFilter: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
}));

function ZoneEdit(props) {
  const classes = useStyles();
  const { dictionary, data, update } = props;
  const [Agencies, setAgencies] = useState([]);
  const [ZoneCode, setZoneCode] = useState('');
  const [Zone1, setZone1] = useState('');
  const [Description, setDescription] = useState('');
  const [Agency, setAgency] = useState(null);
  const [Flags, setFlags] = useState(true);

  useEffect(() => {
    if (!data) return;
    setZoneCode(data.ZoneCode || '');
    setZone1(data.Zone1 || '');
    setDescription(data.Description || '');
    const Agenceis = dictionary.Agencies.map((agency) => {
      return {
        Code: agency.AgencyID,
        Description: agency.Description,
      };
    });
    setAgencies(Agenceis);
    const Agency = Agenceis.find((agency) => agency.Code === data.AgencyID) || null;
    setAgency(Agency);
    setFlags(data.Flags ? true : false);
    // eslint-disable-next-line
  }, [data, dictionary]);

  useEffect(() => {
    if (!dataValid()) return update(null);
    update({
      ...data,
      ZoneCode,
      Zone1,
      Description,
      AgencyID: Agency ? Agency.Code : null,
      Flags,
    });
    // eslint-disable-next-line
  }, [ZoneCode, Description, Agency, Flags, Zone1]);

  const handleZoneCodeChange = (ev) => {
    const value = ev.target.value;
    if (value.length > 20) return;
    setZoneCode(value);
  };

  const handleZone1Change = (ev) => {
    const value = ev.target.value;
    if (value.length > 10) return;
    setZone1(value);
  };

  const handleZoneDescriptionChange = (ev) => {
    const value = ev.target.value;
    if (value.length > 100) return;
    setDescription(value);
  };

  const dataValid = () => {
    return ZoneCode && Agency;
  };

  return (
    <Card className={classes.card}>
      <div className={classes.form}>
        <TextField
          className={classes.w100x200}
          label="Zone Code*"
          value={ZoneCode}
          onChange={handleZoneCodeChange}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100x200}
          label="Zone"
          value={Zone1}
          onChange={handleZone1Change}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w200x300}
          label="Description"
          value={Description}
          onChange={handleZoneDescriptionChange}
          variant="outlined"
          size="small"
        />
        <Dictionary
          options="Agencies"
          className={classes.w200x300}
          onChange={(val) => setAgency(val)}
          value={Agency}
          label="Agency ID*"
        />
        <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={
            <Checkbox
              checked={Flags}
              onChange={(ev) => setFlags(ev.target.checked)}
              className={classes.checkbox}
            />
          }
          label="Attach to event"
        />
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps)(ZoneEdit);
