import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function JuvenileProblemAbuseNeglectIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M329.9 337.2c-3.6 7.2-6.5 13.2-9.4 19.1-6.4 13.1-5.5 19.6 4.5 30.1 16.7 17.5 33.4 35 50.3 52.3 10.7 10.9 23.6 9.9 31.1-2.1 4.6-7.4 3.9-17-2.7-24.1-13-14.1-26.3-27.9-39.7-41.5-3.9-4-4.9-6.9-2-12.3 10.3-19.7 19.8-39.8 29.8-59.7 3.9-7.7 4.8-14.9 2.3-23.8-8.1-29.3-15.1-58.8-22.4-88.4-.6-2.3-.4-5.6.8-7.4 16.1-24 32.4-47.9 49-72.4 6 3 11.8 6.2 17.8 8.6 2.1.8 5-.3 7.5-.5-1.3-2.4-2-5.8-4-7.1-4.9-3.2-10.5-5.4-16.1-8.2.3-2.3 1.5-5.1.7-6.9-1.8-3.8-4-8.8-7.2-10.2-3.2-1.4-9.6-.4-11.7 2.1-3.5 4.3-5.8 3.1-9.4 1.3-26.4-13.2-52.9-26.2-79.3-39.3-1.9-.9-3.8-2.4-5.7-2.7-2.2-.2-4.5.7-6.8 1.1 1.1 2.3 2 4.8 3.5 6.7 1.1 1.4 3.2 1.8 4.9 2.7l76.8 38.1c2.1 1 4.2 2.1 6.8 3.5-10.2 15-20.3 29.6-30.1 44.3-8.7 13.2-17.3 26.5-25.6 40-11.7 19-35.7 24.5-54.3 11.7-3.9-2.7-6.2-2.2-9.8.1-23.2 15.3-37.9 38-50.2 62.7-3.2 6.5-6.1 13.2-8.7 20-2.8 7.1-.3 13.9 5.6 16.8 6.2 3 12.8.3 16.4-6.7 7.5-14.6 14.1-29.8 22.9-43.5 6.8-10.5 16.3-19.1 24.6-28.6.8.4 1.6.7 2.4 1.1 2.4 9.3 4.7 18.6 7.1 27.8 4 15.7 8 31.5 12.3 47.2 1.2 4.5.5 7.5-2.7 11-13.9 14.9-27.4 30.1-41.2 45.2-6 6.6-8.7 13.7-7 23.2 3.9 22.5 7 45.2 10.3 67.9 1.8 12.3 10.4 20 21.1 18.8 11.2-1.3 18.4-10.9 16.7-23.6-2.6-19.5-5.9-39-8.7-58.5-.3-2.1-.2-5.2 1.1-6.6 8.1-9.4 16.6-18.6 25-27.8.2-.7 1-.7 3.4-1.5zm-213.7-.1c-11.9 11.6-15.6 34.7 4.7 42.6 1.4.5 2.8 3.1 2.8 4.8.3 10.7.1 21.4.1 32.7-3.3 1.3-6.5 2.5-9.6 4-9.5 4.6-10.9 15.8-2.1 21.4 13.6 8.6 27.7 16.4 41.7 24.2 5.6 3.1 12 .7 14.7-4.4 2.8-5.5 1.5-11.4-3.6-15.5-1.8-1.4-3.7-2.7-5.3-5.6 2.9.5 6 .6 8.7 1.7 10.3 4.1 20.4 8.5 30.7 12.7 7.4 3 13.8.6 16.3-6.1 2.4-6.2-.6-12.5-7.8-15.7-9-4-18.2-7.9-27.4-11.3-4.3-1.6-6.8-3.7-6-8.9.4-2.4.3-4.9 0-7.3-1.2-12.5 1.3-23.3 9-33.9 4.7-6.5 6.8-16 7.8-24.4 1.1-9.1-4.6-15.8-13.3-18.1-4.7-1.2-9.7-1.5-14.6-1.6-5-.2-8.7 3.3-8 8 .4 3 4.7 5.5 7.4 8.1.3.3.9.1 1.4.1 13.4 1.1 14.2 2.5 8.6 15s-18 18.2-31.7 13.8c-15.1-4.8-22-13.5-24.1-29.7-.2-2-.3-4.1-.4-6.6z" />
      <path d="M276.5 159.5c.2 19.7 15.9 35.8 34.7 35.4 18.9-.3 34.2-16.7 34-36.3-.2-19.8-15.7-35.4-34.8-35.2-19.2.1-34.1 16-33.9 36.1zM172 349c-3.3-.4-6.5-1.1-9.7-1.3-8-.4-10.4-2.5-11-10-.6-6.7 3.1-11.8 9.6-13.1 2-.4 4.1-.4 6.3-.6-6.3-9.1-17.8-12.1-28.2-7.4-10.7 4.8-17.3 17-15.2 28.5 2.3 12.8 12.1 21.6 24.5 22 11.4.5 21-6.7 23.7-18.1zM276.7 98c2 4.4 3.4 7.4 5.1 11.2 3.5-3.4 6.5-6.3 10.3-9.9v11.8c.8.1 1.5.2 2.3.3.9-6.7 1.7-13.3 2.6-20-.6-.3-1.2-.6-1.8-.8-3.5 4.4-6.9 8.7-11.3 14.3-2.7-6.1-4.3-9.9-6.8-15.5-2.7 5.9-4.7 10.3-7.1 15.7-5-4.7-9.7-9.3-14.5-13.8-.5.3-1.1.6-1.6.9.8 6.3 1.5 12.7 2.4 20-6.1-1-11.9-1.9-19.8-3.1 4.9 6.8 8.5 11.8 12.7 17.6-5.8 1.7-10.9 3.2-16.1 4.7-.1.7-.3 1.5-.4 2.2 6.3 3 12.7 6 19.4 9.3-2.7 4.1-5 7.6-7.3 11.1.3.6.5 1.3.8 1.9 5.4-.8 10.8-1.5 16.2-2.3 0-.7-.1-1.5-.1-2.2h-10.3c2-3.5 3.7-6.4 5.6-9.8-5.3-2.7-10.3-5.2-16.4-8.3 5.2-1.6 9.2-2.9 14.2-4.4-3.3-4.8-6.2-8.9-10-14.4 5.6.8 10 1.5 15.3 2.3-.5-5.7-1-10.7-1.7-17.5 4.8 4.6 8.6 8.1 13.1 12.4 1.7-5 3.2-8.7 5.2-13.7zM112.9 327.3c-5.1.7-9.3.5-12.7 2.2-2.3 1.2-4.1 4.8-4.6 7.7-.3 1.7 3 5.8 3.7 5.5 2.5-.8 5.1-2.6 6.9-4.8 2.3-2.7 3.9-6 6.7-10.6zm15.8-23.3c-3.8-3-7.5-6.4-11.6-8.9-1.3-.8-4 1-6 1.7.7 2.2.9 5.6 2.3 6.3 4.2 2.1 8.9 3.1 13.4 4.5.6-1.2 1.3-2.4 1.9-3.6zm-23.3 8c-2.9-1.4-5.8-3.4-8.8-3.9-1.7-.2-3.7 2.4-5.6 3.7 2 1.6 3.8 4.3 5.9 4.6 2.4.4 5.2-1.3 7.8-2.1.2-.7.4-1.5.7-2.3z" />
    </SvgIcon>
  );
}

export default JuvenileProblemAbuseNeglectIcon;
