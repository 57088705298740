import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8complaint100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M40 0c-5.514 0-10 4.486-10 10a9.9 9.9 0 0 0 1.738 5.62l-1.687 5.064A.998.998 0 0 0 31 22c.184 0 .366-.05.53-.152l4.368-2.73A9.867 9.867 0 0 0 40 20c5.514 0 10-4.486 10-10S45.514 0 40 0zm-.8 5h1.6c.1 0 .2.1.2.2v5.6c0 .1-.1.2-.2.2h-1.6c-.1 0-.2-.1-.2-.2V5.2c0-.1.1-.2.2-.2zM18.394 6.986c-3.415.062-5.912 1.107-7.424 3.102-1.771 2.338-2.104 5.86-.992 10.478-.412.52-.712 1.306-.59 2.325.243 2 1.04 2.843 1.697 3.197.297 1.523 1.064 3.346 1.904 4.238.001.47.003.902.006 1.344.004.558.009 1.13.01 1.812-.58 1.22-2.492 1.959-4.512 2.74C4.89 37.619.406 39.355.068 44.943l-.064 1.06h36.988l-.062-1.06c-.337-5.588-4.822-7.346-8.426-8.76-2.017-.791-3.929-1.542-4.512-2.76a272.9 272.9 0 0 0 .016-3.158c.839-.884 1.605-2.678 1.902-4.176.658-.353 1.455-1.195 1.697-3.195.122-1-.162-1.773-.562-2.293.54-1.846 1.608-6.501-.262-9.514-.797-1.284-2.002-2.094-3.588-2.412-.888-1.093-2.561-1.688-4.8-1.688zM39.199 13h1.602c.1 0 .199.1.199.2v1.6c0 .1-.1.2-.2.2h-1.6c-.1 0-.2-.1-.2-.2v-1.6c0-.1.1-.2.2-.2z" />
    </SvgIcon>
  );
}

export default icons8complaint100Icon;
