import React from 'react';
import SearchPanel from './SearchPanel';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';

const infoWidth = 230;

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    maxHeight: 250,
  },
  listItem: {
    borderBottom: `1px solid ${theme.colors.grey1}`,
    cursor: 'pointer',
  },
  locationInfo: {
    width: '100%',
    maxHeight: 44,
    lineHeight: '1.3em',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    fontSize: 12,
    marginTop: 0,
  },
  info: {
    width: infoWidth,
    flex: `0 0 ${infoWidth}px`,
    marginRight: 10,
  },
}));

function EventDuplicates(props) {
  const classes = useStyles();
  const { data, setLocation } = props;

  return (
    <SearchPanel title="Address Duplicates">
      <List className={classes.list}>
        {data.map((addr) => (
          <ListItem
            className={classes.listItem}
            key={addr.ptsAddressID}
            onClick={() => setLocation({ ...addr, IsPrimary: true })}>
            {addr.FullAddressText}
          </ListItem>
        ))}
      </List>
    </SearchPanel>
  );
}

export default EventDuplicates;
