import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete2 from './Autocomplete2';
import { getAgenciesAccessPermission } from 'reducers/PermissionsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getService } from 'reducers/service';
import { setCurrentAgency, setAgencies } from 'reducers/VfcReducer';

const useStyles = makeStyles((theme) => ({
  agency: {
    minWidth: 200,
    width: 250,
    marginTop: 4,
    background: theme.colors.grey4,
    borderRadius: 4,
  },
}));

function AgencySelect() {
  const classes = useStyles();
  const Agencies = useSelector((state) => state.vfc.Agencies);
  const Agency = useSelector((state) => state.vfc.Agency);
  const User = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Agencies.length) return;
    getAgencies();
  }, []);

  const getAgencies = async () => {
    // Get all available agencies
    const service = getService('archive-browser');
    const agencies = await service.find({
      query: { type: 'agencies' },
    });

    // Show only agencies you have permission to access
    const accessAgencies = getAgenciesAccessPermission('modules', 'Incident Archive');
    const filteredAgencies = [];
    agencies.forEach((Agency) => {
      if (accessAgencies.indexOf(Agency.code) !== -1) {
        filteredAgencies.push(Agency);
      }
    });
    dispatch(setAgencies(filteredAgencies));

    // Select default agency
    const AgencyID = User.userData.user.AgencyID;
    if (AgencyID) {
      const Agency = filteredAgencies.find((a) => a.code === AgencyID);
      if (Agency) dispatch(setCurrentAgency(Agency));
    }
  };

  const onChange = (ev, val) => dispatch(setCurrentAgency(val));

  return (
    <Autocomplete2
      options={Agencies}
      onChange={onChange}
      value={Agency}
      className={classes.agency}
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionLabel={(option) => (option.code ? option.Description : '')}
      renderOption={(option) => <span>{option.Description}</span>}
      compact
      label="Agency"
    />
  );
}

export default AgencySelect;
