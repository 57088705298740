import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../components/Dialog';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAddNoteDialog } from '../reducers/DialogsReducer';
import { addEventNote, getEventDetails } from '../reducers/EventsReducer';

const useStyles = makeStyles((theme) => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));

function AddNote(props) {
  const classes = useStyles();
  const { ptsEventID } = props;
  const [text, setText] = useState('');
  const [event, setEvent] = useState({});
  const textFieldRef = useRef(null);

  useEffect(() => {
    setTimeout(() => textFieldRef.current.focus(), 0);
    getDetails();
    // eslint-disable-next-line
  }, []);

  const getDetails = async () => {
    props.showSpinner();
    try {
      const event = await getEventDetails(ptsEventID);
      setEvent(event);
    } catch (err) {
      props.handleError(err, '|Error, Unable to retrieve event details');
    }
    props.hideSpinner();
  };

  const close = () => {
    props.closeAddNoteDialog();
  };

  const save = async () => {
    props.showSpinner();
    try {
      await addEventNote(text, ptsEventID);
    } catch (err) {
      props.handleError(err, 'Error, note not saved.');
    }
    props.hideSpinner();
    close();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add Note'}
      actions={renderActions()}
      className={classes.dialog}
      // toolbar={false}
      // draggable={false}
      // fullScreen
    >
      <h5>Event ID: {event.EventID}</h5>
      <p>Location: {event.FullAddressText}</p>
      <TextField
        id="outlined-multiline-static"
        label="New note"
        multiline
        rows={6}
        variant="outlined"
        inputRef={textFieldRef}
        className={classes.textField}
        value={text}
        onChange={(ev) => setText(ev.target.value)}
      />
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAddNoteDialog,
})(AddNote);
