import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField2 from 'components/TextField2';
import formStyles, {
  formatSaveData,
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
} from 'utils/formStyles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  dateTimePicker,
  formatSaveDate,
  getDateTimeFormat,
  formatSaveDateFrom,
  formatSaveDateTo,
  displayDateTime,
  displayDate,
} from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { findBookings, getBookingsSelectItems, getBookingDetails } from 'reducers/VfcReducer';
import { handleError } from 'reducers/ErrorReducer';
import { XGrid } from '@material-ui/x-grid';
import Grid from '@material-ui/core/Grid';
import Report from './BookingReport';
import PageviewIcon from '@material-ui/icons/Pageview';
import PrintSearch from '../../components/PrintsSearch';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Autocomplete2 from '../../components/Autocomplete2';
import Typography from '@material-ui/core/Typography';
import AlertDialog from '../../Dialogs/AlertDialog';
import { getAgenciesAccessPermission } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  addressCategory: gridStyle(220, '100%'),
  item: gridStyle(170, '100%'),
  timePicker: gridStyle(200, '100%'),
  btn: {
    marginLeft: 8,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    marginTop: 8,
    textAlign: 'right',
    marginBottom: 16,
  },
  content: {
    padding: theme.spacing(2),
  },
  xgrid: {
    width: '100%',
    height: 500,
  },
  label: {
    //variant: "h3",
    fontSize: 14,
  },
  autocomplete: {
    margin: '0 0px 0px',
  },
}));

const defaultColumns = [
  { field: 'Number', headerName: 'Booking #', width: 150 },
  {
    field: 'Booked',
    headerName: 'Booked Date/Time',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Arrest',
    headerName: 'Arrest Date/Time',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Release',
    headerName: 'Release Date/Time',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'Name', headerName: 'Booked Person', width: 150 },
  { field: 'AliasName', headerName: 'Alias Name', width: 150 },
  { field: 'Race', headerName: 'Race', width: 150 },
  { field: 'Sex', headerName: 'Sex', width: 150 },
  {
    field: 'DOB',
    headerName: 'DOB',
    width: 120,
    valueFormatter: (params) => displayDate(params.value), //displayDateTime(params.value),
    format: 'date',
  },
  { field: 'IDNumber', headerName: 'ID Number', width: 150 },
  { field: 'IDState', headerName: 'ID State', width: 150 },
  { field: 'SSN', headerName: 'SSN', width: 150 },
  { field: 'FBINumber', headerName: 'FBI Number', width: 150 },
  { field: 'SIDNumber', headerName: 'SID Number', width: 150 },
  { field: 'TrackingNumber', headerName: 'Tracking Number', width: 150 },

  { field: 'Offense', headerName: 'Offense', width: 150 },
  { field: 'ArrestLocation', headerName: 'Arrest Location', width: 150 },
  { field: 'ArrestCity', headerName: 'Arrest City', width: 150 },
  { field: 'ArrestingOfficer', headerName: 'Arresting Officer', width: 150 },
  { field: 'ArrestingAgency', headerName: 'Arresting Agency', width: 120 },
  { field: 'BookingOfficer', headerName: 'Booking Officer', width: 120 },
  { field: 'ReleasingOfficer', headerName: 'Releasing Officer', width: 150 },
  { field: 'Disposition', headerName: 'Disposition', width: 150 },
  { field: 'AgencyID', headerName: 'Agency', width: 150 },
];

function Booking(props) {
  const classes = useStyles();
  const bookingsSelectItems = props.bookingsSelectItems;
  const bookingDetails = props.bookingDetails;
  const webSocket = props.webSocket;

  const [BookedFrom, setBookedFrom] = useState(null);
  const [BookedTo, setBookedTo] = useState(null);
  const [ArrestFrom, setArrestFrom] = useState(null);
  const [ArrestTo, setArrestTo] = useState(null);
  const [ReleaseFrom, setReleaseFrom] = useState(null);
  const [ReleaseTo, setReleaseTo] = useState(null);
  const [NumberVal, setNumberVal] = useState(null);
  const [Name, setName] = useState(null);
  const [AliasName, setAliasName] = useState(null);
  const [Race, setRace] = useState(null);
  const [Sex, setSex] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [IDNumber, setIDNumber] = useState(null);
  const [IDState, setIDState] = useState(null);
  const [SSN, setSSN] = useState(null);
  const [FBINumber, setFBINumber] = useState(null);
  const [SIDNumber, setSIDNumber] = useState(null);
  const [TrackingNumber, setTrackingNumber] = useState(null);
  const [Offense, setOffense] = useState(null);
  const [ArrestLocation, setArrestLocation] = useState(null);
  const [ArrestCity, setArrestCity] = useState(null);
  const [ArrestingOfficer, setArrestingOfficer] = useState(null);
  const [ArrestingAgency, setArrestingAgency] = useState(null);
  const [BookingOfficer, setBookingOfficer] = useState(null);
  const [ReleasingOfficer, setReleasingOfficer] = useState(null);
  const [Disposition, setDisposition] = useState(null);
  const [selection, setSelection] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [columns, setColumns] = useState(defaultColumns);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);

  const accessAgencies = getAgenciesAccessPermission('modules', 'Incident Archive');

  useEffect(() => {
    if (bookingDetails && selection) setShowDetails(true);
  }, [bookingDetails]);

  useEffect(() => {
    if (searchComplete) handleSearch();
  }, [props.Agency]);

  useEffect(async () => {
    if (webSocket) {
      props.getBookingsSelectItems();
    }
  }, [webSocket]);

  const closeDetails = () => {
    setShowDetails(false);
  };

  const handleClear = () => {
    setBookedFrom(null);
    setBookedTo(null);
    setArrestFrom(null);
    setArrestTo(null);
    setReleaseFrom(null);
    setReleaseTo(null);
    setNumberVal(null);
    setName(null);
    setAliasName(null);
    setRace(null);
    setSex(null);
    setDOB(null);
    setIDNumber(null);
    setIDState(null);
    setSSN(null);
    setFBINumber(null);
    setSIDNumber(null);
    setTrackingNumber(null);
    setOffense(null);
    setArrestLocation(null);
    setArrestCity(null);
    setArrestingOfficer(null);
    setArrestingAgency(null);
    setBookingOfficer(null);
    setReleasingOfficer(null);
    setDisposition(null);

    setRows([]);
  };

  const handleSearch = async () => {
    const _Race = Race ? Race.Description : null;
    const _Sex = Sex ? Sex.Description : null;
    const _IDState = IDState ? IDState.Description : null;
    const _ArrestCity = ArrestCity ? ArrestCity.Description : null;
    const _ArrestingOfficer = ArrestingOfficer ? ArrestingOfficer.Description : null;
    const _ArrestingAgency = ArrestingAgency ? ArrestingAgency.Description : null;
    const _BookingOfficer = BookingOfficer ? BookingOfficer.Description : null;
    const _ReleasingOfficer = ReleasingOfficer ? ReleasingOfficer.Description : null;
    const _Disposition = Disposition ? Disposition.Description : null;

    if (
      bookingsSelectItems == null ||
      Object.keys(bookingsSelectItems).length === 0 ||
      bookingsSelectItems.Disposition == null
    ) {
      if (dialogOpen) {
        handleClose();
        return;
      }
      setLoadingOpen(true);
      return;
    }

    if (
      BookedFrom == null &&
      BookedTo == null &&
      ArrestFrom == null &&
      ArrestTo == null &&
      ReleaseFrom == null &&
      ReleaseTo == null &&
      NumberVal == null &&
      Name == null &&
      AliasName == null &&
      Race == null &&
      Sex == null &&
      DOB == null &&
      IDNumber == null &&
      IDState == null &&
      SSN == null &&
      FBINumber == null &&
      SIDNumber == null &&
      TrackingNumber == null &&
      Offense == null &&
      ArrestLocation == null &&
      ArrestCity == null &&
      ArrestingOfficer == null &&
      ArrestingAgency == null &&
      BookingOfficer == null &&
      ReleasingOfficer == null &&
      Disposition == null
    ) {
      if (loadingOpen) {
        loadingClose();
        return;
      }
      setDialogOpen(true);
      return;
    }
    const data = formatSaveData({
      BookedFrom: formatSaveDateFrom(BookedFrom),
      BookedTo: formatSaveDateTo(BookedTo),
      ArrestFrom: formatSaveDateFrom(ArrestFrom),
      ArrestTo: formatSaveDateTo(ArrestTo),
      ReleaseFrom: formatSaveDateFrom(ReleaseFrom),
      ReleaseTo: formatSaveDateTo(ReleaseTo),
      NumberVal,
      Name,
      AliasName,
      Race: _Race,
      Sex: _Sex,
      DOB: formatSaveDateFrom(DOB),
      IDNumber,
      IDState: _IDState,
      SSN,
      FBINumber,
      SIDNumber,
      TrackingNumber,
      Offense, //: _Offense,
      ArrestLocation,
      ArrestCity: _ArrestCity,
      ArrestingOfficer: _ArrestingOfficer,
      ArrestingAgency: _ArrestingAgency,
      BookingOfficer: _BookingOfficer,
      ReleasingOfficer: _ReleasingOfficer,
      Disposition: _Disposition,
      AgencyID: props.Agency ? props.Agency.code : null,
    });
    data.Agencies = accessAgencies;

    // console.log('============Booking data=============:', data);

    setLoaded(false);
    findBookings(data)
      .then(setRows)
      .catch(props.handleError)
      .finally(() => {
        setLoaded(true);
        setSearchComplete(true);
      });
  };

  const handleSelectChange = (selection) => {
    setSelection(selection[0]);
  };

  const handleViewDetails = () => {
    const item = rows.find((r) => r.id === selection);
    //setDetails(item);
    props.getBookingDetails(item.vfcMainId);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const loadingClose = () => {
    setLoadingOpen(false);
  };

  const renderBookedFrom = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Booked:</Typography>
          <KeyboardDatePicker
            autoOk
            value={BookedFrom}
            onChange={setBookedFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderBookedTo = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <KeyboardDatePicker
            autoOk
            value={BookedTo}
            onChange={setBookedTo}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderArrestFrom = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Arrest:</Typography>
          <KeyboardDatePicker
            autoOk
            value={ArrestFrom}
            onChange={setArrestFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderArrestTo = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <KeyboardDatePicker
            autoOk
            value={ArrestTo}
            onChange={setArrestTo}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderReleaseFrom = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Release:</Typography>
          <KeyboardDatePicker
            autoOk
            value={ReleaseFrom}
            onChange={setReleaseFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderReleaseTo = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <KeyboardDatePicker
            autoOk
            value={ReleaseTo}
            onChange={setReleaseTo}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderNumberVal = () => {
    const onChange = (ev, val) => setNumberVal(val);
    return (
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Booking Number:</Typography>
          <TextField2 value={NumberVal} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderName = () => {
    const onChange = (ev, val) => setName(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Name:</Typography>
          <TextField2 value={Name} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderAliasName = () => {
    const onChange = (ev, val) => setAliasName(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Alias Name:</Typography>
          <TextField2 value={AliasName} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Race:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.Race ? bookingsSelectItems.Race : []
            }
            onChange={onChange}
            value={Race}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Sex:</Typography>
          <Autocomplete2
            options={bookingsSelectItems && bookingsSelectItems.Sex ? bookingsSelectItems.Sex : []}
            onChange={onChange}
            value={Sex}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderDOB = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DOB:</Typography>
          <KeyboardDatePicker
            autoOk
            value={DOB}
            onChange={setDOB}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderIDNumber = () => {
    const onChange = (ev, val) => setIDNumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DL/ID Number:</Typography>
          <TextField2 value={IDNumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderIDState = () => {
    const onChange = (ev, val) => setIDState(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DL/ID State:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.IDState ? bookingsSelectItems.IDState : []
            }
            onChange={onChange}
            value={IDState}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSSN = () => {
    const onChange = (ev, val) => setSSN(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>SSN:</Typography>
          <TextField2 value={SSN} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderFBINumber = () => {
    const onChange = (ev, val) => setFBINumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>FBI Number:</Typography>
          <TextField2 value={FBINumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderSIDNumber = () => {
    const onChange = (ev, val) => setFBINumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>SID Number:</Typography>
          <TextField2 value={SIDNumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderTrackingNumber = () => {
    const onChange = (ev, val) => setTrackingNumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Tracking Number:</Typography>
          <TextField2 value={TrackingNumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderOffense = () => {
    const onChange = (ev, val) => setOffense(val);
    return (
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Offense:</Typography>
          <TextField2 value={Offense} onChange={onChange} compact />
          {/* <Autocomplete2
            options={(bookingsSelectItems && bookingsSelectItems.Offense) ? bookingsSelectItems.Offense : []}
            // className={classes.item}
            onChange={onChange}
            value={Offense}
            //label=""
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          /> */}
        </div>
      </Grid>
    );
  };

  const renderArrestLocation = () => {
    const onChange = (ev, val) => setArrestLocation(val);
    return (
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Arrest Location:</Typography>
          <TextField2 value={ArrestLocation} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderArrestCity = () => {
    const onChange = (ev, val) => setArrestCity(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Arrest City:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.ArrestCity
                ? bookingsSelectItems.ArrestCity
                : []
            }
            onChange={onChange}
            value={ArrestCity}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderArrestingOfficer = () => {
    const onChange = (ev, val) => setArrestingOfficer(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Arresting Officer:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.ArrestingOfficer
                ? bookingsSelectItems.ArrestingOfficer
                : []
            }
            onChange={onChange}
            value={ArrestingOfficer}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderArrestingAgency = () => {
    const onChange = (ev, val) => setArrestingAgency(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Arresting Agency:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.ArrestingAgency
                ? bookingsSelectItems.ArrestingAgency
                : []
            }
            onChange={onChange}
            value={ArrestingAgency}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderBookingOfficer = () => {
    const onChange = (ev, val) => setBookingOfficer(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Booking Officer:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.BookingOfficer
                ? bookingsSelectItems.BookingOfficer
                : []
            }
            onChange={onChange}
            value={BookingOfficer}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderReleasingOfficer = () => {
    const onChange = (ev, val) => setReleasingOfficer(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Releasing Officer:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.ReleasingOfficer
                ? bookingsSelectItems.ReleasingOfficer
                : []
            }
            onChange={onChange}
            value={ReleasingOfficer}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderDisposition = () => {
    const onChange = (ev, val) => setDisposition(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Disposition:</Typography>
          <Autocomplete2
            options={
              bookingsSelectItems && bookingsSelectItems.Disposition
                ? bookingsSelectItems.Disposition
                : []
            }
            onChange={onChange}
            value={Disposition}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.content}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Typography className={classes.label} style={{ width: 100 }}>
              From Date:
            </Typography>
            <Grid container spacing={2}>
              {renderBookedFrom()}
              {renderArrestFrom()}
              {renderReleaseFrom()}
              {renderNumberVal()}
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Typography className={classes.label} style={{ width: 100 }}>
              To Date:
            </Typography>
            <Grid container spacing={2}>
              {renderBookedTo()}
              {renderArrestTo()}
              {renderReleaseTo()}
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderName()}
              {renderAliasName()}
              {renderRace()}
              {renderSex()}
              {renderDOB()}
              {renderIDNumber()}
              {renderIDState()}
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderSSN()}
              {renderFBINumber()}
              {renderSIDNumber()}
              {renderTrackingNumber()}
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderOffense()}
              {renderArrestLocation()}
              {renderArrestCity()}
              {renderArrestingOfficer()}
              {renderArrestingAgency()}
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderBookingOfficer()}
              {renderReleasingOfficer()}
              {renderDisposition()}
            </Grid>
          </div>
        </MuiPickersUtilsProvider>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className={classes.filterWrap} style={{ marginBottom: 8 }}>
            <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
          </div>
          <div className={classes.actions}>
            <PrintSearch title="Booking" data={rows} cols={columns} />
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleViewDetails}
              disabled={!selection}>
              <PageviewIcon />
              View Details
            </Button>
            <Button className={classes.btn} variant="contained" autoFocus onClick={handleClear}>
              <ClearIcon /> Clear
            </Button>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleSearch}>
              <SearchIcon /> Search
            </Button>
          </div>
        </div>
      </div>

      <XGrid
        className={classes.xgrid}
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        rowHeight={38}
        onRowDoubleClick={handleViewDetails}
        onSelectionModelChange={handleSelectChange}
        disableMultipleSelection
        hideFooter
      />
      {/* {Boolean(details) && <Report data={details} close={() => setDetails(null)} />} */}
      {showDetails && <Report data={bookingDetails} close={closeDetails} />}

      {
        <AlertDialog
          open={dialogOpen}
          onClose={handleClose}
          title="Search Criteria"
          content="Please input in at least one field before searching."
        />
      }
      {
        <AlertDialog
          open={loadingOpen}
          onClose={loadingClose}
          title="Loading"
          content="Sorry for the inconvenience. Still loading. Please wait."
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    webSocket: state.websocket.websocket,
    bookingsSelectItems: state.vfc.bookingsSelectItems,
    bookingDetails: state.vfc.bookingDetails,
    Agency: state.vfc.Agency,
  };
};

export default connect(mapStateToProps, { handleError, getBookingsSelectItems, getBookingDetails })(
  Booking
);
