import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8policestation100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M25 1c-.218 0-.434.07-.62.21l-23 18c-.43.34-.51.97-.17 1.41.34.43.97.51 1.41.17L4 19.71V46c0 .55.45 1 1 1h40c.55 0 1-.45 1-1V19.71l1.38 1.08c.19.14.4.21.62.21.3 0 .59-.13.79-.38a1.01 1.01 0 0 0-.17-1.41l-23-18A1.024 1.024 0 0 0 25 1zm-7.2 17c.7.71 2.18 1.1 3.67 1.1 1.39 0 2.79-.35 3.53-1.1.74.75 2.14 1.1 3.53 1.1 1.49 0 2.97-.39 3.67-1.1l1.8 1.83s-1.08 2.02-1.08 3.67c0 2.84 1.08 3.76 1.08 7.42 0 6.7-7.56 8.07-9 9.08-1.44-1.1-9-2.38-9-9.08 0-3.57 1.08-4.58 1.08-7.42 0-1.65-1.08-3.67-1.08-3.67L17.8 18zm7.2 5-1.42 3.4-3.58.4 2.64 2.5-.75 3.7L25 31.1l3.11 1.9-.75-3.7L30 26.8l-3.58-.4L25 23z" />
    </SvgIcon>
  );
}

export default icons8policestation100Icon;
