import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8parking100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M9 4C6.24 4 4 6.24 4 9v32c0 2.76 2.24 5 5 5h32c2.76 0 5-2.24 5-5V9c0-2.76-2.24-5-5-5H9zm8 10h12c3.31 0 6 2.69 6 6v2c0 3.31-2.69 6-6 6h-8v8h-4V14zm4 4v6h8c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2h-8z" />
    </SvgIcon>
  );
}

export default icons8parking100Icon;
