import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchAgencies } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'Description', headerName: 'Description', width: 150, hide: false },
  { field: 'AgencyImage', headerName: 'Agency Image', width: 150, hide: false },
  { field: 'PlaceID', headerName: 'Place ID', width: 150, hide: false },
  { field: 'PlaceName', headerName: 'Place Name', width: 150, hide: false },
  { field: 'Police', headerName: 'Police', width: 150, hide: false },
  { field: 'Fire', headerName: 'Fire', width: 150, hide: false },
  { field: 'EMS', headerName: 'EMS', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedID', headerName: 'Deleted ID', width: 140, hide: false },
];

function Agency(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [agencyColumns, setAgencyColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'Agencies');

  useEffect(() => {
    getAgencyData();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getAgencyData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getAgencyData = async () => {
    setLoaded(false);
    searchAgencies()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.AgencyID,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 29, data: { AgencyID: item.AgencyID }, title: 'Agency Details' });
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Agencies</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={agencyColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Agencies"
            data={filteredRows}
            cols={agencyColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      <XGrid3
        name="agencies"
        columns={agencyColumns}
        setColumns={setAgencyColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
})(Agency);
