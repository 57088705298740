import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Unit from './Unit';

function UnitIndex() {
  return (
    <SearchMenu>
      <FilterConsumer>{(AgencyFilter) => <Unit filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

export default UnitIndex;
