import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Toolbar, Button, Avatar, Box } from '@material-ui/core';
import Tooltip from './Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodMenuItems from 'config/configureMenu';
import settings from 'config/settings';
import { addPageDialog, showReport, closeReport, showTestingDialog } from 'reducers/DialogsReducer';
import UserMenu from './UserMenu';
import AgencySelect from './AgencySelect';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& button': {
      '& span': {
        //marginRight: 8,
      },
    },
  },
  rightMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.topNav.bg,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: settings.mainNavHeight,
    minHeight: settings.mainNavHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    marginRight: 8,
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
  },
  btnLabel: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  activeNav: {
    '& > button': {
      background: '#c9d5e0',
      fontWeight: 600,
    },
    // '& > li': {
    //   fontWeight: 600,
    // },
  },
  userName: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  arrowDn: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: settings.mainNavHeight,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: theme.spacing(2),
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.colors.text1,
    },
    '& a:hover': {
      color: theme.colors.text1,
    },
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  menuUnread: {
    width: 3800,
  },
}));

const MainNav = (props) => {
  const classes = useStyles();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [menuItems, setMenuItems] = useState(prodMenuItems);
  const userName = props.user?.userData?.user?.Username || '';

  const handleUserMenuClick = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserMenuAnchor(null);
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          {/* <div> */}
          {menuItems.map((btn, idx) => (
            <Tooltip title={btn.tooltip} key={idx}>
              <NavLink to={btn.link} activeClassName={classes.activeNav}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.btn, 'p-0 s-2 d-block')}>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={btn.icon} className="font-size-xxl" />
                  </span>
                  <span className={classes.btnLabel}>{btn.label}</span>
                </Button>
              </NavLink>
            </Tooltip>
          ))}
        </div>

        <div className={classes.rightMenu}>
          <AgencySelect />
          <div>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box className={classes.avatar}>
                <Avatar sizes="30" alt={userName} />
              </Box>
              <span className={classes.userName}>{userName}</span>
              <span className={classes.arrowDn}>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {renderDesktopMenu()}
          {Boolean(userMenuAnchor) && (
            <UserMenu anchorEl={userMenuAnchor} onClose={closeUserMenu} user={props.user} />
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    addPageDialog,
    showReport,
    closeReport,
    showTestingDialog,
  })(MainNav)
);
