import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import XGrid3 from 'components/XGrid3';
import SearchDatePicker from '../components/SearchDatePicker';
import { showReport, editEvent } from 'reducers/DialogsReducer';
import { searchEvents } from 'reducers/SearchReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { notify } from 'reducers/NotifierReducer';
import { copyEvent } from 'reducers/EventsReducer';
import { showAddAttachment } from 'reducers/DialogsReducer';
import SearchHotKeys from 'Search/SearchHotKeys';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { getFullPermissions, getAccessPermission } from 'reducers/PermissionsReducer';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { Redirect } from 'react-router-dom';
import { reinstateEvent } from 'reducers/EventsReducer';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 50);

const defaultColumns = [
  { field: 'EventID', headerName: 'Event ID', width: 140, hide: false },
  { field: 'OriginalEventType', headerName: 'Original Event Type', width: 120, hide: false },
  { field: 'EventType', headerName: 'Event Type', width: 120, hide: false },
  { field: 'Status', headerName: 'Status', width: 120, hide: false },
  { field: 'CaseID', headerName: 'Case ID', width: 100, hide: false },
  { field: 'FullAddress', headerName: 'Full Address', width: 120, hide: false },
  { field: 'StreetName', headerName: 'Street Name', width: 120, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 100, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 120, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 120, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120, hide: false },
];

function EventHistory(props) {
  const classes = useStyles();
  const { filter, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const [selectionStatus, setSelectionStatus] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('cad', 'Events');
  const editClosedEvents = getAccessPermission('cad', 'Edit Closed Events');

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate?.type === 'event') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewInternalReport();
        break;
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return { ...row, id: idx };
    });
  };

  const search = () => {
    setLoaded(false);
    searchEvents(dateFrom, dateTo, filter)
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewInternalReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    props.showReport({ id: 24, data: { ptsEventID: item.ptsEventID }, title: 'Event Details' });
  };

  const viewExternalReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    props.showReport({ id: 72, data: { ptsEventID: item.ptsEventID }, title: 'Event Details' });
  };

  const showDetailsMenu = (ev) => {
    setViewMenuAnchor(ev.currentTarget);
  };

  const closeDetailsMenu = () => {
    setViewMenuAnchor(null);
  };

  const editEvent = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.editEvent(item.ptsEventID);
  };

  const handleCopyEvent = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    try {
      const newEvent = await copyEvent(item.ptsEventID);
      props.notify(`Created event: ${newEvent.EventID}`, 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    props.showAddAttachment({ id: ptsEventID, type: 'Event' });
  };

  const reinstate = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    props.reinstateEvent(ptsEventID);
  };

  const actions = () => {
    const canEdit = permissions.Edit && (selectionStatus !== 'closed' || editClosedEvents);
    const canReinstate = permissions.Edit && selectionStatus === 'closed' && editClosedEvents;

    return (
      <>
        <Tooltip title="Veiw details">
          <span>
            <Fab
              onClick={showDetailsMenu}
              size="small"
              color="secondary"
              disabled={typeof selection !== 'number'}>
              <InfoIcon />
            </Fab>
          </span>
        </Tooltip>
        {selection !== null && (
          <>
            {canEdit && (
              <Tooltip title="Edit Event">
                <Fab size="small" color="secondary" onClick={editEvent}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}
            {canReinstate && (
              <Tooltip title="Reinstate Event">
                <Fab size="small" color="secondary" onClick={reinstate}>
                  <OpenInBrowserIcon />
                </Fab>
              </Tooltip>
            )}
            <Tooltip title="Copy Event">
              <Fab size="small" color="secondary" onClick={handleCopyEvent}>
                <FileCopyIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Add attachment">
              <Fab color="secondary" size="small" onClick={addAttachment}>
                <AttachFileIcon />
              </Fab>
            </Tooltip>
          </>
        )}
        <Menu
          anchorEl={viewMenuAnchor}
          keepMounted
          open={Boolean(viewMenuAnchor)}
          onClose={closeDetailsMenu}>
          <MenuItem onClick={viewInternalReport}>Internal</MenuItem>
          <MenuItem onClick={viewExternalReport}>External</MenuItem>
        </Menu>
      </>
    );
  };

  const onSelection = (selection) => {
    setSelection(selection);
    const item = rows.find((row) => row.id === selection);
    const Status = item ? item.Status.toLowerCase() : null;
    setSelectionStatus(Status);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Events</h5>
      <QuickFilterSearch rows={rows} setFilteredRows={setFilteredRows} columns={columns} />
      <SearchDatePicker
        search={search}
        actions={actions}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        printTitle="Events"
        filteredRows={filteredRows}
        columns={columns}
        kbdKey={kbdKey}
      />
      <XGrid3
        name="events"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={onSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  editEvent,
  notify,
  showAddAttachment,
  reinstateEvent,
})(EventHistory);
