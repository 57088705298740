import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, TextField } from '@material-ui/core';
import { areObjEqual } from '../../../utils/functions';

const useStyles = makeStyles(() => ({
  formSection: {
    paddingBottom: '10px',
  },
  root: {
    padding: '11px 14px',
  },
  label: {
    transform: 'translate(14px, 12px) scale(1)',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 14,
    '& > div:first-child': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > div:last-child': {
      minWidth: 90,
    },
  },
}));

const EventFilter = (props) => {
  const classes = useStyles();
  const { colorPalette } = props;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventList, setEventList] = useState([]);
  const EventColors = colorPalette.Events;

  useEffect(() => {
    const events = props.eventList.map((ev) => processEvent(ev));
    if (areObjEqual(events, eventList)) return;
    setEventList(events);
    if (props.selectedEvent) {
      const selectedEvent = events.find((ev) => ev.ptsEventID === props.selectedEvent);
      setSelectedEvent(selectedEvent);
    }
    // eslint-disable-next-line
  }, [props.eventList]);

  const processEvent = (event) => {
    const { ptsEventID, EventID, Status, CallType, FullLocationAddress } = event;
    return { ptsEventID, EventID, Status, CallType, FullLocationAddress };
  };

  const handleFilterEventChange = (event, value) => {
    if (value !== null) {
      let selectedEventInfo = eventList.filter((event) => event.EventID === value.EventID);
      setSelectedEvent(selectedEventInfo[0]);
      props.setSelectedEvent(selectedEventInfo[0].ptsEventID);
    } else {
      setSelectedEvent('');
      props.setSelectedEvent(null);
    }
  };

  const renderOption = (option) => {
    return (
      <div className={classes.option}>
        <div>
          <b>
            {option.CallType} - {option.EventID}
          </b>{' '}
          - {option.FullLocationAddress}
        </div>
        <div className="badge" style={{ background: EventColors[option.Status] }}>
          {option.Status}
        </div>
      </div>
    );
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.formSection}>
      <Autocomplete
        label="event-autocomplete"
        value={selectedEvent}
        onChange={handleFilterEventChange}
        options={eventList}
        size="small"
        getOptionLabel={(option) =>
          option.EventID ? `${option.EventID} - ${option.FullLocationAddress}` : ''
        }
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Event" variant="outlined" />
        )}
      />
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    colorPalette: state.config.colorPalette,
  };
};

export default connect(mapStateToProps)(EventFilter);
