import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function SexCrimeAssaultIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M300.2 154.5c0 11.8 4.8 22.4 12.6 30 10.4-.9 19.5-2.3 25.4-3.6 1.6-.3 3.2-.5 4.8-.5 8.1 0 15.4 4.5 19.3 11.3 13.3-7.1 22.4-21.1 22.4-37.3 0-12.3-5.3-23.4-13.7-31.1l-8.9 3.9 2.9-8.4c-6.5-4.1-14.3-6.6-22.6-6.6-23.3.1-42.2 19-42.2 42.3z" />
      <path d="M458.4 237.6c-8.7-17.6-15.2-28.5-20-35.4H431s2.6-6.1 1.9-7c-.8-.8-20.2-3.3-20.2-3.3l8.8-4.3c-8.7-3.6-24.4-9-38.3-13.6-4.1 9-10.9 16.5-19.5 21.4l.9 2.7c2.7 12-4.9 23.9-16.9 26.6-2.5.6-25.1 5.4-53.1 5.4-4.7 0-9.3-.2-13.8-.4-3.3-2.4-10.7-17.7-10.7-17.7-4.2-8-14.2-11-22.2-6.8s-11 14.2-6.8 22.2c0 0 17.7 38.5 37.1 40.2h1.2c8.9 0 15.7-5.8 24.1-17.1v9.6c7.5 1.1 20.6 3.7 20.6 3.7l-20.6 14.6v17.1c0 4.5.9 10.9 2.4 14.7-.1.8-.2 63.7-.2 63.7 6.2 5.6 11.9 11.4 11.4 12.2-.5.8-6.1.8-11.4.6v7.1l-40.3 36.7c-7.9 7.2-8.5 19.5-1.3 27.4 3.8 4.2 9.1 6.3 14.3 6.3 4.7 0 9.3-1.7 13.1-5.1l46.6-42.5c4-3.7 6.3-8.9 6.3-14.3v-23.6l-14.3-7.3 14.3-8v-30.7c.8 0 1.5.1 2.3.1 1.9 0 3.8-.1 5.7-.4l17.8 66.4c.6 2.4 1.7 4.6 3.2 6.5l17.6 23.8 11.8-1.2-2.6 5.8 12.6 3.7-12.5 4.2 10.9 14.7c3.8 5.1 9.6 7.8 15.6 7.8 4 0 8.1-1.2 11.5-3.8 8.6-6.4 10.4-18.5 4-27.1l-35.6-48-8.4-31.1-13.7-2.1 10-11.7-8.1-30.1c2.2-4.4 3.4-9.3 3.4-14.7v-30.3l-15.3-9.4 15.3-2.4v-4.9l-10.6-4.7 10.6-2.7v-30c9 3.1 16.4 5.8 19.5 7.2 3.7 4.4 12.4 19.7 19.6 34.2 2.9 5.8 8.7 9.1 14.7 9.1 2.4 0 4.9-.5 7.2-1.7 8.2-4.4 11.5-14.2 7.5-22.3z" />
      <circle cx="159.9" cy="118.9" r="44.9" />
      <path d="M214.2 310.1c3.7-3.9 6.4-8.8 7.7-14.7l14.6-68.2c-1.3-4.1-1.4-8.5-.1-12.7 1.7-5.4 5.3-9.9 10.4-12.5 3.1-1.6 6.5-2.5 9.9-2.5 7.9 0 15.1 4.3 18.8 11.3l3.2 6.1c1.3 2.5 2.7 5.2 4 7.6 4.6.3 9.2.4 13.7.4 27.8 0 50.3-4.9 52.1-5.3 9.4-2.1 15.3-11.4 13.2-20.8-2.1-9.4-11.4-15.3-20.8-13.2-.4.1-.9.2-1.3.3l-2.9 13.6-9.8-11.4c-24.4 3.3-64.5 4.5-87.6-10.9-4.5-8.2-12.1-14.8-19.9-18.6 0 0-4.3-2.4-9.3-3.3-4.7-.9-10.2-.9-10.2-.9h-.1c-15.5.5-33.8 9.1-37.7 27.1l-21.2 97.1c-1.7 7.9-1.6 14.9 2.8 19.3-.2.8-.4 1.5-.5 2.2l-8.7 72.7-46 59.7c-7.4 9.5-5.6 23.3 4 30.6 4 3.1 8.7 4.6 13.3 4.6 6.5 0 13-2.9 17.3-8.5l49.7-64.4c2.4-3.1 3.9-6.8 4.4-10.8l4.7-39.1 14.3 31.7-14.5 69.4c-2.5 11.8 5.1 23.4 17 25.8 1.5.3 3 .5 4.5.5 10.1 0 19.2-7.1 21.4-17.4l15.9-76.7c.9-4.5.4-9.2-1.5-13.4l-24.8-54.7zM196.5 46.9l9.2 15.8 17.3-6.4-9.1 20.6 17.2 3.7-13 11.5 18.4 11.8-19.3 5.5.9 3.2 26.3-7.6-20.8-13.3 14.5-12.9-19.5-4.3 10.5-23.9-21.9 8-11.1-18.9-10.5 24.4 3.1 1.2z" />
    </SvgIcon>
  );
}

export default SexCrimeAssaultIcon;
