import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from './EventIcon';

const avatarWidth = 44;

const useStyles = makeStyles((theme) => ({
  avatar: {
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    height: avatarWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      boxSizing: 'border-box',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: avatarWidth,
      height: avatarWidth,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5em',
      color: theme.colors.text1,
      fontWeight: 500,
      textTransform: 'uppercase',
      padding: 4,
    },
  },
}));

function EventAvatar(props) {
  const classes = useStyles();
  const { CallTypes, event, colorPalette, className, color } = props;
  const { Status, CallType } = event;
  const evType = CallTypes.find((type) => type.Code === CallType);
  const EventColors = colorPalette.Events;
  const statusColor = color ? color : EventColors[Status];

  return (
    <div className={clsx(classes.avatar, className)}>
      <div>
        <EventIcon evType={evType} color={statusColor} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    colorPalette: state.config.colorPalette,
    CallTypes: state.dictionary.CallTypes,
  };
};

export default connect(mapStateToProps)(EventAvatar);
