import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import SearchDatePicker from '../components/SearchDatePicker';
import { searchUnitStatus, removeUnitStatus } from 'reducers/SearchReducer';
import TextField2 from 'components/TextField2';
import CheckIcon from '@material-ui/icons/Check';
import { showReport } from 'reducers/DialogsReducer';

import Autocomplete2 from 'components/Autocomplete2';
import { gridStyle } from 'utils/formStyles';
import { filterResults } from 'utils/functions';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { editUnitStatus } from 'reducers/DialogsReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import { notify } from 'reducers/NotifierReducer';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(1.5),
    },
  },
  unit: gridStyle(200, 200),
  quickFilter: gridStyle(200, 420),
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 70);

const defaultColumns = [
  { field: 'Mobile', headerName: 'Mobile', width: 100, hide: false },
  { field: 'EventID', headerName: 'Event ID', width: 120, hide: false },
  { field: 'Unit', headerName: 'Unit', width: 100, hide: false },
  { field: 'Names', headerName: 'Names', width: 120, hide: false },
  { field: 'Action', headerName: 'Action', width: 120, hide: false },
  {
    field: 'Occurred',
    headerName: 'Occurred',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120, hide: false },
  { field: 'Division', headerName: 'Division', width: 120, hide: false },
  { field: 'Zone', headerName: 'Zone', width: 100, hide: false },
  { field: 'Location', headerName: 'Location', width: 120, hide: false },
  { field: 'TripNumber', headerName: 'Trip Number', width: 120, hide: false },
  { field: 'Resources', headerName: 'Resources', width: 120, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120, hide: false },
];

function UnitStatusHistory(props) {
  const classes = useStyles();
  const { filter, dataUpdate } = props;
  const [quickFilter, setQuickFilter] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [unit, setUnit] = useState(null);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const query = new URLSearchParams(useLocation().search);
  const permissions = getFullPermissions('cad', 'Unit Status');

  useEffect(() => {
    const defaultUnit = query.get('u');
    if (defaultUnit) {
      const unit = props.units.find((unit) => unit.Unit === defaultUnit);
      unit && setUnit(unit);
    }
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'unit-status') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    let filteredRows = rows;
    if (quickFilter) filteredRows = filterResults(quickFilter, rows, columns);
    if (unit) filteredRows = filterResults(unit.Unit, filteredRows, [{ field: 'Unit' }]);
    setFilteredRows(filteredRows);
    // eslint-disable-next-line
  }, [rows, quickFilter, unit]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const search = () => {
    setLoaded(false);
    searchUnitStatus(dateFrom, dateTo, filter)
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsActionID };
    });
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 38,
      data: { ptsActionID: item.ptsActionID },
      title: 'Unit Action Details',
    });
  };

  const editStatus = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsUnitID, ptsEventID } = item;
    props.editUnitStatus(ptsUnitID, ptsEventID);
  };

  const deleteStatus = async () => {
    if (!window.confirm('Are you sure you want to delete this status?')) return;
    try {
      await removeUnitStatus(selection);
      search();
      props.notify('Unit Status Deleted', 'success');
    } catch (e) {
      props.handleError(e);
    }
  };

  const actions = () => {
    return (
      <>
        {selection !== null && permissions.Edit && (
          <Tooltip title="Edit Unit Status">
            <Fab className="ml-2" size="small" color="secondary" onClick={editStatus}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
        {selection !== null && permissions.Delete && (
          <Tooltip title="Delete">
            <Fab className="ml-2" size="small" color="secondary" onClick={deleteStatus}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        )}
      </>
    );
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Unit Status</h5>
      <div className={classes.filters}>
        <TextField2
          className={classes.quickFilter}
          label="Quick filter"
          value={quickFilter}
          onChange={(ev) => setQuickFilter(ev.target.value)}
          compact
        />
        <Autocomplete2
          options={props.units}
          className={classes.unit}
          onChange={(ev, val) => setUnit(val)}
          value={unit}
          label="Unit"
          compact
          getOptionSelected={(option, value) => option.Unit === value.Unit}
          getOptionLabel={(option) => option.Unit}
          renderOption={(option) => <span>{option.Unit}</span>}
        />
      </div>
      <SearchDatePicker
        search={search}
        actions={actions}
        viewDetails={selection ? viewDetails : null}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        printTitle="Unit Status History"
        filteredRows={filteredRows}
        columns={columns}
        kbdKey={kbdKey}
      />
      <XGrid3
        name="unitStatus"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  units: state.units,
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  editUnitStatus,
  notify,
})(UnitStatusHistory);
