import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Zone from './Zone';

function ZoneIndex() {
  return (
    <SearchMenu>
      <FilterConsumer>{(AgencyFilter) => <Zone filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

export default ZoneIndex;
