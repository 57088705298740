import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import SearchDatePicker from '../components/SearchDatePicker';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { searchQueries } from 'reducers/SearchReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';

import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const defaultColumns = [
  { field: 'Unit', headerName: 'Unit', width: 100, hide: false },
  {
    field: 'QueryDate',
    headerName: 'Query Date',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'Notes', headerName: 'Notes', width: 100, hide: false },
  { field: 'QueryType', headerName: 'Query Type', width: 120, hide: false },
  { field: 'QueryOperator', headerName: 'Query Operator', width: 120, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 70);

function QueryHistory(props) {
  const classes = useStyles();
  const { filter } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    search();
    getallQueries();
    // eslint-disable-next-line
  }, [filter]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  // Code commented as it only throws errors and doesn't seem to do anything
  const getallQueries = async () => {
    //const res = await searchQueries(dateFrom, dateTo, filter);
    // console.log("resis: ", res)
  };

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsQueryID };
    });
  };

  const search = () => {
    setLoaded(false);
    searchQueries(dateFrom, dateTo, filter)
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Query Details - ${item.QueryType}/ ${item.QueryOperator}`,
    });
  };

  const filterData = (rawData) => {
    const {
      EventID,
      Unit,
      QueryDate,
      Tag,
      TagState,
      OLN,
      OLNState,
      Notes,
      QueryType,
      QueryOperator,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;

    return {
      EventID,
      Unit,
      QueryDate,
      Tag,
      TagState,
      OLN,
      OLNState,
      Notes,
      QueryType,
      QueryOperator,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  return (
    <div>
      <h5>NCIC Queries</h5>
      <QuickFilterSearch rows={rows} setFilteredRows={setFilteredRows} columns={columns} />
      <SearchDatePicker
        search={search}
        viewDetails={typeof selection === 'number' ? viewDetails : null}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        printTitle="NCIC Queries"
        filteredRows={filteredRows}
        columns={columns}
        kbdKey={kbdKey}
      />
      <XGrid3
        name="queries"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
})(QueryHistory);
