import { getService } from './service';
// import bestContrast from 'get-best-contrast-color';
import { handleError } from './ErrorReducer';
import store from 'config/configureStore';
import settings from 'config/settings';

const GET_SETTINGS = 'CONFIG/GET_SETTINGS';
const CLEAR_SETTINGS = 'CONFIG/CLEAR_SETTINGS';
const SET_TIME_DIFF = 'SET_TIME_DIFF';

// ================  ACTIONS  ======================

export const timeSyncInit = () => {
  return async (dispatch) => {
    setInterval(() => {
      const state = store.store.getState();
      const { isAuthenticated } = state.user;
      if (isAuthenticated) dispatch(syncTime());
    }, settings.syncTime);
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    try {
      const service = getService();
      const settings = await service.find({ query: { type: 'settings' } });
      settings.timeDiff = await getServerTimeDiff();
      dispatch({ type: GET_SETTINGS, settings });
    } catch (error) {
      dispatch(handleError(error, 'Error loading settings'));
    }
  };
};

export const getServerTimeDiff = async () => {
  let timeDiff = 0;
  try {
    const service = getService('server-time');
    const time1 = new Date().getTime();
    const time = await service.get(0);
    const time2 = new Date().getTime();
    timeDiff = Math.round(time1 + (time2 - time1) / 2 - time);
  } catch (error) {
    handleError(error, 'Error loading settings');
  }
  return timeDiff;
};

export const syncTime = () => {
  return async (dispatch) => {
    try {
      const timeDiff = await getServerTimeDiff();
      dispatch({ type: SET_TIME_DIFF, timeDiff });
    } catch (error) {
      handleError(error, 'Error syncing the time');
    }
  };
};

export const clearSettings = () => (dispatch) => {
  dispatch({ type: CLEAR_SETTINGS });
};

export const saveSettings = (type, data) => {
  const service = getService();
  return service.patch(type, { type: 'settings', data });
};

const testingLevel = parseInt(process.env.REACT_APP_TESTING_LEVEL) || 0;

// ==================  REDUCERS  ===============
const defaultConfig = {
  loaded: false,
  options: {},
  colorPalette: {
    events: {},
    units: {},
  },
  timeDiff: 0,
  testingLevel,
};

export default function reducer(state = defaultConfig, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.settings, loaded: true };
    case CLEAR_SETTINGS:
      return { ...defaultConfig };
    case SET_TIME_DIFF:
      return { ...state, timeDiff: action.timeDiff };
    default:
      return state;
  }
}

export function getWorkstationID() {
  const settings = getLocalSettings();
  return settings.workstationID;
}

export function saveWorkstationID(workstationID) {
  const settings = getLocalSettings();
  settings.workstationID = workstationID;
  saveLocalSettings(settings);
}

export function createWorkstationID() {
  return String(Math.random()).substr(2, 10);
}

const defaultLocalSettings = {
  workstationID: '',
  mapLat: 38.8937091,
  mapLng: -77.0846157,
  mapZoom: 14,
  mapEventOpen: false,
  mapEventWidth: 580,
  mapUnitOpen: false,
  mapUnitWidth: 580,
  changePassReminder: {},
};

export function getLocalSettings(setting) {
  const savedSettings = localStorage.getItem('localSettings');
  let settings;
  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings);
    settings = { ...defaultLocalSettings, ...parsedSettings };
  } else {
    const newSettings = { ...defaultLocalSettings };
    newSettings.workstationID = createWorkstationID();
    localStorage.setItem('localSettings', JSON.stringify(newSettings));
    settings = newSettings;
  }
  if (setting) return settings[setting];
  return settings;
}

export function saveLocalSettings(settings) {
  const currentSettings = getLocalSettings();
  const newSettings = { ...currentSettings, ...settings };
  localStorage.setItem('localSettings', JSON.stringify(newSettings));
}
