import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nav: {
    width: 85,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  column: {
    flexGrow: 1,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.colors.border}`,
    width: '100%',
    overflowY: 'auto',
    '& h5': {
      textAlign: 'center',
    },
  },
  activePanel: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 7,
  },
}));

function SelectList(props) {
  const classes = useStyles();
  const { options, selected, setOptions, keyID, labelID, title, height } = props;
  const [selection, setSelection] = useState([]);
  const [lastIdx, setLastIdx] = useState(null);

  useEffect(() => {
    if (!selected) return;
    setSelection(selected);
    // eslint-disable-next-line
  }, [selected]);

  const handleOptClick = (ev, opt) => {
    const shiftPressed = ev.shiftKey;
    const idx = options.indexOf(opt);
    const selectionIdx = selection.indexOf(opt[keyID]);
    const deselecting = selectionIdx !== -1;
    let newSelection;
    if (deselecting) {
      newSelection = [...selection];
      newSelection.splice(selectionIdx, 1);
    } else {
      newSelection = [...selection, opt[keyID]];
    }
    if (shiftPressed && idx !== lastIdx) {
      const from = Math.min(idx, lastIdx);
      const to = Math.max(idx, lastIdx);
      const objSelection = [...options].splice(from, to - from + 1);
      objSelection.forEach((obj) => {
        const objIdx = newSelection.indexOf(obj[keyID]);
        if (deselecting) {
          objIdx !== -1 && newSelection.splice(objIdx, 1);
        } else {
          objIdx === -1 && newSelection.push(obj[keyID]);
        }
      });
    }
    setSelection(newSelection);
    setLastIdx(idx);
    setOptions(newSelection);
  };

  return (
    <div className={classes.column}>
      <h5>{title}</h5>
      <PerfectScrollbar style={{ height: height - 40 }}>
        <List dense component="div" role="list">
          {options.map((opt) => {
            return (
              <ListItem
                key={opt[keyID]}
                role="listitem"
                button
                onClick={(ev) => handleOptClick(ev, opt)}
                style={{ padding: 0 }}>
                <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
                  <Checkbox
                    checked={selection.indexOf(opt[keyID]) !== -1}
                    tabIndex={-1}
                    disableRipple
                    style={{ padding: 0 }}
                    disabled={opt.IsDisabled}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={opt[labelID]}
                  style={opt.IsDisabled ? { opacity: 0.5 } : {}}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </PerfectScrollbar>
    </div>
  );
}

export default SelectList;
