import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8detective100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M21 3C12.164 3 4.902 9.777 4.094 18.406c.855-.824 1.504-1.328 1.562-1.375 3.563-2.695 9.11-5.906 15.344-5.906 6.457 0 12.176 3.414 15.844 6.281.05.043.484.422 1.062.938C37.07 9.746 29.813 3 21 3Zm-8.063 11.719a31.802 31.802 0 0 0-6.25 3.687 23.12 23.12 0 0 0-2.625 2.563C4.563 29.899 11.95 37 21 37c3.715 0 7.14-1.219 9.938-3.25l13.156 13.156 2.812-2.843L33.875 31.03c2.395-2.797 3.922-6.355 4.094-10.281a45.104 45.104 0 0 0-2.219-1.969c-1.77-1.383-4.066-2.91-6.688-4.062a9.432 9.432 0 0 1 1.5 5.094c0 5.285-4.277 9.593-9.562 9.593-5.285 0-9.563-4.308-9.563-9.593 0-1.887.56-3.614 1.5-5.094Zm8.063.75a4.34 4.34 0 0 0-4.344 4.344c0 2.402 1.942 4.375 4.344 4.375s4.344-1.973 4.344-4.375A4.34 4.34 0 0 0 21 15.468Z" />
    </SvgIcon>
  );
}

export default icons8detective100Icon;
