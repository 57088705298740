import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function JuvenileProblemLoiteringIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M462.1 395h-35.9l-41.6-41.6-21.5-21.6c-1.1-1.1-2.7-1.8-4.2-1.8-1.6 0-3.1.6-4.2 1.8L315.6 371v-86.6l34.7 30.5c3.5 3 8 4.7 12.6 4.7 1 0 2.1-.1 3.1-.2.3 0 40.2-8 40.2-8 10.5-1.9 17.5-12 15.6-22.5-1.7-9.2-9.7-16-19.1-16-1.1 0-2.3.1-3.4.3h-.1l-30.6 6.2-29.1-25.4s38 .1 38.5.1c8.9 0 16.4-6.7 17.4-15.6 1.1-9.6-5.9-18.3-15.5-19.4H248.8c-13.1 0-23.8 10.7-23.8 23.8v86c-13.7-22.2-36-38.5-61.6-45.2l-35.5-98c-.9-2.4-3.2-4.1-5.8-4-2.6.1-4.8 1.8-5.6 4.3l-4 14-6.5-7c-1.6-1.8-4.1-2.4-6.4-1.7-2.3.8-3.9 2.8-4.1 5.2l-1.4 15.8c-8.1 2.1-14.4 8.7-16 17l-25.6 10.3c-1.6.6-2.8 1.9-3.4 3.4-.6 1.6-.5 3.3.3 4.8 8.2 16.3 24 26.4 41.3 26.4 1.6 0 3.2-.1 4.8-.3l.2 96.6c-11.7 19.3-33.3 53.6-33.5 54-1.2 1.8-1.2 4.2-.2 6.1s3.1 3.1 5.3 3.1h21c2 0 3.9-1 5-2.7l34.5-52.9 1.1.2c-.5 5.7-.1 14.3 4.9 24.1-10.9 2.7-23.2 9.8-23.2 25.4 0 3.3 2.7 6 6 6h76.9c7.9-.3 21.6-4.5 31.8-16.8 2 9.6 10.5 16.8 20.7 16.8h216c10.7 0 19.4-8.7 19.4-19.4.1-10.7-8.6-19.4-19.3-19.4zm-110.8 0 7.6-7.6 7.6 7.6h-15.2z" />
      <path d="M265.6 212.6c24.8 0 45-20.2 45-45s-20.2-45-45-45-45 20.2-45 45 20.2 45 45 45z" />
    </SvgIcon>
  );
}

export default JuvenileProblemLoiteringIcon;
