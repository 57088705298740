import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField2 from 'components/TextField2';
import formStyles, {
  formatSaveData,
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
} from 'utils/formStyles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  dateTimePicker,
  formatSaveDate,
  getDateTimeFormat,
  formatSaveDateFrom,
  formatSaveDateTo,
  displayDateTime,
} from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { findCases, getCasesSelectItems, getCaseDetails } from 'reducers/VfcReducer';
import { handleError } from 'reducers/ErrorReducer';
import Report from './CaseReport';
import PageviewIcon from '@material-ui/icons/Pageview';
import PrintSearch from '../../components/PrintsSearch';
import { XGrid } from '@material-ui/x-grid'; //import XGrid3 from 'components/XGrid3';
import Grid from '@material-ui/core/Grid';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Autocomplete2 from '../../components/Autocomplete2';
import Typography from '@material-ui/core/Typography';
import AlertDialog from '../../Dialogs/AlertDialog';
import { getAgenciesAccessPermission } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  addressCategory: gridStyle(220, '100%'),
  item: gridStyle(170, '100%'),
  timePicker: gridStyle(200, '100%'),
  btn: {
    marginLeft: 8,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    marginTop: 8,
    textAlign: 'right',
    marginBottom: 16,
  },
  content: {
    padding: theme.spacing(2),
  },
  xgrid: {
    width: '100%',
    height: 500,
  },
  label: {
    //variant: "h3",
    fontSize: 14,
  },
  autocomplete: {
    margin: '0 0px 0px',
  },
}));

const defaultColumns = [
  { field: 'Number', headerName: 'Case #', width: 150 },
  {
    field: 'Reported',
    headerName: 'Reported Date/Time',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Occured',
    headerName: 'Occurred Date/Time',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'Offense', headerName: 'Offense', width: 200 },
  { field: 'Location', headerName: 'Location', width: 200 },
  { field: 'ReportingOfficer', headerName: 'Reporting Officer', width: 200 },
  { field: 'PartyPersons', headerName: 'Names', width: 350 },
  { field: 'PartyVehicles', headerName: 'Vehicles', width: 350 },
  { field: 'AgencyID', headerName: 'Agency', width: 150 },
];

function Cases(props) {
  const classes = useStyles();
  const casesSelectItems = props.casesSelectItems;
  const caseDetails = props.caseDetails;
  const webSocket = props.webSocket;

  const [ReportedFrom, setReportedFrom] = useState(null);
  const [ReportedTo, setReportedTo] = useState(null);
  const [OccuredFrom, setOccuredFrom] = useState(null);
  const [OccuredTo, setOccuredTo] = useState(null);
  const [NumberVal, setNumberVal] = useState(null);
  const [Offense, setOffense] = useState(null);
  const [LocationOfOffense, setLocationOfOffense] = useState(null);
  const [ReportingOfficer, setReportingOfficer] = useState(null);
  const [PartyType, setPartyType] = useState(null);
  const [Name, setName] = useState(null);
  const [Race, setRace] = useState(null);
  const [Sex, setSex] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [DLNumber, setDLNumber] = useState(null);
  const [DLState, setDLState] = useState(null);
  // const [SSN, setSSN] = useState(null);
  const [VehicleYear, setVehicleYear] = useState(null);
  const [VehicleMake, setVehicleMake] = useState(null);
  const [VehicleModel, setVehicleModel] = useState(null);
  const [VehicleColor, setVehicleColor] = useState(null);
  const [License, setLicense] = useState(null);
  const [LicenseState, setLicenseState] = useState(null);
  const [VIN, setVIN] = useState(null);
  const [PropertyCode, setPropertyCode] = useState(null);
  const [PropertyDescription, setPropertyDescription] = useState(null);
  const [PropertySerialNumber, setPropertySerialNumber] = useState(null);
  const [searchComplete, setSearchComplete] = useState(false);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const accessAgencies = getAgenciesAccessPermission('modules', 'Incident Archive');

  useEffect(() => {
    if (caseDetails && selection) setShowDetails(true);
  }, [caseDetails]);

  useEffect(() => {
    if (searchComplete) handleSearch();
  }, [props.Agency]);

  useEffect(async () => {
    if (webSocket) {
      props.getCasesSelectItems();
    }
  }, [webSocket]);

  const closeDetails = () => {
    setShowDetails(false);
  };

  const handleClear = () => {
    setReportedFrom(null);
    setReportedTo(null);
    setOccuredFrom(null);
    setOccuredTo(null);
    setNumberVal(null);
    setOffense(null);
    setLocationOfOffense(null);
    setReportingOfficer(null);
    setPartyType(null);
    setName(null);
    setRace(null);
    setSex(null);
    setDOB(null);
    setDLNumber(null);
    setDLState(null);
    //setSSN(null);
    setVehicleYear(null);
    setVehicleMake(null);
    setVehicleModel(null);
    setVehicleColor(null);
    setLicense(null);
    setLicenseState(null);
    setVIN(null);
    setPropertyCode(null);
    setPropertyDescription(null);
    setPropertySerialNumber(null);

    setRows([]);
  };

  const handleSearch = async () => {
    const _Offense = Offense ? Offense.Description : null;
    const _ReportingOfficer = ReportingOfficer ? ReportingOfficer.Description : null;
    const _PartyType = PartyType ? PartyType.Description : null;
    const _Race = Race ? Race.Description : null;
    const _Sex = Sex ? Sex.Description : null;
    const _DLState = DLState ? DLState.Description : null;
    const _VehicleMake = VehicleMake ? VehicleMake.Description : null;
    const _VehicleModel = VehicleModel ? VehicleModel.Description : null;
    const _LicenseState = LicenseState ? LicenseState.Description : null;
    const _PropertyCode = PropertyCode ? PropertyCode.Description : null;

    if (
      casesSelectItems == null ||
      Object.keys(casesSelectItems).length === 0 ||
      casesSelectItems.Offense == null ||
      casesSelectItems.Offense.length === 0
    ) {
      if (dialogOpen) {
        handleClose();
        return;
      }
      setLoadingOpen(true);
      return;
    }

    if (
      ReportedFrom == null &&
      ReportedTo == null &&
      OccuredFrom == null &&
      OccuredTo == null &&
      NumberVal == null &&
      _Offense == null &&
      LocationOfOffense == null &&
      _ReportingOfficer == null &&
      _PartyType == null &&
      Name == null &&
      _Race == null &&
      _Sex == null &&
      DOB == null &&
      DLNumber == null &&
      _DLState == null &&
      VehicleYear == null &&
      _VehicleMake == null &&
      _VehicleModel == null &&
      VehicleColor == null &&
      License == null &&
      _LicenseState == null &&
      VIN == null &&
      _PropertyCode == null &&
      PropertyDescription == null &&
      PropertySerialNumber == null
    ) {
      if (loadingOpen) {
        loadingClose();
        return;
      }
      setDialogOpen(true);
      return;
    }

    const data = formatSaveData({
      ReportedFrom: formatSaveDateFrom(ReportedFrom),
      ReportedTo: formatSaveDateTo(ReportedTo),
      OccuredFrom: formatSaveDateFrom(OccuredFrom),
      OccuredTo: formatSaveDateTo(OccuredTo),
      NumberVal,
      Offense: _Offense,
      LocationOfOffense,
      ReportingOfficer: _ReportingOfficer,
      PartyType: _PartyType,
      Name,
      Race: _Race,
      Sex: _Sex,
      DOB: formatSaveDateFrom(DOB),
      DLNumber,
      DLState: _DLState,
      // SSN,
      VehicleYear,
      VehicleMake: _VehicleMake,
      VehicleModel: _VehicleModel,
      VehicleColor,
      License,
      LicenseState: _LicenseState,
      VIN,
      PropertyCode: _PropertyCode,
      PropertyDescription,
      PropertySerialNumber,
      AgencyID: props.Agency ? props.Agency.code : null,
    });
    data.Agencies = accessAgencies;

    setLoaded(false);
    findCases(data)
      .then(setRows)
      .catch(props.handleError)
      .finally(() => {
        setLoaded(true);
        setSearchComplete(true);
      });
  };

  const handleSelectChange = (selection) => {
    setSelection(selection[0]);
  };

  const handleViewDetails = () => {
    const item = rows.find((r) => r.id === selection);
    //setDetails(item);
    //console.log("Case handleview");
    props.getCaseDetails(item.vfcMainId);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const renderReportedFrom = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>
            {/* style={{ marginLeft: 10 }}  */}Reported:
          </Typography>
          <KeyboardDatePicker
            autoOk
            value={ReportedFrom}
            onChange={setReportedFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderReportedTo = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <KeyboardDatePicker
            autoOk
            value={ReportedTo}
            onChange={setReportedTo}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderOccuredFrom = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>
            {/* style={{ marginLeft: 10 }}  */}Occurred:
          </Typography>
          <KeyboardDatePicker
            autoOk
            value={OccuredFrom}
            onChange={setOccuredFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderOccuredTo = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <KeyboardDatePicker
            autoOk
            value={OccuredTo}
            onChange={setOccuredTo}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderNumber = () => {
    const onChange = (ev, val) => setNumberVal(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Office/Case Number:</Typography>
          <TextField2 value={NumberVal} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderOffense = () => {
    const onChange = (ev, val) => setOffense(val);
    return (
      <Grid item xs={4}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Offense:</Typography>

          <Autocomplete2
            options={casesSelectItems && casesSelectItems.Offense ? casesSelectItems.Offense : []}
            onChange={onChange}
            value={Offense}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderLocationOfOffense = () => {
    const onChange = (ev, val) => setLocationOfOffense(val);
    return (
      <Grid item xs={4}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Location of Offense:</Typography>
          <TextField2 value={LocationOfOffense} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderReportingOfficer = () => {
    const onChange = (ev, val) => setReportingOfficer(val);

    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Reporting Officer:</Typography>

          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.ReportingOfficer
                ? casesSelectItems.ReportingOfficer
                : []
            }
            onChange={onChange}
            value={ReportingOfficer}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderPartyType = () => {
    const onChange = (ev, val) => setPartyType(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Party Type:</Typography>
          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.PartyType ? casesSelectItems.PartyType : []
            }
            onChange={onChange}
            value={PartyType}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderName = () => {
    const onChange = (ev, val) => setName(val);
    return (
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Name:</Typography>
          <TextField2 value={Name} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Race:</Typography>

          <Autocomplete2
            options={casesSelectItems && casesSelectItems.Race ? casesSelectItems.Race : []}
            onChange={onChange}
            value={Race}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Sex:</Typography>

          <Autocomplete2
            options={casesSelectItems && casesSelectItems.Sex ? casesSelectItems.Sex : []}
            onChange={onChange}
            value={Sex}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderDOB = () => {
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DOB:</Typography>
          <KeyboardDatePicker
            autoOk
            value={DOB}
            onChange={setDOB}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  };

  const renderDLNumber = () => {
    const onChange = (ev, val) => setDLNumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DL/ID Number:</Typography>
          <TextField2 value={DLNumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderDLState = () => {
    const onChange = (ev, val) => setDLState(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>DL/ID State:</Typography>
          <Autocomplete2
            options={casesSelectItems && casesSelectItems.DLState ? casesSelectItems.DLState : []}
            onChange={onChange}
            value={DLState}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  // const renderSSN = () => {
  //   const onChange = (ev, val) => setSSN(val);
  //   return (
  //     <TextField2
  //       className={classes.item}
  //       label="SSN"
  //       value={SSN}
  //       onChange={onChange}
  //       compact
  //     />
  //   );
  // };

  const renderVehicleYear = () => {
    const onChange = (ev, val) => setVehicleYear(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Vehicle Year:</Typography>
          <TextField2 value={VehicleYear} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderVehicleMake = () => {
    const onChange = (ev, val) => setVehicleMake(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Vehicle Make:</Typography>
          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.VehicleMake ? casesSelectItems.VehicleMake : []
            }
            onChange={onChange}
            value={VehicleMake}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderVehicleModel = () => {
    const onChange = (ev, val) => setVehicleModel(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Vehicle Model:</Typography>
          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.VehicleModel ? casesSelectItems.VehicleModel : []
            }
            onChange={onChange}
            value={VehicleModel}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderVehicleColor = () => {
    const onChange = (ev, val) => setVehicleColor(val);
    return (
      <Grid item xs={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Vehicle Color:</Typography>
          <TextField2 value={VehicleColor} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderLicense = () => {
    const onChange = (ev, val) => setLicense(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>License:</Typography>
          <TextField2 value={License} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderLicenseState = () => {
    const onChange = (ev, val) => setLicenseState(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>License State:</Typography>
          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.LicenseState ? casesSelectItems.LicenseState : []
            }
            onChange={onChange}
            value={LicenseState}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderVIN = () => {
    const onChange = (ev, val) => setVIN(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>VIN:</Typography>
          <TextField2 value={VIN} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderPropertyCode = () => {
    const onChange = (ev, val) => setPropertyCode(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Property Code:</Typography>
          <Autocomplete2
            options={
              casesSelectItems && casesSelectItems.PropertyCode ? casesSelectItems.PropertyCode : []
            }
            onChange={onChange}
            value={PropertyCode}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : '')}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderPropertyDescription = () => {
    const onChange = (ev, val) => setPropertyDescription(val);
    return (
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Property Description:</Typography>
          <TextField2 value={PropertyDescription} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  const renderPropertySerialNumber = () => {
    const onChange = (ev, val) => setPropertySerialNumber(val);
    return (
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
          }}>
          <Typography className={classes.label}>Serial Number:</Typography>
          <TextField2 value={PropertySerialNumber} onChange={onChange} compact />
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.content}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Typography className={classes.label} style={{ width: 100 }}>
              From Date:
            </Typography>
            <Grid container spacing={2}>
              {renderReportedFrom()}
              {renderOccuredFrom()}
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Typography className={classes.label} style={{ width: 100 }}>
              To Date:
            </Typography>
            <Grid container spacing={2}>
              {renderReportedTo()}
              {renderOccuredTo()}
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderNumber()}
              {renderOffense()}
              {renderLocationOfOffense()}
              {renderReportingOfficer()}
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderPartyType()}
              {renderName()}
              {renderRace()}
              {renderSex()}
              {renderDOB()}
              {renderDLNumber()}
              {renderDLState()}
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderVehicleYear()}
              {renderVehicleMake()}
              {renderVehicleModel()}
              {renderVehicleColor()}
              {renderLicense()}
              {renderLicenseState()}
              {renderVIN()}
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Grid container spacing={2}>
              {renderPropertyCode()}
              {renderPropertyDescription()}
              {renderPropertySerialNumber()}
            </Grid>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}>
        <div className={classes.filterWrap} style={{ marginBottom: 8 }}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>

        <div className={classes.actions}>
          <PrintSearch title="Cases" data={rows} cols={columns} />
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleViewDetails}
            disabled={!selection}>
            <PageviewIcon />
            View Details
          </Button>
          <Button className={classes.btn} variant="contained" autoFocus onClick={handleClear}>
            <ClearIcon /> Clear
          </Button>
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSearch}>
            <SearchIcon /> Search
          </Button>
        </div>
      </div>

      <XGrid
        //name="VfcEvent"
        className={classes.xgrid}
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        rowHeight={38}
        onRowDoubleClick={handleViewDetails}
        onSelectionModelChange={handleSelectChange}
        disableMultipleSelection
        hideFooter
      />
      {/* <XGrid3
        name="VfcCases"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      /> */}

      {/* {Boolean(details) && <Report data={details} close={() => setDetails(null)} />} */}
      {showDetails && <Report data={caseDetails} close={closeDetails} />}

      {
        <AlertDialog
          open={dialogOpen}
          onClose={handleClose}
          title="Search Criteria"
          content="Please input in at least one field before searching."
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    webSocket: state.websocket.websocket,
    casesSelectItems: state.vfc.casesSelectItems,
    caseDetails: state.vfc.caseDetails,
    Agency: state.vfc.Agency,
  };
};

export default connect(mapStateToProps, { handleError, getCasesSelectItems, getCaseDetails })(
  Cases
);
