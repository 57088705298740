import Tooltip from './Tooltip';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  tooltip: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: theme.tooltip.bg,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.tooltip.border}`,
    fontWeight: 400,
    boxShadow: `1px 1px 3px ${theme.tooltip.shadow}`,
    '& b': {
      fontWeight: 600,
    },
    '& table': {
      color: theme.content.color,
      background: theme.card.bg,
      width: '100%',
      marginBottom: 7,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    '& h4': {
      textAlign: 'center',
      fontSize: 14,
      color: theme.tooltip.header,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 4,
    },
    '& th': {
      border: `1px solid ${theme.tooltip.tableBorder}`,
      padding: '2px 5px',
      textTransfrom: 'uppercase',
      whiteSpace: 'nowrap',
      background: theme.tooltip.tableHeaderBg,
    },
    '& td': {
      border: `1px solid ${theme.tooltip.tableBorder}`,
      padding: '2px 5px',
    },
  },
}))(Tooltip);
