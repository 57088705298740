import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8evidence100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="100"
      height="100">
      <path d="M25.406 3.887c-11.634 0-21.11 9.477-21.11 21.111s9.476 21.111 21.11 21.111a21 21 0 0 0 13.3-4.738l14.831 18.58s3.25.483 5.268-1.619c2.03-2.118 1.552-5.2 1.552-5.2L41.78 38.298a21 21 0 0 0 4.739-13.299c0-11.634-9.477-21.111-21.112-21.111zm0 4.222c9.353 0 16.89 7.537 16.89 16.889a16.858 16.858 0 0 1-16.89 16.889A16.857 16.857 0 0 1 8.518 24.998 16.857 16.857 0 0 1 25.406 8.109zm-5.613 6.889c-1.019 0-1.967.418-2.742 1.209-1.64 1.672-2.179 4.538-2.14 6.484.034 1.734.819 3.617.853 3.696l.257.611h6.866l.2-.734c.026-.095.639-2.344.798-4.47.029-.383.23-3.808-1.506-5.679a3.444 3.444 0 0 0-2.586-1.117zm11.23 0c-1.014 0-1.908.386-2.587 1.117-1.736 1.871-1.535 5.295-1.506 5.68.159 2.125.77 4.373.797 4.467l.203.736h6.863l.258-.611c.034-.08.818-1.96.853-3.694.04-1.947-.497-4.814-2.136-6.486-.776-.791-1.726-1.209-2.745-1.209zm-15.109 14-.006.992c-.016 2.36.471 4.056 1.45 5.041.626.632 1.46.967 2.408.967.209 0 .42-.03.644-.092 1.48-.4 2.482-2.102 2.496-4.234a9.755 9.755 0 0 0-.052-1.162 5.936 5.936 0 0 0-.122-.74l-.18-.772h-6.638zm12.35 0-.182.772c-.06.253-.094.499-.121.738a9.77 9.77 0 0 0-.055 1.164c.014 2.132 1.018 3.833 2.496 4.234.227.061.44.092.649.092.947 0 1.779-.335 2.406-.967.979-.985 1.467-2.68 1.451-5.04l-.008-.993h-6.636z" />
    </SvgIcon>
  );
}

export default icons8evidence100Icon;
