import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  alignRight: {
    textAlign: 'right',
  },
  underlined: {
    borderBottom: `1px solid ${theme.colors.grey1}`,
  },
  btn: {
    marginTop: 5,
    minWidth: 50,
    background: theme.colors.orange1,
  },
}));

function BulletinNotification(props) {
  const classes = useStyles();
  const { data } = props;

  const {
    Beginning,
    Ending,
    Notification,
    OLN,
    OLNState,
    Person,
    Plate,
    PlateState,
    Priority,
    Ranges,
    Vehicle,
  } = data;

  return (
    <>
      <table className={classes.table}>
        <tbody>
          {!!Notification && (
            <tr>
              <td colSpan={2} className={classes.underlined}>
                {Notification}
              </td>
            </tr>
          )}
          {!!Priority && (
            <tr>
              <th>Priority</th>
              <td className={classes.alignRight}>{Priority}</td>
            </tr>
          )}
          {!!Beginning && (
            <tr>
              <th>Beginning</th>
              <td className={classes.alignRight}>{displayDateTime(Beginning)}</td>
            </tr>
          )}
          {!!Ending && (
            <tr>
              <th>Ending</th>
              <td className={classes.alignRight}>{displayDateTime(Ending)}</td>
            </tr>
          )}
          {(Plate || PlateState) && (
            <tr>
              <th>Plate</th>
              <td className={classes.alignRight}>
                {Plate} <strong>{PlateState}</strong>
              </td>
            </tr>
          )}
          {(OLN || OLNState) && (
            <tr>
              <th>OLN</th>
              <td className={classes.alignRight}>
                {OLN} <strong>{OLNState}</strong>
              </td>
            </tr>
          )}
          {!!Ranges && (
            <tr>
              <th>Range of eff.</th>
              <td className={classes.alignRight}>{Ranges}</td>
            </tr>
          )}
          {!!Vehicle && (
            <tr>
              <th>Vehicle</th>
              <td className={classes.alignRight}>{Vehicle}</td>
            </tr>
          )}
          {!!Person && (
            <tr>
              <th>Person</th>
              <td className={classes.alignRight}>{Person}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default BulletinNotification;
