import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { closeSetUnitZone } from '../reducers/DialogsReducer';
import Dialog from '../components/Dialog';
import { getUnitData, setUnitZone } from '../reducers/UnitsReducer';
import { getZoneNames } from '../reducers/ZonesReducer';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.25em',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 12px`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function UnitZone(props) {
  const classes = useStyles();
  const { ptsUnitID, dictionary } = props;
  const { Agencies } = dictionary;
  const [Zone, setZone] = useState(null);
  const [Unit, setUnit] = useState('');
  const [unitInfo, setUnitInfo] = useState(null);
  const [Agency, setAgency] = useState(null);
  const [zones, setZones] = useState([]);
  const [AgencyZones, setAgencyZones] = useState([]);

  useEffect(() => {
    props.showSpinner();
    getZoneNames()
      .then((zones) => setZones(zones))
      .catch((err) => props.handleError(err, 'Cannot acquire zone information'))
      .finally(() => props.hideSpinner());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ptsUnitID || zones.length === 0) return;
    props.showSpinner();
    getUnitData(ptsUnitID)
      .then((unit) => {
        setUnit(unit.Unit);
        setUnitInfo(unit);
        const agency = Agencies.find((a) => a.AgencyID === unit.AgencyID);
        setAgency(agency);
      })
      .catch((err) => props.handleError(err, 'Cannot acquire unit information'))
      .finally(() => props.hideSpinner());
    // eslint-disable-next-line
  }, [zones]);

  useEffect(() => {
    if (!Agency) return;
    const AgencyZones = zones.filter((z) => z.AgencyID === Agency.AgencyID);
    const zone = AgencyZones.find((z) => z.ZoneCode === unitInfo.Zone);
    setAgencyZones(AgencyZones);
    setZone(zone ? zone : null);
    // eslint-disable-next-line
  }, [Agency]);

  const close = () => {
    props.closeSetUnitZone();
  };

  const save = () => {
    const zone = Zone ? Zone.ZoneCode : null;
    props.showSpinner();
    setUnitZone(ptsUnitID, zone)
      .then(() => close())
      .catch((err) => props.handleError(err, 'Error, problems with saving Zone data'))
      .finally(() => props.hideSpinner());
  };

  const renderForm = () => {
    return (
      <div className={classes.form}>
        <Autocomplete
          options={AgencyZones}
          getOptionLabel={(option) => option.ZoneCode}
          value={Zone}
          className={classes.w100pr}
          renderInput={(params) => (
            <TextField {...params} label="Zone" variant="outlined" size="small" />
          )}
          onChange={(event, newValue) => setZone(newValue)}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  return (
    <Dialog open={true} onClose={close} title={'Unit Props'} actions={renderActions()} toolbar>
      <h4 className={classes.title}>{Unit}</h4>
      {renderForm()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeSetUnitZone,
  handleError,
  showSpinner,
  hideSpinner,
})(UnitZone);
