import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8view100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="100"
      height="100">
      <path d="M7 2a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h9.72A7.971 7.971 0 0 1 14 22a8 8 0 0 1 8-8 7.96 7.96 0 0 1 3 .588V9.5a.997.997 0 0 0-.293-.707l-6.5-6.5A1 1 0 0 0 17.5 2H7zm10 1.904L23.096 10H18a1 1 0 0 1-1-1V3.904zM22 16c-3.302 0-6 2.698-6 6s2.698 6 6 6a5.954 5.954 0 0 0 3.469-1.117l2.824 2.824a1 1 0 1 0 1.414-1.414l-2.824-2.824A5.954 5.954 0 0 0 28 22c0-3.302-2.698-6-6-6zm0 2c2.221 0 4 1.779 4 4s-1.779 4-4 4-4-1.779-4-4 1.779-4 4-4z" />
    </SvgIcon>
  );
}

export default icons8view100Icon;
