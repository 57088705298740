import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8splitup100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M1 6v2h19.764l1.713 4.28-3.256 3.255 3.166 5.283-2.897 3.868 2.225 4.449-1.895 4.732L22.326 42H14V28h1v10h1c1.1 0 2-.9 2-2V25.5c0-2.48-2.02-4.5-4.5-4.5h-8C3.02 21 1 23.02 1 25.5V36c0 1.1.9 2 2 2h1V28h1v14H1v2h24.035L21.94 33.953l1.967-4.908-2.076-4.15 2.963-3.954-3.055-5.095 3.086-3.086L22.117 6H1zm23.283 0 2.895 7.238-2.918 2.918 2.947 4.903-3.037 4.045 1.926 3.851-2.035 5.092L27.12 44H49v-2h-3.69l-2.8-13.9.98-.2 2.01 9.98c.82-.21 1.52-.73 1.96-1.44.46-.76.59-1.68.36-2.49l-1.61-6.88A7.825 7.825 0 0 0 38.56 21h-.12c-3.67 0-6.82 2.5-7.65 6.07l-1.6 6.84a3.212 3.212 0 0 0 2.22 3.95l2.1-9.96.98.2L31.56 42H28.6l-2.42-7.867 2.105-5.268-1.773-3.549 3.101-4.134-2.834-4.717 2.744-2.744L27.238 8H49V6H24.283zM9.5 11c-2.485 0-4.45 2.015-4.45 4.5S7.016 20 9.5 20a4.5 4.5 0 0 0 0-9zm29 0c-2.485 0-4.45 2.015-4.45 4.5S36.016 20 38.5 20a4.5 4.5 0 0 0 0-9zM9 38h1v4H9v-4z" />
    </SvgIcon>
  );
}

export default icons8splitup100Icon;
