import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '../../components/Tooltip';
import Button from '@material-ui/core/Button';
import { insertText } from '../../utils/functions';
import { closeEditPageTemplate } from '../../reducers/DialogsReducer';
import Dialog from '../../components/Dialog';
import { saveTemplate, updateTemplates, getTemplate } from '../../reducers/PagingReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { handleError } from '../../reducers/ErrorReducer';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

const snippets = [
  { Description: 'Event ID', Code: '{EventID}' },
  { Description: 'Event Type', Code: '{EventType}' },
  { Description: 'Address', Code: '{Address}' },
  { Description: 'Date', Code: '{Date}' },
  { Description: 'Time', Code: '{Time}' },
];

const useStyles = makeStyles((theme) => ({
  card: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  addressFilter: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
  textArea: {
    fontSize: 12,
  },
  formBtn: {
    margin: `0 4px 8px`,
    minWidth: 0,
    paddingRight: 8,
    paddingLeft: 8,
  },
}));

function TemplateEdit(props) {
  const classes = useStyles();
  const { ROWGUID } = props;
  const [data, setData] = useState(null);
  const [Code, setCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Template, setTemplate] = useState('');
  const [IsDefault, setIsDefault] = useState(false);
  const [IsActive, setIsActive] = useState(true);
  const [snippet, setSnippet] = useState(null);
  const [editedValid, setEditedValid] = useState(false);
  const templateRef = useRef();
  const subjectRef = useRef();

  useEffect(() => {
    if (!ROWGUID) return;
    if (ROWGUID === true) {
      setData({
        Code: '',
        Description: '',
        Template: '',
        IsDefault: false,
        IsActive: true,
        ROWGUID: null,
      });
    } else {
      props.showSpinner();
      getTemplate(ROWGUID)
        .then((result) => setData(result))
        .catch((err) => props.handleError(err, 'Unable to get template data'))
        .finally(() => props.hideSpinner());
    }
    // eslint-disable-next-line
  }, [ROWGUID]);

  useEffect(() => {
    if (!data) return;
    setCode(data.Code);
    setDescription(data.Description);
    setTemplate(data.Template);
    setIsDefault(data.IsDefault);
    setIsActive(data.IsActive);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [Code, Description, Template, IsDefault, IsActive]);

  const handleCodeChange = (ev) => {
    const value = ev.target.value;
    if (value.length > 10) return;
    setCode(value);
  };

  const handleDescriptionChange = (ev) => {
    const value = ev.target.value;
    if (value.length > 100) return;
    setDescription(value);
  };

  const handleTemplateChange = (ev) => {
    const value = ev.target.value;
    if (value.length > 200) return;
    setTemplate(value);
  };

  const insertSnippet = () => {
    const cursorPos = templateRef.current.selectionStart;
    if (!snippet) return;
    setTemplate(insertText(Template, cursorPos, snippet.Code));
  };

  const insertSubjectSnippet = () => {
    const cursorPos = subjectRef.current.selectionStart;
    if (!snippet) return;
    setDescription(insertText(Description, cursorPos, snippet.Code));
  };

  const closeEdit = () => {
    props.closeEditPageTemplate();
  };

  const save = () => {
    props.showSpinner();
    saveTemplate({ Code, Description, Template, IsDefault, IsActive, ROWGUID })
      .then(() => props.updateTemplates())
      .catch((err) => props.handleError(err, 'Unable to save template data'))
      .finally(() => props.hideSpinner());
    closeEdit();
  };

  const validate = () => {
    let valid = true;
    if (!Code) valid = false;
    if (!Template) valid = false;
    if (valid !== editedValid) {
      setEditedValid(valid);
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          disabled={!editedValid}>
          <SaveIcon /> Save
        </Button>
        <Button onClick={props.closeEditPageTemplate} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const renderTemplateForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={clsx(classes.textField, classes.w100pr)}
          label="Code"
          value={Code}
          onChange={handleCodeChange}
          variant="outlined"
          size="small"
        />
        <TextField
          className={clsx(classes.textField, classes.w100pr)}
          label="Subject"
          value={Description}
          onChange={handleDescriptionChange}
          variant="outlined"
          size="small"
          inputRef={subjectRef}
        />
        <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={
            <Checkbox
              checked={IsDefault}
              onChange={() => setIsDefault(!IsDefault)}
              className={classes.checkbox}
            />
          }
          label="Default"
        />
        <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={
            <Checkbox
              checked={IsActive}
              onChange={() => setIsActive(!IsActive)}
              className={classes.checkbox}
            />
          }
          label="Active"
        />
        <Autocomplete
          options={snippets}
          value={snippet}
          getOptionLabel={(option) => option.Description}
          getOptionSelected={(option, value) => option.Code === value.Code}
          onChange={(event, newValue) => setSnippet(newValue)}
          className={classes.w120x180}
          renderInput={(params) => <TextField {...params} label="Snippet" variant="outlined" />}
          size="small"
        />
        <Tooltip title="Insert snippet to template">
          <span>
            <Button className={classes.formBtn} onClick={insertSnippet} disabled={!snippet}>
              <AddIcon /> Template
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Insert snippet to subject">
          <span>
            <Button className={classes.formBtn} onClick={insertSubjectSnippet} disabled={!snippet}>
              <AddIcon /> Subject
            </Button>
          </span>
        </Tooltip>
        <TextField
          className={clsx(classes.textField, classes.w100pr)}
          label="Template"
          value={Template}
          onChange={handleTemplateChange}
          variant="outlined"
          size="small"
          multiline
          rows={10}
          style={{ marginTop: 5 }}
          inputRef={templateRef}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </div>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={closeEdit}
      title={ROWGUID !== true ? 'Edit Template' : 'Add Template'}
      actions={renderActions()}
      toolbar>
      {renderTemplateForm()}
    </Dialog>
  );
}

export default connect(null, {
  closeEditPageTemplate,
  updateTemplates,
  showSpinner,
  hideSpinner,
  handleError,
})(TemplateEdit);
