import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8elderlyperson100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M29 2c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5Zm-8.469 6.094c-.633.039-1.176.219-1.625.594-4.3 3.601-6.617 14.414-6.718 14.812-.399 2 .105 3.887 1.406 5.188l6.219 6.812L23 45.688c.102.699.488 1.414 1.188 1.812.5.3 1.023.5 1.625.5h.5c1.5-.3 2.582-1.71 2.28-3.313l-3.687-11.374s.008-.126-.093-.126L20 26.407c0-.9.113-5.4 2.313-9l7.187 10.5c.8 1.1 2.3 1.488 3.5.687.602-.399.992-.895 1.094-1.594.101-.7-.008-1.398-.407-2L23.407 9.5a3.013 3.013 0 0 0-2.22-1.406 4.089 4.089 0 0 0-.656 0Zm13.063 22.312c-.602.301-1.207.5-1.907.5L36 48h2ZM15.187 33.5 11.5 44.406c-.5 1.399.29 3 1.688 3.5.3.102.605.188.906.188.398 0 .82-.082 1.219-.282.699-.3 1.175-.925 1.374-1.624l2.22-6.782L18 36.594Z" />
    </SvgIcon>
  );
}

export default icons8elderlyperson100Icon;
