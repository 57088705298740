import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import FormDivider from 'components/FormDivider';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { getPtsPerson, addOrEditPerson } from 'reducers/SearchReducer';
import { dateTimePicker, formatSaveDate } from 'reducers/TimeReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import AddressLookup from 'components/AddressLookup';
import { decodePhoneNo, validatePhoneNo, validateEmail } from 'utils/formStyles';
import TextField2 from 'components/TextField2';
import { useError } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { closeAddPerson, closeEditPerson, showEditPerson } from 'reducers/DialogsReducer';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

function PersonTab(props) {
  const classes = useStyles();
  const { personId, close } = props;
  const {
    NamePrefixes,
    NameSuffixes,
    DeathCauses,
    Gang,
    MaritalStatus,
    Languages,
    DangerousType,
    Resident,
    Citizenships,
    Nationalities,
    Ethnicity,
    EyeColor,
    Handed,
    BloodType,
    HairColor,
    HairLength,
    HairStyle,
    Sex,
    Race,
    Counties,
    Countries,
    Cities,
    States,
    ContactMethodTypes,
    ContactMethods,
  } = props.dictionary;

  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [nickname, setNickname] = useState('');
  const [prefix, setPrefix] = useState(null);
  const [suffix, setSuffix] = useState(null);
  const [genApp, setGenApp] = useState('');
  const [race, setRace] = useState(null);
  const [sex, setSex] = useState(null);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [birthdate, setBirthdate] = useState(null);
  const [birthCity, setBirthCity] = useState(null);
  const [birthCounty, setBirthCounty] = useState(null);
  const [birthCountry, setBirthCountry] = useState(null);
  const [isDeceased, setIsDeceased] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState(null);
  const [causeOfDeath, setCauseOfDeath] = useState(null);
  const [localResident, setLocalResident] = useState(null);
  const [isUSCitizen, setIsUSCitizen] = useState(true);
  const [currentCitizenship, setCurrentCitizenship] = useState(null);
  const [birthCitizenship, setBirthCitizenship] = useState(null);
  const [birthNationality, setBirthNationality] = useState(null);
  const [gang, setGang] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [educationLevel, setEducationLevel] = useState('');
  const [allergies, setAllergies] = useState('');
  const [dna, setDna] = useState('');
  const [notes, setNotes] = useState('');
  const [primarylanguage, setPrimaryLanguage] = useState(null);
  const [whyDangerous, setWhyDangerous] = useState(null);
  const [fpAvailable, setFpAvailable] = useState(false);
  const [overrideJuvenile, setOverrideJuvenile] = useState(false);
  const [publicOfficial, setPublicOfficial] = useState(false);
  const [englishSpeaking, setEnglishSpeaking] = useState(false);
  const [digitalImage, setDigitalImage] = useState(false);
  const [inJail, setInJail] = useState(false);
  const [carryWeapon, setCarryWeapon] = useState(false);
  const [isDangerous, setIsDangerous] = useState(false);
  const [organDonor, setOrganDonor] = useState(false);
  const [exFelon, setExFelon] = useState(false);
  const [protectiveOrder, setProtectiveOrder] = useState(false);
  const [seniorCitizen, setSeniorCitizen] = useState(false);
  const [officer, setOfficer] = useState(false);
  const [sexOffender, setSexOffender] = useState(false);
  const [juvenile, setJuvenile] = useState(false);
  const [military, setMilitary] = useState(false);
  const [probation, setProbation] = useState(false);
  const [ethnicity, setEthnicity] = useState(null);
  const [eyecolor, setEyecolor] = useState(null);
  const [handed, setHanded] = useState(null);
  const [bloodType, setBloodType] = useState(null);
  const [haircolor, setHaircolor] = useState(null);
  const [hairlength, setHairlength] = useState(null);
  const [hairstyle, setHairStyle] = useState(null);
  const [ssn, setSsn] = useState('');
  const [oln, setOln] = useState('');
  const [state, setState] = useState(null);
  const [address, setAddress] = useState('');
  const [isPrimary, setIsPrimary] = useState(false);
  const [contactType, setContactType] = useState(null);
  const [contactMethodType, setContactMethodType] = useState(null);
  const [info, setInfo] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [contactNotes, setContactNotes] = useState('');
  const [errors, setErrors] = useState();
  const { err } = useError(errors, setErrors);
  const dispatch = useDispatch();
  const isNewRecord = !personId;

  useEffect(() => {
    if (personId) fetchPersonData();
  }, [personId]);

  const fetchPersonData = async () => {
    const data = await getPtsPerson(personId);
    const {
      Age,
      Allergies,
      BirthCitizenship,
      BirthCountry,
      BirthCounty,
      BirthNationality,
      BirthState,
      BloodType: fetchedBloodType,
      Born,
      CauseOfDeath,
      CurrentCitizenship,
      DNA,
      Dangerous,
      Deceased,
      Died,
      EducationLevel,
      EnglishLangIndicator,
      Ethnicity: fetchedEthnicity,
      ExFelon,
      EyeColor: fetchedEyeColor,
      FpString,
      FirstName,
      Gang: fetchedGang,
      GeneralAppearance,
      HairColor: fetchedHairColor,
      HairLength: fetchedHairLength,
      HairStyle: fetchedHairStyle,
      Handed: fetchedHanded,
      Height,
      IsOrganDonor,
      IsUSCitizen,
      Juvenile,
      LastName,
      LocalResident,
      MaritalStatus: fetchedMaritalStatus,
      MiddleName,
      Military,
      NickName,
      Notes,
      Officer,
      OverrideJuvenile,
      Prefix,
      PrimaryLanguage,
      Probation,
      ProtectiveOrder,
      PublicOfficial,
      Race: fetchedRace,
      SeniorCitizen,
      Sex: fetchedSex,
      SexOffender,
      Suffix,
      Weight,
      WhyDangerous,
      ptsBirthCityID,
      HasDigitalImage,
      Incarcerated,
      ConcealedWeaponPermit,
      PrintsAvailable,
    } = data[0];
    setFirstname(FirstName || '');
    setMiddlename(MiddleName || '');
    setLastname(LastName || '');
    setNickname(NickName || '');
    const prefix = NamePrefixes.find((p) => p.Code === Prefix);
    setPrefix(prefix || null);
    const suffix = NameSuffixes.find((s) => s.Code === Suffix);
    setSuffix(suffix || null);
    setGenApp(GeneralAppearance || '');
    const race = Race.find((r) => r.Code === fetchedRace);
    setRace(race || null);
    const sex = Sex.find((s) => s.Code === fetchedSex);
    setSex(sex || null);
    setHeight(Height || '');
    setWeight(Weight || '');
    setBirthdate(dateTimePicker(Born) || null);
    // setAge(Age);
    const city = Cities.find((c) => c.ptsCityID === ptsBirthCityID);
    setBirthCity(city || null);
    const country = Countries.find((c) => c.Code === BirthCountry);
    setBirthCountry(country || null);
    const county = Counties.find((c) => c.Code === BirthCounty);
    setBirthCounty(county || null);
    setIsDeceased(Deceased);
    setDeceasedDate(dateTimePicker(Died) || null);
    const deathCause = DeathCauses.find((d) => d.Code === CauseOfDeath);
    setCauseOfDeath(deathCause || null);
    const resident = Resident.find((r) => r.Code === LocalResident);
    setLocalResident(resident || null);
    setIsUSCitizen(IsUSCitizen);
    const curCitizen = Citizenships.find((c) => c.Code === CurrentCitizenship);
    setCurrentCitizenship(curCitizen || null);
    const birthCitizen = Citizenships.find((c) => c.Code === BirthCitizenship);
    setBirthCitizenship(birthCitizen || null);
    const nationality = Nationalities.find((n) => n.Code === BirthNationality);
    setBirthNationality(nationality || null);
    const gang = Gang.find((g) => g.Code === fetchedGang);
    setGang(gang || null);
    const marital = MaritalStatus.find((m) => m.Code === fetchedMaritalStatus);
    setMaritalStatus(marital || null);
    setEducationLevel(EducationLevel || '');
    setAllergies(Allergies || '');
    setDna(DNA || '');
    setNotes(Notes || '');
    const primLanguage = Languages.find((l) => l.Code === PrimaryLanguage);
    setPrimaryLanguage(primLanguage || null);
    const dangerCause = DangerousType.find((d) => d.Code === WhyDangerous);
    setWhyDangerous(dangerCause || null);
    setFpAvailable(PrintsAvailable);
    setOverrideJuvenile(OverrideJuvenile);
    setPublicOfficial(PublicOfficial);
    setEnglishSpeaking(EnglishLangIndicator);
    setDigitalImage(HasDigitalImage);
    setInJail(Incarcerated);
    setCarryWeapon(ConcealedWeaponPermit);
    setIsDangerous(Dangerous);
    setOrganDonor(IsOrganDonor);
    setExFelon(ExFelon);
    setProtectiveOrder(ProtectiveOrder);
    setSeniorCitizen(SeniorCitizen);
    setOfficer(Officer);
    setSexOffender(SexOffender);
    setJuvenile(Juvenile);
    setMilitary(Military);
    setProbation(Probation);
    const ethnicity = Ethnicity.find((e) => e.Code === fetchedEthnicity);
    setEthnicity(ethnicity || null);
    const eyeColor = EyeColor.find((e) => e.Code === fetchedEyeColor);
    setEyecolor(eyeColor || null);
    const handed = Handed.find((h) => h.Code === fetchedHanded);
    setHanded(handed || null);
    const bloodtype = BloodType.find((b) => b.Code === fetchedBloodType);
    setBloodType(bloodtype || null);
    const hairColor = HairColor.find((h) => h.Code == fetchedHairColor);
    setHaircolor(hairColor || null);
    const hairLength = HairLength.find((h) => h.Code === fetchedHairLength);
    setHairlength(hairLength || null);
    const hairStyle = HairStyle.find((h) => h.Code === fetchedHairStyle);
    setHairStyle(hairStyle || null);
  };

  const save = async () => {
    const FullName = `${firstname} ` + (middlename ? `${middlename} ` : '') + lastname;

    let data = {
      person: {
        FirstName: firstname || null,
        MiddleName: middlename || null,
        LastName: lastname || null,
        FullName,
        NickName: nickname || null,
        Prefix: prefix ? prefix.Code : null,
        Suffix: suffix ? suffix.Code : null,
        GeneralAppearance: genApp || null,
        Race: race ? race.Code : null,
        Sex: sex ? sex.Code : null,
        Height: height || null,
        Weight: weight || null,
        Born: formatSaveDate(birthdate),
        ptsBirthCityID: birthCity ? birthCity.ptsCityID : null,
        BirthCounty: birthCounty ? birthCounty.Code : null,
        BirthCountry: birthCountry ? birthCountry.Code : null,
        Deceased: isDeceased,
        Died: formatSaveDate(deceasedDate),
        CauseOfDeath: causeOfDeath ? causeOfDeath.Code : null,
        LocalResident: localResident ? localResident.Code : null,
        IsUSCitizen: isUSCitizen,
        CurrentCitizenship: currentCitizenship ? currentCitizenship.Code : null,
        BirthCitizenship: birthCitizenship ? birthCitizenship.Code : null,
        BirthNationality: birthNationality ? birthNationality.Code : null,
        Gang: gang ? gang.Code : null,
        MaritalStatus: maritalStatus ? maritalStatus.Code : null,
        EducationLevel: educationLevel || null,
        Allergies: allergies || null,
        DNA: dna || null,
        Notes: notes || null,
        PrimaryLanguage: primarylanguage ? primarylanguage.Code : null,
        WhyDangerous: whyDangerous ? whyDangerous.Code : null,
        PrintsAvailable: fpAvailable,
        OverrideJuvenile: overrideJuvenile,
        PublicOfficial: publicOfficial,
        HasDigitalImage: digitalImage,
        Incarcerated: inJail,
        ConcealedWeaponPermit: carryWeapon,
        Dangerous: isDangerous,
        IsOrganDonor: organDonor,
        ExFelon: exFelon,
        ProtectiveOrder: protectiveOrder,
        SeniorCitizen: seniorCitizen,
        Officer: officer,
        SexOffender: sexOffender,
        Juvenile: juvenile,
        Military: military,
        Probation: probation,
        Ethnicity: ethnicity ? ethnicity.Code : null,
        EyeColor: eyecolor ? eyecolor.Code : null,
        Handed: handed ? handed.Code : null,
        BloodType: bloodType ? bloodType.Code : null,
        HairColor: haircolor ? haircolor.Code : null,
        HairLength: hairlength ? hairlength.Code : null,
        HairStyle: hairstyle ? hairstyle.Code : null,
        EnglishLangIndicator: englishSpeaking,
        ActiveWarrant: false,
        HasDigitizedSignatureImage: false,
      },
    };
    if (!personId) {
      const contactCreds = {
        contact: {
          ContactMethod: contactMethodType ? contactMethodType.Code : null, // mail, phone
          MethodType: contactType ? contactType.Code : null,
          Info: info || null,
          IsDefault: isDefault,
          Notes: contactNotes || null,
          ptsAddressID: address ? address.ptsAddressID : null,
          IsPrimary: isPrimary,
          PartyType: 'Person',
        },
        credentials: {
          SSN: ssn,
          OLN: oln,
          State: state ? state.Code : null,
        },
      };
      Object.assign(data, contactCreds);
    }

    if (contactMethodType && contactMethodType.Code === 'Phone' && info.length > 10) {
      data = { ...data, contact: { ...data.contact, ...decodePhoneNo(info) } };
    }

    try {
      const ptsPersonID = await addOrEditPerson(personId, data);
      dispatch(
        notifyDataUpdate({
          type: 'Person',
          data: { action: personId ? 'Update' : 'Create', ptsPersonID },
        })
      );
      props.notify('Person data saved', 'success');
      if (isNewRecord) {
        props.closeAddPerson();
        props.showEditPerson(ptsPersonID);
      }
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!firstname || !lastname}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderPrefix = () => {
    const onChange = (ev, val) => setPrefix(val);
    return (
      <Dictionary
        options="NamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={prefix}
        label="Prefix"
      />
    );
  };

  const renderSuffix = () => {
    const onChange = (ev, val) => setSuffix(val);
    return (
      <Dictionary
        options="NameSuffixes"
        className={classes.item}
        onChange={onChange}
        value={suffix}
        label="Suffix"
      />
    );
  };

  const renderFirstname = () => {
    return (
      <TextField
        label="First Name"
        variant="outlined"
        onChange={(e) => setFirstname(e.target.value)}
        value={firstname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderMiddleName = () => {
    return (
      <TextField
        label="Middle Name"
        variant="outlined"
        onChange={(e) => setMiddlename(e.target.value)}
        value={middlename}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderLastName = () => {
    return (
      <TextField
        label="Last Name"
        variant="outlined"
        onChange={(e) => setLastname(e.target.value)}
        value={lastname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderNickname = () => {
    return (
      <TextField
        label="Nick Name"
        variant="outlined"
        onChange={(e) => setNickname(e.target.value)}
        value={nickname}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderGeneralAppearance = () => {
    return (
      <TextField
        label="General Appearance"
        variant="outlined"
        onChange={(e) => setGenApp(e.target.value)}
        value={genApp}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderHeight = () => {
    const handleHeight = (e) => setHeight(e.target.value);
    return (
      <TextField
        label="Height"
        variant="outlined"
        onChange={handleHeight}
        value={height}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderWeight = () => {
    return (
      <TextField
        label="Weight"
        variant="outlined"
        onChange={(e) => setWeight(e.target.value)}
        value={weight}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (
      <Dictionary
        options="Sex"
        className={classes.item}
        onChange={onChange}
        value={sex}
        label="Sex"
      />
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (
      <Dictionary
        options="Race"
        className={classes.item}
        onChange={onChange}
        value={race}
        label="Race"
      />
    );
  };

  const renderBirthDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Birth Date"
          value={birthdate}
          onChange={(date) => setBirthdate(date)}
          autoOk
          size="small"
          className={classes.item}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDeceased = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDeceased}
            onChange={() => setIsDeceased(!isDeceased)}
            color="primary"
          />
        }
        label="Deceased"
        className={classes.item}
      />
    );
  };

  const renderBirthCity = () => {
    const onChange = (ev, val) => setBirthCity(val);
    return (
      <Dictionary
        options="Cities"
        className={classes.item}
        onChange={onChange}
        value={birthCity}
        label="Birth City"
      />
    );
  };

  const renderBirthCounty = () => {
    const onChange = (ev, val) => setBirthCounty(val);
    return (
      <Dictionary
        options="Counties"
        className={classes.item}
        onChange={onChange}
        value={birthCounty}
        label="Birth County"
      />
    );
  };

  const renderBirthCountry = () => {
    const onChange = (ev, val) => setBirthCountry(val);
    return (
      <Dictionary
        options="Countries"
        className={classes.item}
        onChange={onChange}
        value={birthCountry}
        label="Birth Country"
      />
    );
  };

  const renderDeceasedDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Deceased Date"
          value={deceasedDate}
          onChange={(date) => setDeceasedDate(date)}
          autoOk
          size="small"
          className={classes.item}
          disabled={!isDeceased}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderCauseOfDeath = () => {
    const onChange = (ev, val) => setCauseOfDeath(val);
    return (
      <Dictionary
        options="DeathCauses"
        className={classes.item}
        onChange={onChange}
        value={causeOfDeath}
        label="Cause of Death"
      />
    );
  };

  const renderUsCitizen = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isUSCitizen}
            onChange={() => setIsUSCitizen(!isUSCitizen)}
            color="primary"
          />
        }
        label="US Citizen"
        className={classes.item}
      />
    );
  };

  const renderLocalResident = () => {
    const onChange = (ev, val) => setLocalResident(val);
    return (
      <Dictionary
        options="Resident"
        className={classes.item}
        onChange={onChange}
        value={localResident}
        label="Local Resident"
      />
    );
  };

  const renderCurrentCitizenship = () => {
    const onChange = (ev, val) => setCurrentCitizenship(val);
    return (
      <Dictionary
        options="Citizenships"
        className={classes.item}
        onChange={onChange}
        value={currentCitizenship}
        label="Current Citizenship"
      />
    );
  };

  const renderBirthCitizenship = () => {
    const onChange = (ev, val) => setBirthCitizenship(val);
    return (
      <Dictionary
        options="Citizenships"
        className={classes.item}
        onChange={onChange}
        value={birthCitizenship}
        label="Birth Citizenship"
      />
    );
  };

  const renderBirthNationality = () => {
    const onChange = (ev, val) => setBirthNationality(val);
    return (
      <Dictionary
        options="Nationalities"
        className={classes.item}
        onChange={onChange}
        value={birthNationality}
        label="Birth Nationality"
      />
    );
  };

  const renderGang = () => {
    const onChange = (ev, val) => setGang(val);
    return (
      <Dictionary
        options="Gang"
        className={classes.item}
        onChange={onChange}
        value={gang}
        label="Gang"
      />
    );
  };

  const renderMaritalStatus = () => {
    const onChange = (ev, val) => setMaritalStatus(val);
    return (
      <Dictionary
        options="MaritalStatus"
        className={classes.item}
        onChange={onChange}
        value={maritalStatus}
        label="Marital Status"
      />
    );
  };

  const renderEducationLevel = () => {
    return (
      <TextField
        label="Education Level"
        variant="outlined"
        onChange={(e) => setEducationLevel(e.target.value)}
        value={educationLevel}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderAllergies = () => {
    return (
      <TextField
        label="Allergies"
        variant="outlined"
        onChange={(e) => setAllergies(e.target.value)}
        value={allergies}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderDna = () => {
    return (
      <TextField
        label="DNA"
        variant="outlined"
        onChange={(e) => setDna(e.target.value)}
        value={dna}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Person Notes"
        variant="outlined"
        onChange={(e) => setNotes(e.target.value)}
        value={notes}
        size="small"
        className={classes.item}
        multiline
        rows={5}
      />
    );
  };

  const renderPrimaryLanguage = () => {
    const onChange = (ev, val) => setPrimaryLanguage(val);
    return (
      <Dictionary
        options="Languages"
        className={classes.item}
        onChange={onChange}
        value={primarylanguage}
        label="Primary Language"
      />
    );
  };

  const renderWhyDangerous = () => {
    const onChange = (ev, val) => setWhyDangerous(val);
    return (
      <Dictionary
        options="DangerousType"
        className={classes.item}
        onChange={onChange}
        value={whyDangerous}
        label="Why Dangerous"
        disabled={!isDangerous}
      />
    );
  };

  const renderFingerPrintAvailable = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={fpAvailable}
            onChange={() => setFpAvailable(!fpAvailable)}
            color="primary"
          />
        }
        label="Finger Print Available"
        className={classes.item}
      />
    );
  };

  const renderOverrideJuvenile = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={overrideJuvenile}
            onChange={() => setOverrideJuvenile(!overrideJuvenile)}
            color="primary"
          />
        }
        label="Override Juvenile"
        className={classes.item}
      />
    );
  };

  const renderPublicOfficial = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={publicOfficial}
            onChange={() => setPublicOfficial(!publicOfficial)}
            color="primary"
          />
        }
        label="Public Official"
        className={classes.item}
      />
    );
  };

  const renderEnglishSpeaking = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={englishSpeaking}
            onChange={() => setEnglishSpeaking(!englishSpeaking)}
            color="primary"
          />
        }
        label="English Speaking"
        className={classes.item}
      />
    );
  };

  const renderDangerous = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDangerous}
            onChange={() => setIsDangerous(!isDangerous)}
            color="primary"
          />
        }
        label="Dangerous"
        className={classes.item}
      />
    );
  };

  const renderDigitalImage = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={digitalImage}
            onChange={() => setDigitalImage(!digitalImage)}
            color="primary"
          />
        }
        label="Digital Image"
        className={classes.item}
      />
    );
  };

  const renderInJail = () => {
    return (
      <FormControlLabel
        control={<Checkbox checked={inJail} onChange={() => setInJail(!inJail)} color="primary" />}
        label="In Jail"
        className={classes.item}
      />
    );
  };

  const renderCarryWeapon = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={carryWeapon}
            onChange={() => setCarryWeapon(!carryWeapon)}
            color="primary"
          />
        }
        label="Carry Weapon"
        className={classes.item}
      />
    );
  };

  const renderOrganDonor = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={organDonor}
            onChange={() => setOrganDonor(!organDonor)}
            color="primary"
          />
        }
        label="Organ Donor"
        className={classes.item}
      />
    );
  };

  const renderExFelon = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={exFelon} onChange={() => setExFelon(!exFelon)} color="primary" />
        }
        label="Ex Felon"
        className={classes.item}
      />
    );
  };

  const renderProtectiveOrder = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={protectiveOrder}
            onChange={() => setProtectiveOrder(!protectiveOrder)}
            color="primary"
          />
        }
        label="Protective Order"
        className={classes.item}
      />
    );
  };

  const renderSeniorCitizen = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={seniorCitizen}
            onChange={() => setSeniorCitizen(!seniorCitizen)}
            color="primary"
          />
        }
        label="Senior Citizen"
        className={classes.item}
      />
    );
  };

  const renderOfficer = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={officer} onChange={() => setOfficer(!officer)} color="primary" />
        }
        label="Officer"
        className={classes.item}
      />
    );
  };

  const renderSexOffender = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={sexOffender}
            onChange={() => setSexOffender(!sexOffender)}
            color="primary"
          />
        }
        label="Sex Offender"
        className={classes.item}
      />
    );
  };

  const renderJuvenile = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={juvenile} onChange={() => setJuvenile(!juvenile)} color="primary" />
        }
        label="Juvenile"
        className={classes.item}
      />
    );
  };

  const renderMilitary = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={military} onChange={() => setMilitary(!military)} color="primary" />
        }
        label="Military"
        className={classes.item}
      />
    );
  };

  const renderProbation = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={probation} onChange={() => setProbation(!probation)} color="primary" />
        }
        label="Probation"
        className={classes.item}
      />
    );
  };

  const renderEthnicity = () => {
    const onChange = (ev, val) => setEthnicity(val);
    return (
      <Dictionary
        options="Ethnicity"
        className={classes.item}
        onChange={onChange}
        value={ethnicity}
        label="Ethnicity"
      />
    );
  };

  const renderEyeColor = () => {
    const onChange = (ev, val) => setEyecolor(val);
    return (
      <Dictionary
        options="EyeColor"
        className={classes.item}
        onChange={onChange}
        value={eyecolor}
        label="Eye Color"
      />
    );
  };

  const renderHanded = () => {
    const onChange = (ev, val) => setHanded(val);
    return (
      <Dictionary
        options="Handed"
        className={classes.item}
        onChange={onChange}
        value={handed}
        label="Handed"
      />
    );
  };

  const renderBloodType = () => {
    const onChange = (ev, val) => setBloodType(val);
    return (
      <Dictionary
        options="BloodType"
        className={classes.item}
        onChange={onChange}
        value={bloodType}
        label="Blood Type"
      />
    );
  };

  const renderHairColor = () => {
    const onChange = (ev, val) => setHaircolor(val);
    return (
      <Dictionary
        options="HairColor"
        className={classes.item}
        onChange={onChange}
        value={haircolor}
        label="Hair Color"
      />
    );
  };

  const renderHairLength = () => {
    const onChange = (ev, val) => setHairlength(val);
    return (
      <Dictionary
        options="HairLength"
        className={classes.item}
        onChange={onChange}
        value={hairlength}
        label="Hair Length"
      />
    );
  };

  const renderHairStyle = () => {
    const onChange = (ev, val) => setHairStyle(val);
    return (
      <Dictionary
        options="HairStyle"
        className={classes.item}
        onChange={onChange}
        value={hairstyle}
        label="Hair Style"
      />
    );
  };

  const renderSsn = () => {
    return (
      <TextField
        label="SSN"
        variant="outlined"
        onChange={(e) => setSsn(e.target.value)}
        value={ssn}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderOln = () => {
    return (
      <TextField
        label="OLN"
        variant="outlined"
        onChange={(e) => setOln(e.target.value)}
        value={oln}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => setState(val);
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange}
        value={state}
        label="State"
      />
    );
  };

  const renderAddressLookup = () => {
    const clearPlace = () => setAddress(null);
    return (
      <AddressLookup
        className={classes.item}
        ptsPlaces={false}
        ptsAddresses={true}
        googleAddresses={false}
        onAddressValueSet={setAddress}
        defaultLocation={null}
        onReset={clearPlace}
        label="Address Lookup"
      />
    );
  };

  const renderPrimary = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={isPrimary} onChange={() => setIsPrimary(!isPrimary)} color="primary" />
        }
        label="Primary"
        className={classes.item}
      />
    );
  };

  const renderContactType = () => {
    const onChange = (ev, val) => setContactType(val);
    return (
      <Dictionary
        options="ContactMethodTypes"
        className={classes.item}
        onChange={onChange}
        value={contactType}
        label="Contact Type"
      />
    );
  };

  const renderMethodType = () => {
    const onChange = (ev, val) => setContactMethodType(val);
    return (
      <Dictionary
        options="ContactMethods"
        className={classes.item}
        onChange={onChange}
        value={contactMethodType}
        label="Method Type"
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setInfo(val);
    };
    const type = contactMethodType?.Code;
    let error;
    if (type === 'Phone') {
      error = err(validatePhoneNo(info), 'Info', 'Invalid phone number');
    }
    if (type === 'Email') {
      error = err(validateEmail(info), 'Info', 'Invalid email address');
    }

    return (
      <TextField2
        className={classes.item}
        label="Info"
        value={info}
        onChange={onChange}
        max={100}
        error={error}
        type={type}
        disabled={!contactMethodType}
      />
    );
  };

  const renderDefault = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={isDefault} onChange={() => setIsDefault(!isDefault)} color="primary" />
        }
        label="Default"
        className={classes.item}
      />
    );
  };

  const renderContactNotes = () => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={(e) => setContactNotes(e.target.value)}
        value={contactNotes}
        size="small"
        className={classes.item}
      />
    );
  };

  return (
    <>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Name" />
          <RowInner>
            {renderPrefix()}
            {renderFirstname()}
            {renderMiddleName()}
            {renderLastName()}
            {renderSuffix()}
            {renderNickname()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="General Details" />
          <RowInner>
            {renderGeneralAppearance()}
            {renderRace()}
            {renderSex()}
            {renderHeight()}
            {renderWeight()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Age" />
          <RowInner>
            {renderBirthDate()}
            {/* {renderAge()} */}
            {renderBirthCity()}
            {renderBirthCounty()}
            {renderBirthCountry()}
            {renderDeceased()}
            {renderDeceasedDate()}
            {renderCauseOfDeath()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Citizenship" />
          <RowInner>
            {renderLocalResident()}
            {renderUsCitizen()}
            {renderCurrentCitizenship()}
            {renderBirthCitizenship()}
            {renderBirthNationality()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Advanced Details" />
          <RowInner>
            {renderGang()}
            {renderMaritalStatus()}
            {renderEducationLevel()}
            {renderAllergies()}
            {renderDna()}
            <Fills className={classes.item} />
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Features" />
          <RowInner>
            {renderEthnicity()}
            {renderEyeColor()}
            {renderHanded()}
            {renderBloodType()}
            {renderHairColor()}
            {renderHairLength()}
            {renderHairStyle()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      {!personId && (
        <>
          <Row>
            <ColCard minWidth={500}>
              <FormDivider title="Credentials" />
              <RowInner>
                {renderSsn()}
                {renderOln()}
                {renderState()}
                <Fills className={classes.item} />
              </RowInner>
            </ColCard>
            <ColCard minWidth={500}>
              <FormDivider title="Contact" />
              <RowInner>
                {renderAddressLookup()}
                {renderPrimary()}
                {renderContactType()}
                {renderMethodType()}
                {renderInfo()}
                {renderDefault()}
                {renderContactNotes()}
                <Fills className={classes.item} />
              </RowInner>
            </ColCard>
          </Row>
        </>
      )}
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Flags" />
          <RowInner>
            {renderFingerPrintAvailable()}
            {renderOverrideJuvenile()}
            {renderPublicOfficial()}
            {renderEnglishSpeaking()}
            {renderPrimaryLanguage()}
            {renderDigitalImage()}
            {renderInJail()}
            {renderCarryWeapon()}
            {renderDangerous()}
            {renderWhyDangerous()}
            {renderOrganDonor()}
            {renderExFelon()}
            {renderProtectiveOrder()}
            {renderSeniorCitizen()}
            {renderOfficer()}
            {renderSexOffender()}
            {renderJuvenile()}
            {renderMilitary()}
            {renderProbation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <div className={classes.actions}>{renderActions()}</div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeAddPerson,
  closeEditPerson,
  notify,
  handleError,
  showEditPerson,
})(PersonTab);
