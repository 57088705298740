import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from 'components/Dialog';
import { Grid, Button, FormControl, TextField } from '@material-ui/core';
import { updateUnitStatusPromise, getUnitStatus, setNotify } from 'reducers/UnitStatusReducer';
import EventFilter from './EventFilter';
import PlateStateFilter from './PlateStateFilter';
import OlnStateFilter from './OlnStateFilter';
import ModifiersFilter from './ModifiersFilter';
import AddressLookup from 'components/AddressLookup';
import { closeNewUnitStatus } from 'reducers/DialogsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getPlace } from 'reducers/EventsReducer';
import { MdLocationSearching } from 'react-icons/md';
import { getUnitCoords, geocodeCoords } from 'utils/mapFunctions';
import { showBulletinDetails } from 'reducers/DialogsReducer';
import { bulletinFound } from 'reducers/BulletinReducer';
import DateFnsUtils from '@date-io/date-fns';
import { findBulletins } from 'reducers/BulletinReducer';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { authorizeExternalApp, setSearchCookie } from 'reducers/UserReducer';
import Tooltip from 'components/Tooltip';
import { displayDateTime, getFormat24, getCurrentDate } from 'reducers/TimeReducer';
import Dictionary from 'components/Dictionary';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

function getNcicUrl() {
  let url = process.env.REACT_APP_NCIC_URL;
  if (!url) return false;
  if (url.substr(-1) !== '/') url += '/';
  url += 'Search';
  return url;
}

const ncicUrl = getNcicUrl();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  body: {
    background: theme.palette.bgElements,
    minHeight: '50vh',
  },
  itemWrap: {
    fontWeight: 500,
  },
  itemLabel: {
    fontWeight: 200,
  },
  formSection: {
    paddingBottom: 10,
  },
  color: {
    background: theme.palette.bgElements,
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing(2)}px`,
    '& button': {
      marginRight: theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  insideFab: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  dateWrap: {
    margin: '-16px -4px 0',
  },
  note: {
    '& textarea': {
      fontSize: 14,
    },
  },
  status: {
    marginLeft: 0,
    marginRight: 0,
  },
  address: {
    marginBottom: 10,
  },
}));

function getDefaultEvent(events, ptsUnitID) {
  let output = '';
  events.forEach((event) => {
    const result = event.assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (result) output = event.ptsEventID;
  });
  return output;
}

function DialogUnitStatus(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { dictionary, events, units, data, permissions, notify, notification, options } = props;
  const { ptsEventID, ptsUnitID, edit } = data;
  const [event, setEvent] = useState(ptsEventID ? ptsEventID : getDefaultEvent(events, ptsUnitID));
  const { States, UnitActions, UnitActionModifiers } = dictionary;
  const [status, setStatus] = useState('');
  const [locationString, setLocationString] = useState('');
  const [plate, setPlate] = useState('');
  const [oln, setOln] = useState('');
  const [plateState, setPlateState] = useState(null);
  const [olnState, setOlnState] = useState(null);
  const [mileage, setMileage] = useState('');
  const [modifiers, setModifiers] = useState([]);
  const [statusNotes, setStatusNotes] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [ptsDestinationID, setPtsDestinationID] = useState(null);
  const [ptsActionID, setPtsActionID] = useState(null);
  const [editedDestination, setEditedDestination] = useState(null);
  const [unit, setUnit] = useState(null);
  const [Occurred, setOccurred] = useState(null);
  const title = data.title + ' - ' + (unit ? unit.Unit : '');
  const disableNcic = !ncicUrl || !permissions.globals['Execute NCIC Queries'];
  const addDateToNote = options.UnitStatusNotesDate;
  const format24 = getFormat24();

  useEffect(() => {
    setOccurred(getCurrentDate());
    if (!edit || !ptsUnitID) return;
    getEditStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unit = units.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (unit) setUnit(unit);
    // eslint-disable-next-line
  }, [units]);

  const getEditStatus = async () => {
    try {
      const result = await getUnitStatus(ptsUnitID, ptsEventID);
      const {
        Action,
        Location,
        Mileage,
        Notes,
        OLN,
        OLNState,
        Plate,
        PlateState,
        modifiers,
        ptsActionID,
        ptsDestinationID,
        Occurred,
      } = result;
      if (ptsDestinationID) {
        const destination = await getPlace(ptsDestinationID);
        if (destination) setEditedDestination(destination);
      }
      const states = dictionary.States;
      const olnState = states.find((c) => c.Code === OLNState) || null;
      const plateState = states.find((c) => c.Code === PlateState) || null;
      const mod = !modifiers
        ? []
        : modifiers
            .map((m) => UnitActionModifiers.find((a) => a.Code === m.Modifier))
            .filter((m) => m);

      setStatus(Action);
      setLocationString(Location || '');
      setMileage(String(Mileage || ''));
      setStatusNotes(Notes || '');
      setOln(OLN || '');
      setOlnState(olnState);
      setPlate(Plate || '');
      setPlateState(plateState);
      setPtsActionID(ptsActionID || null);
      setPtsDestinationID(ptsDestinationID || null);
      setModifiers(mod);
      setOccurred(Occurred);
    } catch (err) {
      console.log(err);
    }
  };

  const resetForm = () => {
    setStatus('');
    setLocationString('');
    setPlate('');
    setOln('');
    setPlateState(null);
    setOlnState(null);
    setLocationString('');
    setStatusNotes('');
    setModifiers([]);
    setMileage('');
    setPtsActionID(null);
  };

  const handleClose = () => {
    resetForm();
    props.closeNewUnitStatus();
  };

  const submitForm = (e) => {
    e.preventDefault();
    statusChange();
  };

  useEffect(() => {
    if (notify && notification) {
      props.bulletinFound(notification);
      props.setNotify(false);
    }
  }, [notify, notification]);

  const statusChange = () => {
    const _ptsEventID = typeof event === 'number' ? event : ptsEventID;
    const data = {
      UnitStatus: status.Code || null,
      Notes: statusNotes || null,
      Plate: plate || null,
      PlateState: plateState !== null ? plateState.Code : null,
      OLN: oln || null,
      OLNState: olnState !== null ? olnState.Code : null,
      Location: locationString || null,
      Modifiers: modifiers || null,
      Mileage: mileage || null,
      ptsUnitID,
      ptsDestinationID,
      Occurred,
    };

    props.showSpinner();
    updateUnitStatusPromise(data, _ptsEventID, ptsActionID)
      .then(() => props.findBulletins(data))
      .then(() => {
        resetForm();
        props.closeNewUnitStatus();
        props.notifyDataUpdate({ type: 'unit-status' });
      })
      .catch(
        (err) => console.log(err) || props.handleError(err, 'Error, Problems during status change')
      )
      .finally(() => props.hideSpinner());
  };

  const setSelectedPlateState = (state) => setPlateState(state);
  const setSelectedOlnState = (state) => setOlnState(state);
  const setSelectedModifier = (modifiers) => setModifiers(modifiers);
  const handleLocationChange = (event) => setLocationString(event.target.value.substr(0, 100));
  const handlePlateChange = (event) => setPlate(event.target.value.substr(0, 25));
  const handleOlnChange = (event) => setOln(event.target.value.substr(0, 100));
  const handleMileageChange = (event) => {
    const val = event.target.value;
    if (val.length < 11) setMileage(val);
  };
  const handleStatusNotesChange = (event) => setStatusNotes(event.target.value);
  const setSelectedEvent = (event) => setEvent(event);
  const onPlaceValueChange = (place) => setPtsDestinationID(place.ptsPlaceID);
  const getLocation = async () => {
    if (!unit) return;
    const coords = getUnitCoords(unit);
    if (!coords) return;
    const addresses = await geocodeCoords(coords);
    if (!addresses.length) return;
    setLocationString(addresses[0].formattedAddress.substr(0, 100));
  };

  const handleOlnSearch = () => {
    authorizeExternalApp();
    setSearchCookie({
      type: 'oln',
      oln,
      state: olnState ? olnState.Code : null,
      ptsUnitID,
    });
  };

  const handlePlateSearch = () => {
    authorizeExternalApp();
    setSearchCookie({
      type: 'plate',
      plate,
      state: plateState ? plateState.Code : null,
      ptsUnitID,
    });
  };

  const renderActions = () => {
    const olnDisabled = !oln || !ncicUrl || disableNcic;
    const plateDisabled = !plate || !ncicUrl || disableNcic;
    return (
      <div className={classes.actions}>
        <div>
          <Tooltip title="NCIC search">
            <span>
              <Button
                component="a"
                onClick={authorizeExternalApp}
                href={ncicUrl}
                target="_ncic"
                disabled={disableNcic}>
                Search
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="NCIC OLN search">
            <span>
              <Button
                component="a"
                onClick={handleOlnSearch}
                href={ncicUrl}
                target="_ncic"
                disabled={olnDisabled}>
                OLN
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="NCIC plate earch">
            <span>
              <Button
                component="a"
                onClick={handlePlateSearch}
                href={ncicUrl}
                target="_ncic"
                disabled={plateDisabled}>
                Plate
              </Button>
            </span>
          </Tooltip>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            onClick={submitForm}>
            <SaveIcon /> Save
          </Button>
          <Button onClick={handleClose} color="primary" size="small">
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  const onNoteClick = () => {
    if (!addDateToNote || statusNotes !== '') return;
    const created = displayDateTime(getCurrentDate(), true) + ': ';
    setStatusNotes(created);
  };

  const renderStatus = () => {
    const onChange = (ev, val) => {
      setStatus(val);
    };
    return (
      <Dictionary
        options="UnitActions"
        className={classes.status}
        onChange={onChange}
        value={status}
        label="Status"
        compact
      />
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth={'sm'}
      title={title}
      actions={renderActions()}>
      <form onSubmit={submitForm}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={1} className={classes.dateWrap}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                label="Edit Status Date"
                value={Occurred}
                onChange={(date) => setOccurred(date)}
                autoOk
                size="small"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardTimePicker
                ampm={!format24}
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                label="Edit Status Time"
                value={Occurred}
                onChange={(date) => setOccurred(date)}
                autoOk
                size="small"
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <EventFilter eventList={events} selectedEvent={event} setSelectedEvent={setSelectedEvent} />
        {renderStatus()}
        <FormControl fullWidth className={classes.formSection}>
          <TextField
            variant="outlined"
            label="Location"
            className={classes.filter}
            onChange={handleLocationChange}
            value={locationString}
            size="small"
          />
          <Fab
            className={classes.insideFab}
            disabled={edit}
            onClick={getLocation}
            size="small"
            color="secondary">
            <MdLocationSearching style={{ fontSize: '1.3rem' }} />
          </Fab>
        </FormControl>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              value={plate}
              onChange={handlePlateChange}
              fullWidth
              label="Plate"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <PlateStateFilter
              states={States}
              selectedPlateState={plateState}
              setSelectedPlateState={setSelectedPlateState}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              value={oln}
              onChange={handleOlnChange}
              fullWidth
              label="OLN"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <OlnStateFilter
              states={States}
              selectedOlnState={olnState}
              setSelectedOlnState={setSelectedOlnState}
            />
          </Grid>
        </Grid>
        <FormControl fullWidth className={classes.formSection}>
          <TextField
            type="number"
            variant="outlined"
            label="Mileage"
            className={classes.filter}
            onChange={handleMileageChange}
            value={mileage}
            size="small"
          />
        </FormControl>
        <FormControl fullWidth className={classes.formSection}>
          <TextField
            multiline={true}
            rows="2"
            variant="outlined"
            label="Notes"
            className={classes.note}
            onChange={handleStatusNotesChange}
            value={statusNotes}
            size="small"
            onClick={onNoteClick}
          />
        </FormControl>
        <FormControl fullWidth className={classes.address}>
          <AddressLookup
            ptsPlaces={true}
            ptsAddresses={false}
            googleAddresses={false}
            onPlaceValueSet={onPlaceValueChange}
            onAddressValueSet={onPlaceValueChange}
            setPlace={editedDestination}
            label="Add destination"
            size="small"
            compact
          />
        </FormControl>
        <FormControl fullWidth className={classes.formSection}>
          <ModifiersFilter
            modifiers={UnitActionModifiers}
            selectedModifiers={modifiers}
            setSelectedModifier={setSelectedModifier}
          />
        </FormControl>
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  dictionary: state.dictionary,
  events: state.events,
  units: state.units,
  notify: state.unitStatus.notify,
  notification: state.unitStatus.notification,
  permissions: state.permissions,
  options: state.config.options,
});

export default connect(mapStateToProps, {
  closeNewUnitStatus,
  showSpinner,
  hideSpinner,
  handleError,
  setNotify,
  showBulletinDetails,
  bulletinFound,
  findBulletins,
  notifyDataUpdate,
})(DialogUnitStatus);
