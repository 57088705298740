import { getService } from './service';

const GET_EVENT_SELECT_ITEMS = 'VFC/GET_EVENT_SELECT_ITEMS';
const GET_CASE_SELECT_ITEMS = 'VFC/GET_CASE_SELECT_ITEMS';
const GET_BOOKING_SELECT_ITEMS = 'VFC/GET_BOOKING_SELECT_ITEMS';
const GET_WARRANT_SELECT_ITEMS = 'VFC/GET_WARRANT_SELECT_ITEMS';

const GET_CASE = 'VFC/GET_CASE';
const GET_EVENT = 'VFC/GET_EVENT';
const GET_BOOKING = 'VFC/GET_BOOKING';
const GET_WARRANT = 'VFC/GET_WARRANT';
const SET_AGENCY = 'VFC/SET_AGENCY';
const SET_AGENCIES = 'VFC/SET_AGENCIES';

export const findEvents = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'events', data },
  });
  return parseEvents(result);
};

const parseEvents = (data) => {
  return data.map((ev) => ({
    ...ev,
    id: ev.vfcMainId,
  }));
};

export const findCases = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'cases', data },
  });
  return parseCases(result);
};

const parseCases = (data) => {
  return data.map((ev) => ({
    ...ev,
    id: ev.vfcMainId,
  }));
};

export const findBookings = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'bookings', data },
  });
  return parseBookings(result);
};

const parseBookings = (data) => {
  return data.map((ev) => ({
    ...ev,
    id: ev.vfcMainId,
  }));
};

export const findWarrants = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'warrants', data },
  });
  return parseWarrants(result);
};

const parseWarrants = (data) => {
  return data.map((ev) => ({
    ...ev,
    id: ev.vfcMainId,
  }));
};

function formatEventsData(data) {
  data = data[0];
  return {
    ...data,
    AddtlInfo: data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null,
    MainInfo: data.MainInfo ? JSON.parse(data.MainInfo) : null,
    Notes: data.Notes ? JSON.parse(data.Notes) : null,
  };
}

function formatCasesData(data) {
  data = data[0];

  return {
    ...data,
    AddtlInfo: data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null,
    MainInfo: data.MainInfo ? JSON.parse(data.MainInfo) : null,
  };
}

export const getBookingsSelectItems = () => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 50000;
      const row = await service.get({ type: 'booking-select-items', data: '' });
      dispatch({ type: GET_BOOKING_SELECT_ITEMS, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received # - booking-select-items', 'error'));
    }
  };
};

export const getEventsSelectItems = () => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 50000;
      const row = await service.get({ type: 'events-select-items', data: '' });
      dispatch({ type: GET_EVENT_SELECT_ITEMS, payload: row });
    } catch (error) {
      console.log('getEventsSelectItems error: ', error);
    }
  };
};

export const getWarrantsSelectItems = () => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 50000;
      const row = await service.get({ type: 'warrants-select-items', data: '' });
      dispatch({ type: GET_WARRANT_SELECT_ITEMS, payload: row });
    } catch (error) {
      console.log('getWarrantsSelectItems error: ', error);
    }
  };
};

export const getCasesSelectItems = () => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      const row = await service.get({ type: 'cases-select-items', data: '' });
      dispatch({ type: GET_CASE_SELECT_ITEMS, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received # - cases-select-items', 'error'));
    }
  };
};

export const getCaseDetails = (vfcMainId) => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'cases', data: vfcMainId });
      dispatch({ type: GET_CASE, payload: row });
    } catch (error) {}
  };
};

export const getEventDetails = (vfcMainId) => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'events', data: vfcMainId });
      dispatch({ type: GET_EVENT, payload: row });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBookingDetails = (vfcMainId) => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'bookings', data: vfcMainId });
      dispatch({ type: GET_BOOKING, payload: row });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getWarrantDetails = (vfcMainId) => {
  return async (dispatch) => {
    try {
      const service = getService('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'warrants', data: vfcMainId });
      dispatch({ type: GET_WARRANT, payload: row });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setCurrentAgency = (AgencyID) => (dispatch) => {
  dispatch({ type: SET_AGENCY, payload: AgencyID });
};

export const setAgencies = (agencies) => (dispatch) => {
  dispatch({ type: SET_AGENCIES, payload: agencies });
};

// ======================= REDUCER =======================
const defaultState = {
  eventsSelectItems: {
    EventTypes: [{ code: '', description: '' }],
    EventDescription: [{ code: '', description: '' }],
    Zones: [{ code: '', description: '' }],
    Officers: [{ code: '', description: '' }],
    UnitVehicles: [{ code: '', description: '' }],
    CallerCity: [{ code: '', description: '' }],
    CallerZip: [{ code: '', description: '' }],
    SceneCity: [{ code: '', description: '' }],
    SceneZip: [{ code: '', description: '' }],
  },
  casesSelectItems: {
    Offense: [{ code: '', description: '' }],
    ReportingOfficer: [{ code: '', description: '' }],
    PartyType: [{ code: '', description: '' }],
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    DLStates: [{ code: '', description: '' }],
    VehicleMake: [{ code: '', description: '' }],
    VehicleModel: [{ code: '', description: '' }],
    LicenseState: [{ code: '', description: '' }],
    PropertyCode: [{ code: '', description: '' }],
  },
  bookingsSelectItems: {
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    IDState: [{ code: '', description: '' }],
    Offense: [{ code: '', description: '' }],
    ArrestCity: [{ code: '', description: '' }],
    ArrestingOfficer: [{ code: '', description: '' }],
    ArrestingAgency: [{ code: '', description: '' }],
    BookingOfficer: [{ code: '', description: '' }],
    ReleasingOfficer: [{ code: '', description: '' }],
    Disposition: [{ code: '', description: '' }],
  },
  warrantsSelectItems: {
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    ChargeDescription: [{ code: '', description: '' }],
  },
  eventDetails: null,
  caseDetails: null,
  bookingDetails: null,
  warrantDetails: null,
  Agencies: [],
  Agency: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CASE_SELECT_ITEMS:
      return {
        ...state,
        casesSelectItems: action.payload,
      };
    case GET_EVENT_SELECT_ITEMS:
      return {
        ...state,
        eventsSelectItems: action.payload,
      };
    case GET_BOOKING_SELECT_ITEMS:
      return {
        ...state,
        bookingsSelectItems: action.payload,
      };
    case GET_WARRANT_SELECT_ITEMS:
      return {
        ...state,
        warrantsSelectItems: action.payload,
      };
    case GET_EVENT:
      return {
        ...state,
        eventDetails: formatEventsData(action.payload),
      };
    case GET_CASE:
      return {
        ...state,
        caseDetails: formatCasesData(action.payload),
      };
    case GET_BOOKING:
      return {
        ...state,
        bookingDetails: formatCasesData(action.payload),
      };
    case GET_WARRANT:
      return {
        ...state,
        warrantDetails: formatCasesData(action.payload),
      };
    case SET_AGENCIES:
      return {
        ...state,
        Agencies: action.payload,
      };
    case SET_AGENCY:
      return {
        ...state,
        Agency: action.payload,
      };
    default:
      return state;
  }
}
