import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FraudForgeryIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M299.3 189c-4.8-4.8-12.5-4.8-17.3 0-4.8 4.8-4.8 12.5 0 17.3 3.9 3.9 6.9 8.6 8.7 13.7H316c-2.3-11.6-8.1-22.3-16.7-31zm-31.1-43v-3.7c14.4-6.6 24.5-24.6 24.5-46.3 0-27.5-16.1-49-36.7-49s-36.7 21.5-36.7 49c0 21.7 10.1 39.7 24.5 46.3v3.7c0 13.8-6.9 27.1-16.7 32.4-8 4.3-14.8 10.2-20 17.2h38.3c14.1-10.9 22.8-29.6 22.8-49.6zm-19.3 243.2L72.3 263.1v189.6c0 6.8 5.5 12.2 12.2 12.2h342.9c6.8 0 12.2-5.5 12.2-12.2V263.1L263.1 389.2c-4.2 3.1-10 3.1-14.2 0z" />
      <path d="M427.4 220.1H316c.8 4 1.2 8.1 1.2 12.2 0 33.8-27.5 61.2-61.2 61.2-6.8 0-12.2-5.5-12.2-12.2 0-6.8 5.5-12.2 12.2-12.2 20.3 0 36.7-16.5 36.7-36.7 0-4.2-.7-8.4-2.1-12.2h-206c-6.8 0-12.2 5.5-12.2 12.2v.7L256 364.2 439.7 233v-.7c0-6.7-5.5-12.2-12.3-12.2z" />
    </SvgIcon>
  );
}

export default FraudForgeryIcon;
