import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8airplanelanding100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100"
      height="100">
      <path d="M5.625 3.188c-.86 0-1.566.515-1.906 1.374l-3.5 8.97c-1.887 4.831 7.937 9.812 16.5 13.155l1.687.688-11.593 7.813c-.754.449-1.739 1.152-1.657 2.156.043.531.364.972.907 1.187l1.78.688c.466.18 1.067.281 1.782.281a9.37 9.37 0 0 0 3.094-.531L31.25 32.28c9.098 3.364 14.395 4.938 16.625 4.938.996 0 1.625-.324 1.875-.969.965-2.469-.79-8.246-7.688-10.938L10.125 12.845c-.344-.133-.434-.309-.594-1.438-.148-1.011-.664-4.386-.781-5.375-.18-1.43-1.727-2.843-3.125-2.843Zm13.094 1.656a1.35 1.35 0 0 0-1.188.531c-.402.543-.433 1.367-.093 2.5l1.5 6.25c3.07 1.2 6.675 2.621 10.125 3.969l-4.688-8.938c-.688-1.32-2.137-2.992-3.438-3.5l-1.75-.687a1.607 1.607 0 0 0-.468-.125ZM33.03 35.062c-.02.145-.031.29-.031.438 0 1.93 1.57 3.5 3.5 3.5 1.273 0 2.387-.7 3-1.719a195.701 195.701 0 0 1-6.469-2.218Z" />
    </SvgIcon>
  );
}

export default icons8airplanelanding100Icon;
