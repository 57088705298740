import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function icons8binoculars100Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="100"
      height="100">
      <path d="M7 3a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H7zm12 0a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4zM6 7a1 1 0 0 0-1 1v3a2 2 0 0 0-2 2v9h10v-7h4v7h10v-9a2 2 0 0 0-2-2V8a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v3h-4V8a1 1 0 0 0-1-1H6zM3 24a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2H3zm14 0a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2H17z" />
    </SvgIcon>
  );
}

export default icons8binoculars100Icon;
